import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import { useRouteLoaderData } from "react-router-dom";
import InputGroup from "../../../UI/Inputs/InputGroup";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import { updateEnrollmentRequest } from "../../../../requests/enrollments";
import { displayError } from "../../../../utils/displayError";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getAllCourseDecisionsRequest } from "../../../../requests/courses";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import StudentSelect from "../StudentSelect";

export default function NewStudentVisit({ setRender }) {
  const [decisionOptions, setDecisionOptions] = useState([]);
  const loaderStudent = useRouteLoaderData("student")?.data;
  const [student, setStudent] = useState(loaderStudent);
  const methods = useForm();
  const selectedCourse = methods.watch("course_id");

  const selectedEnrollmentId = student?.course_enrollments.find(
    (enrollment) => enrollment.series_id === selectedCourse
  )?.id;

  const onSubmit = async (data) => {
    try {
      const decisions = data.decisions.map((decision) => ({
        decision_option_id: decision.id,
        cached_enrollment_id: selectedEnrollmentId,
      }));

      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            date: data.date,
            decisions_attributes: decisions,
            notes_attributes:
              data.note_text && data.note_type
                ? [
                    {
                      text: data.note_text,
                      type: data.note_type,
                    },
                  ]
                : [],
          },
        ],
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        ToastNotification("success", "Visit created successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: selectedCourse,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getAllCourseDecisions();
    }
  }, [selectedCourse]);

  return (
    <ActionMenuContainer
      label="Add a visit"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="min-h-[350px]"
        >
          {loaderStudent ? (
            <p className="mb-4">
              Student: {loaderStudent.first_name} {loaderStudent.last_name}
            </p>
          ) : (
            <StudentSelect onChange={setStudent} />
          )}

          {student && (
            <>
              <InputGroup>
                <Select
                  id="course_id"
                  classNames="w-full bg-transparent"
                  label="Course"
                  isRequired
                >
                  <SelectItem value="">Select</SelectItem>
                  {student?.course_enrollments?.map((enrollment) => (
                    <SelectItem
                      key={enrollment.id}
                      value={enrollment.series_id}
                    >
                      {enrollment.series_name}
                    </SelectItem>
                  ))}
                </Select>
                <DatePickerInput
                  id="date"
                  dark
                  label="Sent"
                  selected={new Date()}
                  isRequired
                />
              </InputGroup>
              {decisionOptions.length > 0 && (
                <InputGroup>
                  <MultiSelect
                    id="decisions"
                    label="Decisions"
                    placeholder="Add Decision"
                    options={decisionOptions}
                    defaultSelected={[]}
                    dark
                  />
                </InputGroup>
              )}
              <InputGroup>
                <Select
                  id="note_type"
                  classNames="w-full bg-transparent"
                  label="Note Type"
                >
                  <SelectItem value="">Select</SelectItem>
                  <SelectItem value="Note">Note</SelectItem>
                  <SelectItem value="PrayerRequest">Prayer Request</SelectItem>
                </Select>
                <Input id="note_text" label="Note" dark />
              </InputGroup>
              <div className="text-center">
                <Button type="submit" variant="primary">
                  Save Reminder
                </Button>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
