import React, { useState, useEffect } from "react";
import Card from "../../../UI/Card";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../UI/Button";
import Input from "../../../UI/Inputs/Input";
import PageLoader from "../../../UI/PageLoader";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";
import { getAllStudents } from "../../../../requests/students";
import { displayError } from "../../../../utils/displayError";
import SeminarPrintTicketsPdf from "./SeminarPrintTicketsPdf";
import SeminarPrintTicketsFilter from "./SeminarPrintTicketsFilter";

export default function SeminarPrintTickets() {
  const seminar = useRouteLoaderData("seminar").data;
  const [students, setStudents] = useState();
  const [selectedStudents, setSelectedStudents] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm();
  const startRegistrationNumber = searchParams.get("start_registration_number");
  const endRegistrationNumber = searchParams.get("end_registration_number");

  // Get all present registration numbers from students and sort them
  const registrationNumbers =
    students
      ?.filter((student) => Boolean(student.registration_number))
      .map((student) => student.registration_number.padStart(4, "0"))
      .sort((a, b) => a - b) || [];

  // Fetch seminar students and add their related registration numbers
  const fetchStudents = () => {
    getAllStudents({
      params: {
        pagination: false,
        q: { enrollments_seminar_id_eq: seminar.id },
      },
    })
      .then((res) => {
        const studentsWithRegistrationNumber = res.data.students.map(
          (student) => {
            const enrollment = student.enrollments.find(
              (e) => e.series_id === seminar.id
            );

            const registrationNumber = enrollment.registration_number
              ? enrollment.registration_number.padStart(4, "0")
              : null;

            return {
              ...student,
              registration_number: registrationNumber,
            };
          }
        );
        setStudents(studentsWithRegistrationNumber);
      })
      .catch((e) => {
        displayError(e);
      });
  };

  // Set the start and end registration numbers to the registration number user clicked on
  const setSingleRegistrationNumber = (num) => {
    methods.setValue("start_registration_number", num);
    methods.setValue("end_registration_number", num);
  };

  // Set the selected students to the students in the range of the start and end registration numbers.
  const onSubmit = (data) => {
    const start = Number(data.start_registration_number);
    const end = Number(data.end_registration_number);

    if (start > end) {
      displayError({
        response: {
          statusText:
            "Start registration number cannot be greater than end registration number",
        },
      });
      return;
    }

    if (start < 1 || end < 1) {
      displayError({
        response: {
          statusText: "Registration number cannot be less than 1",
        },
      });
      return;
    }

    setSearchParams((prev) => {
      prev.set("start_registration_number", start);
      prev.set("end_registration_number", end);
      return prev;
    });
  };

  useEffect(() => {
    if (seminar) fetchStudents();
  }, [seminar]);

  useEffect(() => {
    const start = Number(searchParams.get("start_registration_number"));
    const end = Number(searchParams.get("end_registration_number"));

    if (start && end && students) {
      setSelectedStudents(
        students.filter(
          (student) =>
            Number(student.registration_number) >= start &&
            Number(student.registration_number) <= end
        )
      );

      methods.setValue("start_registration_number", start);
      methods.setValue("end_registration_number", end);
    }
  }, [searchParams, students]);

  if (!seminar || !students)
    return (
      <Card classNames="z-10">
        <PageLoader />
      </Card>
    );

  return (
    <Card classNames="z-10">
      <div className="lg:flex gap-4 mb-4">
        <div className="lg:w-1/2 lg:border-black lg:border-r lg:pr-4">
          <div className="mb-2">
            Select another seminar filtering by host school:
          </div>
          <SeminarPrintTicketsFilter seminar={seminar} />
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="pb-4 lg:w-1/2 lg:pb-0"
          >
            <div className="mb-2">
              Generate tickets for registration numbers in the range:
            </div>
            <div className="flex gap-4">
              <Input
                id="start_registration_number"
                type="number"
                placeholder="Start #"
                defaultValue={registrationNumbers[0]}
              />
              <Input
                id="end_registration_number"
                placeholder="End #"
                type="number"
                defaultValue={
                  registrationNumbers[registrationNumbers.length - 1]
                }
              />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
            <div className="mt-2 text-sm text-gray-500">
              NOTE: Print in batches of 500 (example: 1-500, 501-1000,
              1001-1500, etc.).
            </div>
          </form>
        </FormProvider>
      </div>

      <div className="mb-4">
        <p className="mb-4">
          Registration numbers assigned to <b>{registrationNumbers.length}</b>{" "}
          students...
        </p>

        {registrationNumbers.map((num) => (
          <button
            onClick={() => setSingleRegistrationNumber(num)}
            key={num}
            className="border border-black py-1 mr-2 mb-2 w-12 rounded-md text-xs text-center hover:bg-black hover:text-white transition-colors duration-200"
          >
            {num}
          </button>
        ))}
      </div>

      {startRegistrationNumber && endRegistrationNumber && (
        <>
          <p>
            Selected{" "}
            <b>{endRegistrationNumber - startRegistrationNumber + 1}</b> tickets
            to print.
          </p>
          {endRegistrationNumber - startRegistrationNumber > 2000 ? (
            <p className="text-red-500">
              The maximum number of tickets you can print at once is 2000.
            </p>
          ) : (
            <SeminarPrintTicketsPdf
              selectedStudents={selectedStudents}
              startRegistrationNumber={startRegistrationNumber}
              endRegistrationNumber={endRegistrationNumber}
            />
          )}
        </>
      )}
    </Card>
  );
}
