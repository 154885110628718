import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenuContainer";
import Button from "../../../UI/Button";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import InputGroup from "../../../UI/Inputs/InputGroup";
import Input from "../../../UI/Inputs/Input";
import { useRouteLoaderData } from "react-router-dom";
import { getAllCourseLessonsRequest } from "../../../../requests/courses";
import { displayError } from "../../../../utils/displayError";
import { ToastNotification } from "../../../UI/ToastNotification";
import {
  getEnrollmentRequest,
  updateEnrollmentRequest,
} from "../../../../requests/enrollments";
import dayjs from "dayjs";
import StudentSelect from "../StudentSelect";

export default function NewStudentNote({ setRender, user }) {
  const loaderStudent = useRouteLoaderData("student")?.data;
  const [student, setStudent] = useState(loaderStudent);
  const methods = useForm();
  const [lessons, setLessons] = useState([]);
  const [enrollment, setEnrollment] = useState(null);
  const selectedCourse = methods.watch("course_id");
  const selectedContext = methods.watch("context", "lesson");

  const selectedEnrollmentId = student?.course_enrollments.find(
    (enrollment) => enrollment.series_id === selectedCourse
  )?.id;

  const episodeDeliveryId = enrollment?.episode_deliveries.find(
    (delivery) => delivery.episode_id === methods.watch("lesson_id")
  )?.id;

  const getLessons = async () => {
    try {
      const res = await getAllCourseLessonsRequest({
        courseId: selectedCourse,
      });

      if (res.data) {
        setLessons(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getEnrollment = async () => {
    try {
      const res = await getEnrollmentRequest({
        enrollmentId: selectedEnrollmentId,
      });

      if (res.data) {
        setEnrollment(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const renderLessonOrReminder = () => {
    if (selectedContext === "lesson") {
      return (
        <Select
          id="lesson_id"
          classNames="w-full bg-transparent"
          label="Lesson"
          isRequired
        >
          <SelectItem value="" disabled>
            {selectedCourse ? "Select" : "Select a course first"}
          </SelectItem>
          {lessons?.map((lesson) => (
            <SelectItem key={lesson.id} value={lesson.id}>
              {lesson.name}
            </SelectItem>
          ))}
        </Select>
      );
    } else {
      return (
        <Select
          id="reminder_id"
          classNames="w-full bg-transparent"
          label="Reminder"
          isRequired
        >
          <SelectItem value="" disabled>
            {selectedCourse ? "Select" : "Select a course first"}
          </SelectItem>
          {enrollment.reminders?.map((reminder) => (
            <SelectItem key={reminder.id} value={reminder.id}>
              {dayjs(reminder.date).format("MM/DD/YYYY")}
            </SelectItem>
          ))}
        </Select>
      );
    }
  };

  const onSubmit = async (data) => {
    const params =
      methods.watch("context") === "lesson"
        ? {
            episode_deliveries_attributes: [
              {
                id: episodeDeliveryId,
                episode_id: data.lesson_id,
                grading_instructor_id: user.id,
                notes_attributes: [
                  {
                    text: data.note_text,
                    type: data.note_type,
                  },
                ],
              },
            ],
            enrollmentId: selectedEnrollmentId,
          }
        : {
            reminders_attributes: [
              {
                id: methods.watch("reminder_id"),
                notes_attributes: [
                  {
                    text: data.note_text,
                    type: data.note_type,
                  },
                ],
              },
            ],
            enrollmentId: selectedEnrollmentId,
          };

    try {
      const res = await updateEnrollmentRequest(params);
      if (res.data) {
        ToastNotification("success", "Note created successfully.");
        setRender(null);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getLessons();
      getEnrollment();
    }
  }, [selectedCourse]);

  return (
    <ActionMenuContainer
      label="Add a note"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="min-h-[350px]"
        >
          {loaderStudent ? (
            <p className="mb-4">
              Student: {loaderStudent.first_name} {loaderStudent.last_name}
            </p>
          ) : (
            <StudentSelect onChange={setStudent} />
          )}

          {student && (
            <>
              <InputGroup>
                <Select
                  id="course_id"
                  classNames="w-full bg-transparent"
                  label="Course"
                  isRequired
                >
                  <SelectItem value="">Select</SelectItem>
                  {student.course_enrollments?.map((enrollment) => (
                    <SelectItem
                      key={enrollment.id}
                      value={enrollment.series_id}
                    >
                      {enrollment.series_name}
                    </SelectItem>
                  ))}
                </Select>
                <Select
                  id="context"
                  classNames="w-full bg-transparent"
                  label="Context"
                  isRequired
                >
                  <SelectItem value="lesson">Lesson</SelectItem>
                  <SelectItem value="reminder">Reminder</SelectItem>
                </Select>
              </InputGroup>
              <InputGroup>
                {renderLessonOrReminder()}
                <Select
                  id="note_type"
                  classNames="w-full bg-transparent"
                  label="Note Type"
                >
                  <SelectItem value="">Select</SelectItem>
                  <SelectItem value="Note">Note</SelectItem>
                  <SelectItem value="PrayerRequest">Prayer Request</SelectItem>
                </Select>
              </InputGroup>
              <InputGroup>
                <Input id="note_text" label="Note" dark />
              </InputGroup>
              <div className="text-center">
                <Button type="submit" variant="primary">
                  Save Note
                </Button>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
