import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import QuickFind from "../../../UI/QuickFind";
import { PrinterIcon } from "@heroicons/react/20/solid";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import SeminarButton from "../SeminarButton";
import {
  getAllSeminarAssignStudentsRequest,
  getAllSeminarStudentsRequest,
  getRecentlyAssignedStudentsRequest,
} from "../../../../requests/seminars";
import { displayError } from "../../../../utils/displayError";
import { highlightText } from "../../../../utils/highlightText";
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { fullName } from "../../../../utils/fullName";
import { hoverLink } from "../../../../utils/hoverLink";
import Modal from "../../../UI/Modal";
import NewStudent from "./NewStudent";
import AddRegistrationNumber from "./AddRegistrationNumber";
import AssignStudents from "../SeminarDetails/AssignStudents";

export default function SeminarStudents() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const params = useParams();
  const seminarId = params.seminarId;
  const [enrollments, setEnrollments] = useState([]);
  const { user, seminar } = useOutletContext();
  const [newStudentModal, setNewStudentModal] = useState({
    open: false,
    seminar: null,
  });
  const [reloadStudents, setReloadStudents] = useState(false);
  const [assignStudentsModal, setAssignStudentsModal] = useState({
    open: false,
  });
  const [assignStudents, setAssignStudents] = useState([]);
  const [recentlyAssigned, setRecentlyAssigned] = useState([]);

  const getRecentlyAssigned = async () => {
    try {
      const res = await getRecentlyAssignedStudentsRequest({
        seminarId: seminar.id,
      });

      if (res.data) {
        setRecentlyAssigned(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllSeminarStudents = async () => {
    try {
      const res = await getAllSeminarStudentsRequest(seminarId, {
        params: {
          q: {
            student_full_name_or_registration_number_i_cont_all: query
              .trim()
              .split(" "),
          },
        },
      });

      if (res.data) {
        setEnrollments(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getAllSeminarAssignStudents = async () => {
    try {
      const res = await getAllSeminarAssignStudentsRequest({
        seminarId: seminar.id,
      });

      if (res.data) {
        setAssignStudents(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("query", data.search || "");
      return prev;
    });
  };

  useEffect(() => {
    getAllSeminarAssignStudents();
    getRecentlyAssigned();
  }, [assignStudentsModal, reloadStudents]);

  useEffect(() => {
    if (newStudentModal.open === false || reloadStudents) {
      getAllSeminarStudents();
    }
  }, [newStudentModal, reloadStudents]);

  useEffect(() => {
    getAllSeminarStudents();
  }, [query]);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            <img
              src="/add-new.svg"
              className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
              width={30}
              height={30}
              alt="Add New"
              onClick={() =>
                setNewStudentModal({ open: true, seminar: seminar })
              }
            />
            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <div className="absolute left-1/2 inline-flex -translate-x-1/2">
            <SeminarButton
              onClick={() => {
                if (assignStudents.length > 0) {
                  setAssignStudentsModal({ open: true });
                }
              }}
            >
              Assign Students ({assignStudents.length})
            </SeminarButton>
          </div>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} value={query} minLength={1} />
          </div>
        </div>
        {enrollments.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>REGISTRATION NO.</TableHeader>
                <TableHeader>LATEST PRESENTATION</TableHeader>
                <TableHeader>INSTRUCTOR</TableHeader>
                <TableHeader>SCHOOL</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {enrollments.map((enrollment) => (
                <tr key={enrollment.id}>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/students/${enrollment.student.id}/overview`}
                    >
                      {highlightText(
                        fullName(
                          enrollment.student.first_name,
                          enrollment.student.middle_name,
                          enrollment.student.last_name
                        ),
                        query
                      )}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <AddRegistrationNumber
                      enrollment={enrollment}
                      reloadStudents={() => setReloadStudents(true)}
                      query={query}
                    />
                  </TableCell>
                  <TableCell>{enrollment.latest_presentation?.name}</TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/instructors/${enrollment.student.instructor_id}/overview`}
                    >
                      {enrollment.student.instructor_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/schools/${enrollment.student.school.id}/overview`}
                    >
                      {enrollment.student.school.school_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center cursor-pointer">
                      <PrinterIcon className="mr-2 w-5" />
                      <p>Print Ticket</p>
                    </div>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="text-center">No students found.</p>
        )}
      </Card>
      <Modal
        open={newStudentModal.open}
        setOpen={() => setNewStudentModal({ open: false, seminar: null })}
      >
        <NewStudent
          seminar={newStudentModal.seminar}
          closeModal={() => setNewStudentModal({ open: false, seminar: null })}
          reloadStudents={() => setReloadStudents(true)}
          user={user}
        />
      </Modal>
      <Modal
        open={assignStudentsModal.open}
        setOpen={() => setAssignStudentsModal({ open: false })}
        fullWidth
      >
        <AssignStudents
          closeModal={() => {
            setAssignStudentsModal({ open: false });
            setReloadStudents(true);
          }}
          seminar={seminar}
          assignStudents={assignStudents}
          recentlyAssigned={recentlyAssigned}
          setRecentlyAssigned={setRecentlyAssigned}
          getRecentlyAssigned={getRecentlyAssigned}
        />
      </Modal>
    </div>
  );
}
