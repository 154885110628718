import React from "react";
import { twMerge } from "tailwind-merge";

export default function ListButtonGroupItem({
  label,
  name,
  isFirst,
  isLast,
  selected,
  setSelected,
  disabled,
}) {
  const active = selected === (name || label);

  return (
    <button
      onClick={setSelected && (() => setSelected(name || label))}
      type="button"
      disabled={disabled}
      className={twMerge(
        active && "bg-gray-600 text-white",
        isFirst && "rounded-s-full border-l",
        isLast && "rounded-e-full border-r",
        disabled && "opacity-75",
        "border-b border-r border-t border-black px-4 py-2 text-sm font-medium hover:bg-gray-600 hover:text-white transition-colors animation-200"
      )}
    >
      {label}
    </button>
  );
}
