import React, { useEffect, useState } from "react";
import Input from "../../../UI/Inputs/Input";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import InputGroup from "../../../UI/Inputs/InputGroup";

export default function CourseForm({ course }) {
  const [reports, setReport] = useState([]);

  useEffect(() => {
    setReport(["Work in progress"]);
  }, []);

  return (
    <>
      <InputGroup>
        <Input
          id="name"
          placeholder="Course Name"
          defaultValue={course?.name}
          label="Course Name"
          classNames="2xl:w-[640px] xl:w-[500px] lg:w-[300px]"
          isRequired
        />
        <div className="lg:flex items-end">
          <Input
            id="instructor_followup_notification_delay"
            type="number"
            min="1"
            defaultValue={course?.instructor_followup_notification_delay}
            label="Instructor follow-up"
            classNames="2xl:w-[160px] mr-4"
          />
          <p className="text-xs">default 7 days</p>
        </div>
        <div className="lg:flex items-end">
          <Input
            id="head_admin_followup_notification_delay"
            type="number"
            min="1"
            defaultValue={course?.head_admin_followup_notification_delay}
            label="Admin follow-up"
            classNames="2xl:w-[160px] mr-4"
          />
          <p className="text-xs">default 14 days</p>
        </div>
        <Select id="reports">
          <SelectItem value="" disabled selected>
            Select a report
          </SelectItem>
          {reports.map((option) => (
            <SelectItem disabled key={option} value={`${option}`}>
              {option}
            </SelectItem>
          ))}
        </Select>
      </InputGroup>

      <div className="lg:w-1/2">
        <InputGroup>
          <Input
            id="square_access_token"
            label="Square Access Token"
            defaultValue={course?.square_access_token}
          />
        </InputGroup>
      </div>
    </>
  );
}
