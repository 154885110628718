import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/available_advertising_methods`;

const getAvailableAdvertisingMethods = async () => {
  const data = await axiosClient.get(endpoint);

  return data;
};

export { getAvailableAdvertisingMethods };
