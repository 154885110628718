import TableCell from "../../UI/Table/TableCell";
import React, { useEffect, useState } from "react";
import { updateStudentAveryLabels } from "../../../requests/students";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";
import { highlightText } from "../../../utils/highlightText";
import localeFormat from "../../../utils/dateAndTime/localeFormat";
import Button from "../../UI/Button";
import { CheckIcon, MinusIcon } from "@heroicons/react/16/solid";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

export default function IncludeInMailingListRow({ student, loading, query }) {
  const [includeInMailingList, setIncludeInMailingList] = useState(
    student.include_in_mailing_list
  );
  const [cachedAddressValid, setCachedAddressValid] = useState(
    student.cached_address_valid
  );
  const [updatedAt, setUpdateAt] = useState(student.updated_at);

  useEffect(() => {
    setIncludeInMailingList(student.include_in_mailing_list);
    setCachedAddressValid(student.cached_address_valid);
    setUpdateAt(student.updated_at);
  }, [student]);

  const handleChange = async (event) => {
    try {
      let value = "never";
      let validAddress = false;

      if (event.target.innerText === "Include") {
        value = "if_address_valid";
        if (
          student.address &&
          student.city &&
          student.state &&
          student.zip_code &&
          student.country
        ) {
          validAddress = true;
        }
      } else if (event.target.innerText === "Exclude") {
        value = "always";
      }

      const res = await updateStudentAveryLabels({
        studentId: student.id,
        include_in_mailing_list: value,
        cached_address_valid: validAddress,
      });

      if (res.data) {
        setIncludeInMailingList(res.data.include_in_mailing_list);
        setCachedAddressValid(res.data.cached_address_valid);
        setUpdateAt(res.data.updated_at);
        ToastNotification("success", "Updated successfully.");
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <tr
      key={student.id}
      className={`${loading ? "opacity-25" : undefined}, ${includeInMailingList === "never" || (includeInMailingList === "if_address_valid" && !cachedAddressValid) ? "bg-[#FDD]" : ""}`}
    >
      <TableCell classNames="pr-10">
        <Button
          variant="primary-outline"
          icon
          classNames="w-full"
          onClick={(e) => handleChange(e)}
        >
          {includeInMailingList === "always" ? (
            <CheckIcon className="w-5 mr-1" />
          ) : includeInMailingList === "never" ? (
            <MinusIcon className="w-5 mr-1" />
          ) : cachedAddressValid ? (
            <CheckCircleIcon className="w-5 mr-1" />
          ) : (
            <ExclamationCircleIcon className="w-5 mr-1" />
          )}

          {includeInMailingList === "always"
            ? "Include"
            : includeInMailingList === "never"
              ? "Exclude"
              : `Auto - ${cachedAddressValid ? "Valid" : "Invalid"}`}
        </Button>
      </TableCell>
      <TableCell classNames="pr-10">
        {highlightText(student.first_name, query)}
      </TableCell>
      <TableCell classNames="pr-10">
        {highlightText(student.last_name, query)}
      </TableCell>
      <TableCell classNames="pr-10">{student.address}</TableCell>
      <TableCell classNames="pr-10">{student.address_2}</TableCell>
      <TableCell classNames="pr-10">{student.city}</TableCell>
      <TableCell classNames="pr-10">{student.state}</TableCell>
      <TableCell classNames="pr-10">{student.zip_code}</TableCell>
      <TableCell classNames="pr-10">{student.country}</TableCell>
      <TableCell>{localeFormat(updatedAt)}</TableCell>
    </tr>
  );
}
