import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function DesktopNavigation({ baseNavigation }) {
  return (
    <div className="container mx-auto flex justify-center">
      {baseNavigation.map((item) => (
        <NavLink
          key={item.name}
          to={item.href}
          target={item.blank ? "_blank" : undefined}
          className={({ isActive }) =>
            twMerge(isActive && "text-primary-600", "px-6")
          }
        >
          <div className="inline-flex flex-col items-center justify-start gap-1 transition-all hover:scale-105">
            <div>{item.icon()}</div>
            <p>{item.name}</p>
          </div>
        </NavLink>
      ))}
    </div>
  );
}
