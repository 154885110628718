import React, { useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import Filter from "../UI/Filter/Filter";
import { getAllUsers } from "../../requests/users";
import { getAllCourses } from "../../requests/courses";
import { getAllSeminars } from "../../requests/seminars";
import { getAllSchools } from "../../requests/schools";
import { getAllCountries } from "../../requests/countries";
import { getStatesByCountryName } from "../../requests/states";
import { getAllSeminarDecisionsRequest } from "../../requests/seminars";
import { getAllConferences } from "../../requests/conferences";

export default function StudentsFilter() {
  const { user } = useOutletContext();

  const getInstructors = useCallback(async () => {
    try {
      const res = await getAllUsers({
        params: { type: "Instructor", pagination: false },
      });
      return res.data.users.map((user) => ({
        label: `${user.first_name} ${user.last_name} (${user.email})`,
        value: user.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getCourses = useCallback(async () => {
    try {
      const res = await getAllCourses({
        params: { pagination: false },
      });
      return res.data.courses.map((course) => ({
        label: course.name,
        value: course.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSeminars = useCallback(async () => {
    try {
      const res = await getAllSeminars({
        params: { pagination: false },
      });
      return res.data.seminars.map((seminar) => ({
        label: seminar.name,
        value: seminar.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSchools = useCallback(async () => {
    try {
      const res = await getAllSchools({
        params: { pagination: false },
      });
      return res.data.schools.map((school) => ({
        label: `(#${school.school_code}) ${school.school_name}`,
        value: school.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getCountries = useCallback(async () => {
    try {
      const res = await getAllCountries();
      return res.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getStates = useCallback(async (countryName) => {
    try {
      const res = await getStatesByCountryName({ countryName });
      return res.data.map((state) => ({
        label: `${state.name} (${state.abbreviation})`,
        value: state.abbreviation,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSeminarDecisions = useCallback(async (seminarId) => {
    if (!seminarId) return [];

    try {
      const res = await getAllSeminarDecisionsRequest({ seminarId });
      return res.data.map((decisionOption) => ({
        label: decisionOption.name,
        value: decisionOption.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getConferences = useCallback(async () => {
    try {
      const res = await getAllConferences();
      return res.data.map((conference) => ({
        label: conference.name,
        value: conference.conference_code,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const filterAttributes = {
    enrollments_active: {
      label: "Active status",
      operators: [
        { label: "active", value: "true" },
        { label: "inactive", value: "false" },
      ],
    },
    prisoner_number: {
      label: "Prisoner status",
      operators: [
        { label: "is a prisoner", value: "present" },
        { label: "is not a prisoner", value: "blank" },
      ],
    },
    instructor_is_me_id: {
      label: "Instructor is me",
      operators: [
        { label: "is me", value: "eq" },
        { label: "is not me", value: "not_eq" },
      ],
      defaultOperator: "eq",
      hiddenOption: user?.id,
    },
    instructors_shared_with_me_id: {
      label: "Shared with me",
      operators: [
        { label: "is shared with me", value: "eq" },
        { label: "is not shared with me", value: "not_eq" },
      ],
      defaultOperator: "eq",
      hiddenOption: user?.id,
    },
    student_shares_instructor_id: {
      label: "Shared with",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getInstructors,
      autocomplete: true,
    },
    instructor_id: {
      label: "Instructor",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getInstructors,
      autocomplete: true,
    },
    enrollments_course_id: {
      label: "Course",
      operators: [
        { label: "equal", value: "eq" },
        { label: "any course", value: "not_null" },
      ],
      defaultOperator: "eq",
      options: getCourses,
      autocomplete: true,
    },
    enrollments_seminar_id: {
      label: "Seminar",
      operators: [
        { label: "equal", value: "eq" },
        { label: "any seminar", value: "not_null" },
      ],
      defaultOperator: "eq",
      options: getSeminars,
      autocomplete: true,
    },
    enrollments_course_status: {
      label: "Course enrollment status",
      operators: [
        { label: "equal", value: "eq" },
        { label: "any status", value: "present" },
      ],
      defaultOperator: "eq",
      options: [
        { label: "Applied", value: "applied" },
        { label: "Enrolled", value: "enrolled" },
        { label: "Graduated", value: "graduated" },
        { label: "Inactive", value: "inactive" },
      ],
    },
    enrollments_seminar_status: {
      label: "Seminar enrollment status",
      operators: [
        { label: "equal", value: "eq" },
        { label: "any status", value: "present" },
      ],
      defaultOperator: "eq",
      options: [
        { label: "Applied", value: "applied" },
        { label: "Attended", value: "attended" },
        { label: "Inactive", value: "inactive" },
      ],
    },
    decisions_decision_option_id: {
      label: "Seminar decisions",
      operators: ["eq"],
      defaultOperator: "eq",
      options: getSeminarDecisions,
      dependsOn: "enrollments_seminar_id",
    },
    decisions_created_at: {
      label: "Decisions made",
      type: "date",
      defaultOperator: "between",
    },
    school_id: {
      label: "School",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getSchools,
      autocomplete: true,
    },
    school_conference_code: {
      label: "School Conference",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getConferences,
      autocomplete: true,
    },
    country: {
      label: "Country",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getCountries,
    },
    state: {
      label: "State",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getStates,
      autocomplete: true,
      dependsOn: "country",
    },
    baptized: {
      label: "Baptized",
      operators: [
        { label: "baptized", value: "true" },
        { label: "not baptized", value: "false" },
      ],
      defaultOperator: "true",
    },
    enrollments_cached_latest_followup_date: {
      label: "Latest follow-up",
      type: "date",
      defaultOperator: "between",
    },
    created_at: {
      label: "Record created",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
    updated_at: {
      label: "Last updated",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
  };

  return <Filter attributes={filterAttributes} />;
}
