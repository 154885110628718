import React from "react";
import Print from "../UI/Print";
import { addressMultiLine } from "../../utils/fullAddress";

const SchoolsPrint = ({ schools, handleAfterPrint }) => {
  return (
    <Print
      header="SCHOOLS"
      empty={schools.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>NAME</th>
            <th>ADDRESS</th>
            <th>COURSES</th>
            <th>CONFERENCE</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {schools.length > 0 &&
            schools.map((school) => (
              <tr key={school.id}>
                <td>
                  (#{school.school_code}) {school.school_name}
                </td>
                <td className="whitespace-nowrap">
                  {addressMultiLine({
                    address: school.school_address,
                    address_2: school.school_address_2,
                    city: school.school_city,
                    state: school.school_state,
                    zip_code: school.school_zip_code,
                    country: school.school_country,
                  })}
                </td>
                <td>
                  {school.courses.map((course) => course.name).join(", ")}
                </td>
                <td>{school.conference?.name}</td>
                <td>{school.active ? "Active" : "Inactive"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Print>
  );
};

export default SchoolsPrint;
