import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Button from "../../../UI/Button";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentMobileCourses from "./Mobile/StudentMobileCourses";
import {
  Link,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { getAllSchoolCourses } from "../../../../requests/schools";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import { ToastNotification } from "../../../UI/ToastNotification";
import { createEnrollmentRequest } from "../../../../requests/enrollments";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { hoverLink } from "../../../../utils/hoverLink";
import dayjs from "dayjs";

export default function StudentCourses() {
  const student = useRouteLoaderData("student").data;
  const navigate = useNavigate();

  const [enrollmentModal, setEnrollmentModal] = useState(false);
  const [courses, setCourses] = useState();
  const [selectedCourse, setSelectedCourse] = useState(null);

  const params = useParams();
  const studentId = params.studentId;

  const getCourses = async () => {
    const res = await getAllSchoolCourses({
      school_id: student.school_id,
      student_id: student.id,
    });

    if (res.data) {
      setCourses(res.data);
    }
  };

  const handleEnrollment = (courseId) => {
    setSelectedCourse(courseId);
    setEnrollmentModal(true);
  };

  const createEnrollment = async () => {
    try {
      const res = await createEnrollmentRequest({
        student_id: student.id,
        series_id: selectedCourse,
        school_id: student.school_id,
      });

      if (res.data) {
        ToastNotification("success", "Enrollment created successfully.");
        navigate(
          `/students/${studentId}/courses/enrollments/${res.data.id}/lessons`
        );
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const returnStudentEnrollment = (courseId) => {
    return student.course_enrollments.find((courseEnrollment) => {
      return courseEnrollment.series_id === courseId;
    });
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileCourses
          courses={courses}
          returnStudentEnrollment={returnStudentEnrollment}
        />
      </div>
      <Card classNames="md:block hidden">
        {courses?.length ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>COURSE</TableHeader>
                <TableHeader>PROGRESS</TableHeader>
                <TableHeader>DECISIONS</TableHeader>
                <TableHeader>INSTRUCTOR</TableHeader>
                <TableHeader>LAST REMINDER DATE</TableHeader>
                <TableHeader>STATUS</TableHeader>
                <TableHeader>ACTIONS</TableHeader>
              </tr>
            </thead>
            <tbody>
              {courses.map((course) => {
                const enrollment = returnStudentEnrollment(course.id);

                return (
                  <tr key={course.id}>
                    <TableCell>
                      {enrollment ? (
                        <Link
                          className={hoverLink}
                          to={`/students/${studentId}/courses/enrollments/${enrollment.id}/lessons`}
                        >
                          {course.name}
                        </Link>
                      ) : (
                        <p>{course.name}</p>
                      )}
                    </TableCell>
                    <TableCell>{enrollment?.progress}</TableCell>
                    <TableCell>{enrollment?.decisions_count}</TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/instructors/${student.instructor_id}/overview`}
                      >
                        {student.instructor_name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {enrollment?.last_reminder_date &&
                        dayjs(enrollment?.last_reminder_date).format(
                          "MM/DD/YYYY"
                        )}
                    </TableCell>
                    <TableCell>
                      {enrollment &&
                        returnEnrollmentStatus(course.id, enrollment)}
                    </TableCell>
                    <TableCell>
                      {enrollment ? (
                        <Button variant="outline">Delete</Button>
                      ) : (
                        <Button
                          onClick={() => handleEnrollment(course.id)}
                          variant="primary-outline"
                        >
                          Enroll
                        </Button>
                      )}
                    </TableCell>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p className="text-center">No courses offered by the school</p>
        )}
      </Card>

      <Modal dark open={enrollmentModal} setOpen={setEnrollmentModal}>
        <ConfirmationDialog
          closeModal={() => setEnrollmentModal(false)}
          confirmAction={() => createEnrollment()}
        />
      </Modal>
    </>
  );
}
