import React from "react";
import { twMerge } from "tailwind-merge";

export default function PageLoader({ className, variant }) {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center h-[75vh] md:h-[300px]",
        className
      )}
    >
      <div role="status">
        <div
          className={twMerge(
            "animate-spin rounded-full h-14 w-14 border-b-2 md:border-primary",
            variant === "primary" && "border-primary",
            variant === "secondary" && "border-secondary"
          )}
        ></div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
