import React, { useState } from "react";
import DatePickerInput from "../../../../../../UI/Inputs/DatePickerInput";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../../../../UI/Button";
import InputGroup from "../../../../../../UI/Inputs/InputGroup";
import dayjs from "dayjs";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";
import DateIcon from "../../../../../../UI/Icons/DateIcon";

export default function DateReceived({
  lesson,
  enrollment,
  episodeDelivery,
  user,
}) {
  const [formOpen, setFormOpen] = useState(false);
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            date_completed: data.date_completed,
            episode_id: lesson.id,
            grading_instructor_id: user.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        setFormOpen(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  if (!formOpen) {
    return (
      <button
        onClick={() => setFormOpen(true)}
        className="rounded-full border-transparent border text-secondary hover:border-secondary p-2 transition-colors"
      >
        {episodeDelivery?.date_completed ? (
          dayjs(episodeDelivery.date_completed).format("MM/DD/YYYY")
        ) : (
          <DateIcon />
        )}
      </button>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputGroup classNames="mb-0 flex justify-center gap-2 lg:gap-2">
          <DatePickerInput
            id="date_completed"
            selected={episodeDelivery?.date_completed}
            autoFocus
            classNames="w-[130px]"
          />
          <Button onClick={() => setFormOpen(false)} variant="primary-outline">
            Cancel
          </Button>
          <Button type="submit" variant="primary-outline">
            Save
          </Button>
        </InputGroup>
      </form>
    </FormProvider>
  );
}
