import React from "react";
import {
  Outlet,
  useLoaderData,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Card from "../../../../UI/Card";
import { useMediaQuery } from "../../../../../utils/useMediaQuery";
import StudentCourseNav from "./StudentCourseNav";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

export default function StudentCourse() {
  const isMobile = useMediaQuery("md", "down");
  const { user } = useOutletContext();
  const enrollment = useLoaderData().data;
  const seriesEnrollment = "courseEnrollment";
  const params = useParams();
  const studentId = params.studentId;

  return isMobile ? (
    <>
      <div className="flex items-center gap-2">
        <Link to={`/students/${enrollment?.student_id}/courses`}>
          <ArrowLeftIcon className="mr-1 w-7" />
        </Link>
        <span className="tracking-tight leading-none text-[24px] text-center flex-1">
          {enrollment?.series.name}
        </span>
        <span className="w-7" />
      </div>

      <div className="mt-4">
        <Outlet context={{ user, seriesEnrollment }} />
      </div>
    </>
  ) : (
    <Card>
      <StudentCourseNav enrollmentId={enrollment?.id} studentId={studentId} />

      <h1 className="text-2xl">{enrollment?.series.name}</h1>

      <div className="mt-6">
        <Outlet context={{ user, seriesEnrollment }} />
      </div>
    </Card>
  );
}
