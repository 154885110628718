import React, { useCallback } from "react";
import Filter from "../UI/Filter/Filter";
import { getAllCountries } from "../../requests/countries";
import { getStatesByCountryName } from "../../requests/states";
import { getAllConferences } from "../../requests/conferences";

export default function SchoolsFilter() {
  const getConferences = useCallback(async () => {
    try {
      const res = await getAllConferences();
      return res.data.map((conference) => ({
        label: conference.name,
        value: conference.conference_code,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getCountries = useCallback(async () => {
    try {
      const res = await getAllCountries();
      return res.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getStates = useCallback(async (countryName) => {
    try {
      const res = await getStatesByCountryName({ countryName });
      return res.data.map((state) => ({
        label: `${state.name} (${state.abbreviation})`,
        value: state.abbreviation,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const filterAttributes = {
    active: {
      label: "Status",
      operators: [
        { label: "Active", value: "true" },
        { label: "Inactive", value: "false" },
      ],
    },
    conference_code: {
      label: "Conference",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getConferences,
      autocomplete: true,
    },
    discovery_center: {
      label: "Discovery center",
      operators: [
        { label: "Is a Discovery Center", value: "true" },
        { label: "Not a Discovery Center", value: "false" },
      ],
    },
    school_country: {
      label: "School Country",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getCountries,
    },
    school_state: {
      label: "School State",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getStates,
      autocomplete: true,
      dependsOn: "school_country",
    },
    created_at: {
      label: "Record created",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
    updated_at: {
      label: "Record updated",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
  };

  return <Filter attributes={filterAttributes} />;
}
