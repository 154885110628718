import React from "react";
import { useFormContext } from "react-hook-form";

export default function ToggleSwitch({
  id,
  label,
  defaultChecked,
  labelPosition = "right",
}) {
  const { register } = useFormContext();

  const renderLabel = (
    <span className="text-sm font-medium text-black text-[17px]">{label}</span>
  );

  return (
    <label className="inline-flex items-center cursor-pointer w-fit">
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        className="sr-only peer"
        {...register(id)}
      />
      {labelPosition === "left" && <div className="mr-3">{renderLabel}</div>}
      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary"></div>
      {labelPosition === "right" && <div className="ml-3">{renderLabel}</div>}
    </label>
  );
}
