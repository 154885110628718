import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import AddButton from "../../../UI/AddButton";
import EmptyTable from "../../../UI/EmptyTable";
import CourseProductsNew from "./CourseProductsNew";
import { useRouteLoaderData } from "react-router-dom";
import { getAllCourseProductsRequest } from "../../../../requests/courses";
import { deleteProductRequest } from "../../../../requests/products";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import CourseProductsRow from "./CourseProductsRow";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function CourseProducts() {
  const course = useRouteLoaderData("course").data;

  const [products, setProducts] = useState([]);
  const [formOpen, setFormOpen] = useState({ open: false, action: null });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeProductId, setActiveProductId] = useState(null);

  const getAllCourseProducts = async () => {
    const res = await getAllCourseProductsRequest({ courseId: course.id });

    if (res.data) {
      setProducts(res.data);
    }
  };

  const deleteProduct = async () => {
    try {
      const res = await deleteProductRequest({
        productId: activeProductId,
      });

      if (res) {
        ToastNotification("success", "Product deleted successfully.");
        setDeleteModalOpen(false);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const handleDeleteModal = (e, productId) => {
    e.preventDefault();
    setDeleteModalOpen(true);
    setActiveProductId(productId);
  };

  useEffect(() => {
    getAllCourseProducts();
  }, [formOpen, deleteModalOpen]);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            <img
              src="/add-new.svg"
              className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
              width={30}
              height={30}
              alt="Add New"
              onClick={() => setFormOpen({ open: true, action: "new" })}
            />

            <h1 className="text-xl font-bold text-primary">PRODUCTS</h1>
          </div>
        </div>
        {products.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>SKU</TableHeader>
                <TableHeader>PRODUCT</TableHeader>
                <TableHeader>DEFAULT PRICE</TableHeader>
                <TableHeader>GIVEN/SOLD TO</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <CourseProductsRow
                  key={product.id}
                  product={product}
                  handleDeleteModal={handleDeleteModal}
                  formOpen={formOpen}
                  setFormOpen={setFormOpen}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No products found." />
        )}

        {formOpen.action === "new" && (
          <div>
            <CourseProductsNew course={course} setFormOpen={setFormOpen} />
          </div>
        )}

        {formOpen.action === "new" ? null : (
          <div className="flex justify-center mt-4">
            <AddButton
              onClick={() => setFormOpen({ open: true, action: "new" })}
              label="Add Product"
              variant="primary-outline"
            />
          </div>
        )}
      </Card>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteProduct()}
        />
      </Modal>
    </div>
  );
}
