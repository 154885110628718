import React from "react";
import { IconWrapper } from "./IconWrapper";

export const ReassignIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M3.74999 14.0833C3.74999 11.4292 5.65666 9.24083 8.17 8.76417L6.55583 10.3892L8.08333 11.9167L12.4167 7.5725L8.08333 3.25L6.55583 4.7775L8.2675 6.48917V6.55417C4.50833 6.99833 1.58333 10.205 1.58333 14.0833C1.58333 18.2758 4.97416 21.6667 9.16666 21.6667H12.4167V19.5H9.16666C6.17666 19.5 3.74999 17.0733 3.74999 14.0833Z" />
    <path d="M14.5833 14.0833V21.6667H24.3333V14.0833H14.5833ZM22.1667 19.5H16.75V16.25H22.1667V19.5Z" />
    <path d="M24.3333 4.33334H14.5833V11.9167H24.3333V4.33334Z" />
  </IconWrapper>
);

export const ShareIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M22.1667 9.75001V6.50001H20V9.75001H16.75V11.9167H20V15.1667H22.1667V11.9167H25.4167V9.75001H22.1667ZM10.25 13C12.6442 13 14.5833 11.0608 14.5833 8.66668C14.5833 6.27251 12.6442 4.33334 10.25 4.33334C7.85584 4.33334 5.91667 6.27251 5.91667 8.66668C5.91667 11.0608 7.85584 13 10.25 13ZM10.25 6.50001C11.4417 6.50001 12.4167 7.47501 12.4167 8.66668C12.4167 9.85834 11.4417 10.8333 10.25 10.8333C9.05834 10.8333 8.08334 9.85834 8.08334 8.66668C8.08334 7.47501 9.05834 6.50001 10.25 6.50001ZM17.1725 15.7733C15.3525 14.8417 12.9908 14.0833 10.25 14.0833C7.50917 14.0833 5.1475 14.8417 3.3275 15.7733C2.24417 16.3258 1.58334 17.4417 1.58334 18.655V21.6667H18.9167V18.655C18.9167 17.4417 18.2558 16.3258 17.1725 15.7733ZM16.75 19.5H3.75V18.655C3.75 18.2433 3.96667 17.875 4.31334 17.7017C5.6025 17.0408 7.6825 16.25 10.25 16.25C12.8175 16.25 14.8975 17.0408 16.1867 17.7017C16.5333 17.875 16.75 18.2433 16.75 18.655V19.5Z" />
  </IconWrapper>
);

export const CancelSharingIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M22.1667 18.6008L18.5158 14.9392C19.2092 15.1775 19.8483 15.4592 20.4333 15.7625C21.4842 16.315 22.145 17.4092 22.1667 18.6008ZM23.4558 22.9558L21.9283 24.4833L19.1008 21.6667H4.83333V18.655C4.83333 17.4417 5.49417 16.3258 6.5775 15.7733C7.975 15.0583 9.68667 14.4517 11.6367 14.2025L2.00583 4.57166L3.53333 3.04416L23.4558 22.9558ZM16.9342 19.5L13.6842 16.25C13.6192 16.25 13.565 16.25 13.5 16.25C10.9325 16.25 8.8525 17.0408 7.56333 17.7017C7.21667 17.875 7 18.2433 7 18.655V19.5H16.9342ZM13.5 6.49999C14.6917 6.49999 15.6667 7.47499 15.6667 8.66666C15.6667 9.59833 15.0817 10.3892 14.2583 10.6925L15.8617 12.2958C17.0533 11.5267 17.8333 10.1833 17.8333 8.66666C17.8333 6.27249 15.8942 4.33333 13.5 4.33333C11.9833 4.33333 10.64 5.11333 9.87083 6.30499L11.4742 7.90833C11.7775 7.08499 12.5683 6.49999 13.5 6.49999Z" />
  </IconWrapper>
);

export const UploadCSVIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M5.26667 5.00741V21.8593H19.5667V24.2667H5.26667C4.075 24.2667 3.1 23.1833 3.1 21.8593V5.00741C3.1 3.68334 4.075 2.60001 5.26667 2.60001H20.4333C21.625 2.60001 22.6 3.68334 22.6 5.00741V16.9H25.2V19.3074L22.6 19.3074V21.9074L20.4333 21.9074V19.3074L17.8333 19.3074V16.9H20.4333V5.00741H5.26667Z" />
    <path d="M15.0167 19.4519H7.43333V17.0445H15.0167V19.4519ZM18.2667 14.637H7.43333V12.2296H18.2667V14.637ZM18.2667 9.82223H7.43333V7.41483H18.2667V9.82223Z" />
  </IconWrapper>
);

export const DownloadCSVIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M15.6668 24.3747H5.91685C4.72518 24.3747 3.75018 23.3997 3.75018 22.208V4.87467C3.75018 3.68301 4.72518 2.70801 5.91685 2.70801H14.5835L21.0835 9.20801V15.708H18.9168V10.2913H13.5002V4.87467H5.91685V22.208H15.6668V24.3747ZM23.2502 18.958L21.0835 21.1247V15.708H18.9168V21.1247L16.7502 18.958V21.6663L20.0002 24.3747L23.2502 21.6663V18.958Z" />
  </IconWrapper>
);

export const PrintIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M21.0833 8.66667H20V2.91667H7V8.66667H5.91666C4.11833 8.66667 2.66666 10.205 2.66666 12.1167V19.0833H7V23.6667H20V19.0833H24.3333V12.1167C24.3333 10.205 22.8817 8.66667 21.0833 8.66667ZM9.16666 5.2H17.8333V8.66667H9.16666V5.2ZM17.8333 19.0833V21.3833H9.16666V16.7667H17.8333V19.0833ZM20 16.7667V14.4667H7V16.7667H4.83333V12.1167C4.83333 11.4792 5.32083 10.95 5.91666 10.95H21.0833C21.6792 10.95 22.1667 11.4792 22.1667 12.1167V16.7667H20Z" />
    <path d="M20 13.8417C20.5983 13.8417 21.0833 13.3239 21.0833 12.6833C21.0833 12.0428 20.5983 11.525 20 11.525C19.4017 11.525 18.9167 12.0428 18.9167 12.6833C18.9167 13.3239 19.4017 13.8417 20 13.8417Z" />
  </IconWrapper>
);

export const SharpIcon = ({ color }) => (
  <IconWrapper color={color}>
    <path d="M21.6667 10.8333V8.66665H17.3333V4.33331H15.1667V8.66665H10.8333V4.33331H8.66668V8.66665H4.33334V10.8333H8.66668V15.1666H4.33334V17.3333H8.66668V21.6666H10.8333V17.3333H15.1667V21.6666H17.3333V17.3333H21.6667V15.1666H17.3333V10.8333H21.6667ZM15.1667 15.1666H10.8333V10.8333H15.1667V15.1666Z" />
  </IconWrapper>
);
