import React, { useState } from "react";
import { getAllSchools } from "../../../../requests/schools";
import { getAllSeminars } from "../../../../requests/seminars";
import SearchAsync from "../../../UI/SearchAsync";

const SearchSchoolAsync = ({ field, light = false, onChange }) => {
  const loadOptions = async (inputValue) => {
    try {
      const response = await getAllSchools({
        params: {
          q: {
            school_code_or_church_name_i_cont_all: inputValue.trim().split(" "),
            s: "church_name asc",
          },
        },
      });

      return response.data.schools.map((school) => ({
        label: `(#${school.school_code}) ${school.church_name}`,
        value: school.id,
      }));
    } catch (error) {
      return [];
    }
  };

  return (
    <SearchAsync
      {...field}
      light={light}
      className="w-full"
      placeholder="Search School"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

const SearchSeminarAsync = ({ field, light = false, q = {}, onChange }) => {
  const loadOptions = async (inputValue = "") => {
    try {
      const response = await getAllSeminars({
        params: {
          q: {
            ...q,
            name_i_cont_all: inputValue.trim().split(" "),
            s: "name asc",
          },
        },
      });

      return response.data.seminars.map((seminar) => ({
        label: seminar.name,
        value: seminar.id,
      }));
    } catch (error) {
      return [];
    }
  };

  return (
    <SearchAsync
      {...field}
      light={light}
      className="w-full"
      placeholder="Search Seminar"
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default function SeminarPrintTicketsFilter({ seminar }) {
  const [selectedSchoolId, setSelectedSchoolId] = useState();

  if (!seminar) return null;

  return (
    <div className="flex gap-4">
      <SearchSchoolAsync
        light
        className="w-full"
        onChange={(e) => setSelectedSchoolId(e?.value)}
        key={`school-search-${seminar?.id}`}
      />

      {selectedSchoolId && (
        <SearchSeminarAsync
          light
          q={{ host_school_id_eq: selectedSchoolId }}
          className="w-full"
          onChange={(e) => {
            window.location.href = `/seminars/${e?.value}/print-tickets`;
          }}
          key={`seminar-search-${selectedSchoolId}`}
        />
      )}
    </div>
  );
}
