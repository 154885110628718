import React from "react";

const DateIcon = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 19 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0413 2.87508H15.833C16.7038 2.87508 17.4163 3.58758 17.4163 4.45842V17.1251C17.4163 17.9959 16.7038 18.7084 15.833 18.7084H3.16634C2.29551 18.7084 1.58301 17.9959 1.58301 17.1251V4.45842C1.58301 3.58758 2.29551 2.87508 3.16634 2.87508H3.95801V1.29175H5.54134V2.87508H13.458V1.29175H15.0413V2.87508ZM3.16634 17.1251H15.833V8.41675H3.16634V17.1251ZM3.16634 6.83342H15.833V4.45842H3.16634V6.83342ZM9 10.5V12.5H7V13.5H9V15.5H10V13.5H12V12.5H10V10.5H9Z"
    />
  </svg>
);

export default DateIcon;
