import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function NavGroupItem({ label, url }) {
  const activeLink =
    "bg-gradient-to-r from-primary-400 to-primary text-white rounded-full";

  return (
    <NavLink
      to={url}
      className={({ isActive }) =>
        twMerge(
          isActive && activeLink,
          "min-w-[100px] px-4 py-1.5 text-sm font-medium whitespace-nowrap"
        )
      }
    >
      {label}
    </NavLink>
  );
}
