import React from "react";
import { returnEnrollmentStatus } from "../../utils/enrollmentStatus";
import dayjs from "dayjs";

export default function StudentEnrollmentsMobile({ student }) {
  if (student.enrollments.length === 0) return null;

  return student.enrollments.map((enrollment) => (
    <span
      key={enrollment.id}
      className="flex flex-row justify-between py-2 px-4 gap-2"
    >
      {enrollment.series_type === "Seminar" ? (
        <>
          <span className="w-5/12 text-sm leading-[1.2]">
            {enrollment.series_name}
          </span>
          <span className="w-4/12 text-xs leading-[1.2]">
            {enrollment.registration_number || "-"}
          </span>
        </>
      ) : (
        <>
          <span className="w-5/12 text-sm leading-[1.2]">
            {enrollment.series_name}
            {enrollment.lesson_number > 0 && (
              <span className="text-xs w-4 h-4 ml-1 inline-block rounded-full bg-gray-200 text-center leading-4">
                {enrollment.lesson_number}
              </span>
            )}
          </span>
          <span className="w-4/12 text-xs leading-[1.2]">
            {enrollment.last_completed_delivery
              ? dayjs(
                  enrollment.last_completed_delivery?.date_completed
                ).format("MMM D, YYYY")
              : "No lessons"}
          </span>
        </>
      )}
      <span className="w-3/12 text-xs leading-[1.2]">
        {returnEnrollmentStatus(enrollment.series_id, enrollment)}
      </span>
    </span>
  ));
}
