import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../UI/Card";
import PageLoader from "../UI/PageLoader";
import { getVersion } from "../../requests/versions";
import Table from "../UI/Table/Table";
import TableCell from "../UI/Table/TableCell";
import VersionsTable from "./VersionsTable";

export default function Version() {
  const { id } = useParams();
  const [version, setVersion] = useState();

  const fetchVersion = () => {
    getVersion(id).then((res) => {
      setVersion(res.data.version);
    });
  };

  useEffect(() => {
    if (id) fetchVersion();
  }, [id]);

  if (!version)
    return (
      <Card>
        <PageLoader />
      </Card>
    );

  return (
    <Card classNames="mt-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-bold text-primary mr-8 uppercase">
          {version.event} ON {version.item_type}
        </h1>
      </div>

      <VersionsTable versions={[version]} showDetails />

      <div className="mt-8">
        <h2 className="text-lg font-bold text-primary mb-2 uppercase">
          Version Details
        </h2>

        <Table>
          <tbody>
            {Object.entries(version)
              .filter((attr) => attr[1] !== null)
              .map(([key, value]) => (
                <tr key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell classNames="whitespace-pre-wrap break-all">
                    {JSON.stringify(value)}
                  </TableCell>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="text-sm mt-8">
        Note: attributes with <b>null</b> values are not shown in version
        details.
      </div>
    </Card>
  );
}
