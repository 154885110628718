import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import { useFormContext } from "react-hook-form";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getAllCountries } from "../../../../requests/countries";
import { getStatesByCountry } from "../../../../requests/states";
import InputGroup from "../../../UI/Inputs/InputGroup";
import ToggleSwitch from "../../../UI/ToggleSwitch";
import { MapPinIcon } from "@heroicons/react/24/outline";
import Modal from "../../../UI/Modal";
import { getAllConferences } from "../../../../requests/conferences";
import { displayError } from "../../../../utils/displayError";
import { getAllTags } from "../../../../requests/tags";
import { checkPermission } from "../../../../utils/checkPermission";

export default function ContactInformationForm({ school }) {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const { watch } = useFormContext();
  const [churchStates, setChurchStates] = useState([]);
  const [schoolStates, setSchoolStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [tags, setTags] = useState();
  const selectedChurchCountry = watch("church_country", school?.church_country);
  const selectedSchoolCountry = watch("school_country", school?.school_country);

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching countries.");
    }
  };

  const getConferences = async () => {
    try {
      const res = await getAllConferences();

      if (res.data) {
        setConferences(res.data);
      }
    } catch (error) {
      displayError(error);
    }
  };

  const getStates = async (isSchool) => {
    try {
      const countryName = isSchool
        ? selectedSchoolCountry
        : selectedChurchCountry;
      const countryId = countries.find(
        (country) => country.name === countryName
      )?.id;

      const res = await getStatesByCountry({
        countryId: countryId || countries[0]?.id,
      });

      if (res.data) {
        if (isSchool) {
          setSchoolStates(res.data);
        } else {
          setChurchStates(res.data);
        }
      }
    } catch (e) {
      ToastNotification("error", "Error fetching states.");
    }
  };

  const getTags = async () => {
    try {
      const res = await getAllTags();

      if (res.data) {
        setTags(res.data.map((tag) => tag.name));
      }
    } catch (e) {
      ToastNotification("error", "Error fetching school tags.");
    }
  };

  const isAddressComplete = () => {
    const requiredFields = [
      "church_address",
      "church_city",
      "church_state",
      "church_country",
    ];
    return requiredFields.every((field) => Boolean(watch(field)));
  };

  const getGoogleMapsUrl = () => {
    // eslint-disable-next-line no-undef
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    // eslint-enable-next-line no-undef

    const address = [
      watch("church_address"),
      watch("church_address_2"),
      watch("church_city"),
      watch("church_state"),
      watch("church_country"),
      watch("church_zip_code"),
    ]
      .filter(Boolean)
      .join(", ");

    return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(address)}`;
  };

  const tooltipMessage =
    "Please fill in all required address fields to enable map view.";

  const setPattern = (country) => {
    let pattern = null;

    if (watch(country)) {
      if (watch(country) === "United States") {
        pattern = "\\d{5,5}(-\\d{4,4})?";
      } else {
        pattern = "(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]";
      }
    }

    return pattern;
  };

  const setPatternTitle = (country) => {
    let title = null;

    if (watch(country)) {
      if (watch(country) === "United States") {
        title = "12345 or 12345-1234";
      } else {
        title = "K1A 0B1";
      }
    }

    return title;
  };

  useEffect(() => {
    getCountries();
    getConferences();
    getTags();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      getStates(false);
    }
  }, [selectedChurchCountry, countries]);

  useEffect(() => {
    if (countries.length > 0) {
      getStates(true);
    }
  }, [selectedSchoolCountry, countries]);

  return (
    <div className="pl-10">
      <h2 className="mb-6 text-2xl">Church Contact Information</h2>

      {isGlobalAdmin && (
        <InputGroup>
          <ToggleSwitch
            id="active"
            label="Active"
            labelPosition="right"
            defaultChecked={school ? school.active : true}
          />
        </InputGroup>
      )}

      {isGlobalAdmin && tags && (
        <InputGroup classNames="items-center">
          <InputGroup>
            <MultiSelect
              id="tag_list"
              placeholder="Tags"
              options={tags}
              defaultSelected={school?.tag_list || []}
            />
          </InputGroup>
        </InputGroup>
      )}

      <div className="my-4">
        <h3 className="font-bold mb-3">CHURCH</h3>
        <div>
          {conferences.length > 0 && (
            <InputGroup col>
              <Select
                id="conference_code"
                defaultValue={school?.conference_code}
                readOnly={!isGlobalAdmin}
              >
                <SelectItem value="">Select a Conference</SelectItem>
                {conferences.map((conference) => (
                  <SelectItem
                    key={conference.id}
                    value={conference.conference_code}
                  >
                    {conference.name}
                  </SelectItem>
                ))}
              </Select>
            </InputGroup>
          )}

          <InputGroup col>
            <Input
              id="church_name"
              placeholder="Church Name"
              label="Church Name"
              defaultValue={school?.church_name}
              isRequired
            />
          </InputGroup>

          <InputGroup>
            <div className="w-full">
              {countries.length > 0 && (
                <Select
                  id="church_country"
                  defaultValue={
                    school ? school.church_country : "United States"
                  }
                  isRequired
                >
                  <SelectItem value="" disabled>
                    Select a country
                  </SelectItem>
                  {countries.map((country) => (
                    <SelectItem key={country.id} value={country.name}>
                      {country.name}
                    </SelectItem>
                  ))}
                </Select>
              )}
            </div>
          </InputGroup>
          <InputGroup col>
            <div className="flex ml-[-40px]">
              <button
                type="button"
                className={`mr-2 focus:outline-none ${
                  isAddressComplete()
                    ? "text-gray-500 hover:text-gray-700"
                    : "text-gray-300 cursor-not-allowed"
                }`}
                onClick={() => setMapModalOpen(true)}
                disabled={!isAddressComplete()}
                title={!isAddressComplete() ? tooltipMessage : "Open map view"}
              >
                <MapPinIcon className="h-8 w-8" />
              </button>
              <Modal open={mapModalOpen} setOpen={setMapModalOpen} closeButton>
                <iframe
                  width="800"
                  height="500"
                  style={{ border: 0 }}
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={getGoogleMapsUrl()}
                />
                <div className="my-4 flex justify-center">
                  <button
                    onClick={() => setMapModalOpen(false)}
                    className="flex items-center text-secondary hover:text-secondary-600 focus:outline-none transition duration-150 ease-in-out"
                  >
                    <MapPinIcon className="h-5 w-5 mr-2" />
                    Update Location
                  </button>
                </div>
              </Modal>
              <Input
                id="church_address"
                placeholder="Address 1"
                defaultValue={school?.church_address}
                isRequired
              />
            </div>
          </InputGroup>

          <InputGroup col>
            <Input
              id="church_address_2"
              placeholder="Address 2"
              defaultValue={school?.church_address_2}
            />
          </InputGroup>

          <InputGroup>
            <Input
              id="church_city"
              placeholder="City"
              defaultValue={school?.church_city}
              isRequired
            />
            {churchStates.length > 0 && (
              <Select
                id="church_state"
                label="State"
                defaultValue={school?.church_state}
                isRequired
              >
                <SelectItem value="" disabled>
                  {watch("church_country")
                    ? "Select a state"
                    : "Select a country"}
                </SelectItem>
                {watch("church_country") &&
                  churchStates.map((state) => (
                    <SelectItem key={state.id} value={state.abbreviation}>
                      {state.name}
                    </SelectItem>
                  ))}
              </Select>
            )}
            <Input
              id="church_zip_code"
              placeholder="Zip/Postal"
              defaultValue={school?.church_zip_code}
              isRequired
              pattern={setPattern("church_country")}
              title={setPatternTitle("church_country")}
            />
          </InputGroup>

          <InputGroup col>
            <Input
              id="church_contact"
              placeholder="Church Contact"
              label="Church Contact"
              defaultValue={school?.church_contact}
            />
          </InputGroup>

          <InputGroup>
            <Input
              id="church_phone"
              placeholder="Phone"
              label="Phone"
              defaultValue={school?.church_phone}
            />
            <Input
              id="church_fax"
              placeholder="Fax"
              label="Fax"
              defaultValue={school?.church_fax}
            />
          </InputGroup>

          <InputGroup col>
            <Input
              id="church_email"
              placeholder="E-mail Address"
              label="E-mail Address"
              defaultValue={school?.church_email}
            />
          </InputGroup>
        </div>
      </div>

      <div className="mt-12">
        <h3 className="font-bold mb-3">SCHOOL</h3>

        <InputGroup col>
          <Input
            id="school_name"
            placeholder="School Name"
            defaultValue={school?.school_name}
            isRequired
          />
        </InputGroup>

        <InputGroup col>
          <Input
            id="school_address"
            placeholder="Address 1"
            defaultValue={school?.school_address}
            isRequired
          />
        </InputGroup>

        <InputGroup col>
          <Input
            id="school_address_2"
            placeholder="Address 2"
            defaultValue={school?.school_address_2}
          />
        </InputGroup>

        <InputGroup>
          <div className="w-full">
            {countries.length > 0 && (
              <Select
                id="school_country"
                defaultValue={school ? school.school_country : "United States"}
                isRequired
              >
                <SelectItem value="" disabled>
                  Select a country
                </SelectItem>
                {countries.map((country) => (
                  <SelectItem key={country.id} value={country.name}>
                    {country.name}
                  </SelectItem>
                ))}
              </Select>
            )}
          </div>
        </InputGroup>

        <InputGroup>
          <Input
            id="school_city"
            placeholder="City"
            defaultValue={school?.school_city}
            isRequired
          />
          {schoolStates.length > 0 && (
            <Select
              id="school_state"
              label="State"
              defaultValue={school?.school_state}
              isRequired
            >
              <SelectItem value="" disabled>
                {watch("school_country")
                  ? "Select a state"
                  : "Select a country"}
              </SelectItem>
              {watch("school_country") &&
                schoolStates.map((state) => (
                  <SelectItem key={state.id} value={state.abbreviation}>
                    {state.name}
                  </SelectItem>
                ))}
            </Select>
          )}
          <Input
            id="school_zip_code"
            placeholder="Zip/Postal"
            defaultValue={school?.school_zip_code}
            isRequired
            pattern={setPattern("school_country")}
            title={setPatternTitle("school_country")}
          />
        </InputGroup>
      </div>
    </div>
  );
}
