import React from "react";
import { twMerge } from "tailwind-merge";

export default function TableCell({ children, classNames, colSpan }) {
  return (
    <td className={twMerge("border-b py-3", classNames)} colSpan={colSpan}>
      {children}
    </td>
  );
}
