import React from "react";
import InputGroup from "../../../../../UI/Inputs/InputGroup";
import Input from "../../../../../UI/Inputs/Input";
import TextArea from "../../../../../UI/Inputs/TextArea";
import ToggleSwitch from "../../../../../UI/ToggleSwitch";

export default function SeminarForm({ enrollment }) {
  return (
    <>
      <InputGroup classNames="justify-end">
        <ToggleSwitch
          id="active"
          label="Active Enrollment"
          labelPosition="left"
          defaultChecked={enrollment?.active}
        />
      </InputGroup>
      <InputGroup>
        <Input
          id="referral"
          placeholder="Referral Name"
          label="Referral"
          defaultValue={enrollment?.referral}
        />
        <Input
          id="registration_number"
          placeholder="Registration No."
          label="Registration No."
          defaultValue={enrollment?.registration_number}
        />
        <Input
          id="interest_card"
          placeholder="Work in progress"
          label="Interest card scan"
          disabled
        />
      </InputGroup>
      <InputGroup>
        <TextArea
          id="notes"
          label="Student Enrollment Notes"
          defaultValue={enrollment?.notes}
        />
      </InputGroup>
    </>
  );
}
