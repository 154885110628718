import React, { useEffect, useState } from "react";
import ActionMenuContainer from "../../../ActionMenu/ActionMenuContainer";
import { FormProvider, useForm } from "react-hook-form";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import Checkbox from "../../../UI/Inputs/Checkbox";
import { createStudentRequest } from "../../../../requests/students";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getAllSchoolInstructors } from "../../../../requests/schools";
import { checkPermission } from "../../../../utils/checkPermission";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { useNavigate, useParams } from "react-router-dom";
import { displayError } from "../../../../utils/displayError";
import { createEnrollmentRequest } from "../../../../requests/enrollments";

export default function NewStudent({
  seminar,
  closeModal,
  reloadStudents,
  takingAttendance,
  user,
  registrationNumber,
  presentation,
}) {
  const methods = useForm();
  const [instructors, setInstructors] = useState([]);
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const params = useParams();
  const seminarId = params.seminarId;
  const navigate = useNavigate();

  const getInstructors = async () => {
    try {
      const res = await getAllSchoolInstructors({
        school_id: seminar.host_school?.id,
        only_instructors: true,
      });

      if (res.data) {
        setInstructors(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getInstructors();
    }
  }, []);

  const onSubmit = async (data) => {
    const action = window.event.submitter.textContent;
    const schoolId = seminar.host_school?.id;

    try {
      const res = await createStudentRequest({
        ...data,
        school_id: schoolId,
        series_id: seminarId,
      });

      if (res.data) {
        let enrollmentRequestData = {
          student_id: res.data.id,
          series_id: seminarId,
          school_id: schoolId,
          registration_number: data.registration_number,
        };

        if (data.presentation) {
          enrollmentRequestData.episode_deliveries_attributes = [
            {
              date_completed: new Date(),
              episode_id: data.presentation,
              grading_instructor_id: user.id,
              delivery_type: "Personal Delivery",
            },
          ];
        }

        await createEnrollmentRequest(enrollmentRequestData);

        ToastNotification(
          "success",
          "Student created and enrolled successfully."
        );

        if (action === "Add & New Student") {
          methods.reset();
        } else if (action === "Add & View Student") {
          navigate(`/students/${res.data.id}/overview`);
        } else {
          closeModal();
        }

        reloadStudents();
      }
    } catch (e) {
      displayError(e);
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="md:flex-row md:mb-6 flex justify-between flex-col">
            <div className="md:w-2/6 md:mr-8">
              <Input
                id="registration_number"
                label="Registration Number"
                defaultValue={registrationNumber}
                dark
              />
            </div>
            <div className="md:w-4/6 flex items-center">
              <Input
                id="interest_card"
                type="file"
                inputClassNames="border-none"
                label="Interest Card Scan:"
              />
            </div>
          </div>

          <div className="md:flex-row md:mb-6 flex justify-between flex-col">
            <div className="md:w-2/4 md:mr-4">
              <Input id="first_name" label="First Name" dark isRequired />
            </div>
            <div className="md:w-2/4 md:mr-4">
              <Input id="middle_name" label="Middle Name" dark />
            </div>
            <div className="md:w-2/4">
              <Input id="last_name" label="Last Name" dark isRequired />
            </div>
          </div>

          <div className="md:flex-row flex justify-between flex-col">
            <div className="md:w-2/4 md:mr-4">
              <InputGroup>
                <Select
                  isRequired
                  classNames="w-full bg-transparent"
                  label="Instructor"
                  disabled={!isAdmin}
                  id="instructor_id"
                  defaultValue={seminar?.host_school?.head_school_admin_id}
                >
                  <SelectItem value="" disabled>
                    Select an instructor
                  </SelectItem>
                  {instructors.map((instructor) => (
                    <SelectItem
                      key={instructor.id}
                      value={instructor.id}
                      selected={
                        seminar?.host_school?.head_school_admin_id ===
                        instructor.id
                      }
                    >
                      {instructor.first_name} {instructor.last_name}
                    </SelectItem>
                  ))}
                </Select>
              </InputGroup>
            </div>
            <div className="md:w-2/4 md:mr-4">
              <Input
                id="school_id"
                label="School"
                dark
                disabled
                defaultValue={seminar?.host_school?.school_name}
              />
            </div>
            <div className="md:w-2/4">
              <Select
                id="share_with"
                classNames="w-full bg-transparent"
                label="Share with"
                defaultValue=""
              >
                <SelectItem value="" disabled>
                  Select share with
                </SelectItem>
              </Select>
            </div>
          </div>

          <div className="md:w-2/4 md:mb-6">
            <Select
              id="presentation"
              classNames="w-full bg-transparent"
              label="Presentation"
              defaultValue={presentation?.id}
            >
              <SelectItem value="" selected>
                No attendance
              </SelectItem>
              {seminar?.presentations.map((presentation) => (
                <SelectItem key={presentation.id} value={presentation.id}>
                  {presentation.name}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="mb-6">
            <Checkbox
              id="notify_instructor"
              label="Notify the instructor about their new student"
              labelColor="#ebebeb"
              labelFont="font-small"
            />
          </div>

          <div className="md:flex-row flex justify-center">
            <div className="text-center md:mr-4">
              <Button type="submit" variant="primary">
                Add Student
              </Button>
            </div>
            <div className="text-center md:mr-4">
              <Button type="submit" variant="primary">
                Add & New Student
              </Button>
            </div>
            <div className="text-center md:mr-4">
              <Button type="submit" variant="primary">
                Add & View Student
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    );
  };

  return (
    <div className="bg-neutral-600 relative">
      {takingAttendance ? (
        renderForm()
      ) : (
        <ActionMenuContainer label="Add New Student" clickToClose={closeModal}>
          {renderForm()}
        </ActionMenuContainer>
      )}
    </div>
  );
}
