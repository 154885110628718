import React from "react";
import SearchAsync from "./SearchAsync";
import { getAllUsers } from "../../requests/users";

export default function SearchUser({ userType, field }) {
  const loadOptions = async (inputValue) => {
    try {
      const response = await getAllUsers({
        params: {
          type: userType,
          q: {
            first_name_or_last_name_or_email_cont: inputValue,
            s: "first_name asc",
          },
        },
      });

      // Group users by search_group_name
      const groupedUsers = response.data.users.reduce((acc, user) => {
        const group = user.search_group_name || "No School";
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push({
          label: `${user.first_name} ${user.last_name} (${user.email})`,
          value: user.id,
        });
        return acc;
      }, {});

      // Transform grouped users into the required format
      const options = Object.entries(groupedUsers).map(([group, users]) => ({
        label: group,
        options: users,
      }));

      return options;
    } catch (error) {
      return [];
    }
  };

  return (
    <SearchAsync
      {...field}
      className="w-full"
      placeholder={`Search ${userType}`}
      loadOptions={loadOptions}
    />
  );
}
