import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const Print = ({
  header,
  empty = false,
  children,
  handleAfterPrint = () => {},
}) => {
  useEffect(() => {
    document.body.classList.add("has-printable-component");
    return () => document.body.classList.remove("has-printable-component");
  }, []);

  useEffect(() => {
    window.addEventListener("afterprint", handleAfterPrint);
    return () => window.removeEventListener("afterprint", handleAfterPrint);
  }, [handleAfterPrint]);

  const printContent = (
    <div className="print-only">
      {empty ? (
        <p>
          No printable records available.
          <br />
          Please use the "Print Records" button on page actions to render an
          updated list.
        </p>
      ) : (
        <table>
          <thead>
            <tr>
              <th className="header-title">{header}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{children}</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );

  return ReactDOM.createPortal(printContent, document.body);
};

export default Print;
