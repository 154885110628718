import React, { useEffect, useState } from "react";
import {
  getSchoolContact,
  downloadCsvSchoolContactReport,
} from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Pagination } from "../UI/Pagination";
import Pill from "../UI/Pill";
import { twMerge } from "tailwind-merge";
import { DownloadCSVIcon, PrintIcon } from "../UI/PageActions/Icons";
import { ToastNotification } from "../UI/ToastNotification";
import { usePageActions } from "../../contexts/PageActionsContext";
import SchoolContactReportPrint from "./Print/SchoolContactReportPrint";

export default function SchoolContactReport() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();
  const { setPageActions } = usePageActions();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printingReports, setPrintingReports] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      active: searchParams.get("status"),
      conference_code: searchParams.get("conference_code"),
      discovery_center: searchParams.get("discovery_center"),
      pagination: print ? false : undefined,
      page: csv || print ? undefined : page,
      per_page: csv || print ? undefined : pagination.recordsPerPage,
    };

    return queryParams;
  };

  const getReport = async (callback) => {
    setLoading(true);

    try {
      const res = await getSchoolContact({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data);
        } else {
          setReports(res.data);
          pagination.setData(res.data.pagination);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvSchoolContactReport({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    getReport();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || reports?.schools?.length < 1,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || reports?.schools?.length < 1,
        loading: isPrinting,
        onClick: () => {
          setIsPrinting(true);

          getReport((reports) => {
            setPrintingReports(reports);
            setIsPrinting(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    searchParams,
    reports,
    loading,
    isPrinting,
    downloadingCsv,
  ]);

  useEffect(() => {
    if (printingReports?.schools?.length > 0) window.print();

    return () => {
      if (printingReports?.schools?.length > 0) setPrintingReports([]);
    };
  }, [printingReports]);

  return (
    <>
      <div className="flex justify-between my-10">
        <p>Schools with City, State, Contact Info</p>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports.length < 1}
        empty={reports.length < 1}
      >
        {reports.schools?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>NAME</TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>ADDRES 2</TableHeader>
                <TableHeader>CITY</TableHeader>
                <TableHeader>STATE</TableHeader>
                <TableHeader>ZIP/POSTAL CODE</TableHeader>
                <TableHeader>COUNTRY</TableHeader>
                <TableHeader>PHONE</TableHeader>
                <TableHeader>E-MAIL ADDRESS</TableHeader>
                <TableHeader>HEAD SCHOOL ADMIN</TableHeader>
                <TableHeader>ACTIVE</TableHeader>
                <TableHeader>SCHOOL NUMBER</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports.schools.map((school) => {
                const isActive = school.active;
                return (
                  <tr
                    className={twMerge(!isActive && "bg-[#FDD]")}
                    key={school.id}
                  >
                    <TableCell>
                      <p>{school.school_name}</p>
                      <p>{school.church_name}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_address}</p>
                      <p>{school.church_address}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_address_2}</p>
                      <p>{school.church_address_2}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_city}</p>
                      <p>{school.church_city}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_state}</p>
                      <p>{school.church_state}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_zip_code}</p>
                      <p>{school.church_zip_code}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_country}</p>
                      <p>{school.church_country}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_phone}</p>
                      <p>{school.church_phone}</p>
                    </TableCell>
                    <TableCell>
                      <p>{school.school_email}</p>
                      <p>{school.church_email}</p>
                    </TableCell>
                    <TableCell>
                      {school.head_school_admin?.first_name}
                    </TableCell>
                    <TableCell>
                      {isActive ? (
                        <Pill color="success">Active</Pill>
                      ) : (
                        <Pill>Inactive</Pill>
                      )}
                    </TableCell>
                    <TableCell>{school.school_code}</TableCell>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No schools recorded." />
        )}
      </Pagination>

      <SchoolContactReportPrint reports={printingReports} />
    </>
  );
}
