import React from "react";
import { Link } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

const ClickableHeader = ({ title, to }) => (
  <Link
    to={to}
    className="group flex items-center justify-between mb-2 h-8 mx-[-.75rem] mt-[-1rem]"
  >
    <span className="text-lg font-bold text-primary uppercase group-hover:text-primary-600 transition-colors animation-200">
      {title}
    </span>

    <span className="flex items-center gap-2">
      <span className="text-transparent group-hover:text-primary-600 transition-colors animation-200">
        View All
      </span>
      <ArrowTopRightOnSquareIcon className="h-[32px] w-[32px] text-[#C4C4C4] group-hover:text-primary-600 transition-colors animation-200" />
    </span>
  </Link>
);

export default function CardHeader({ title, to }) {
  if (to) return <ClickableHeader title={title} to={to} />;

  return (
    <div className="flex items-center justify-between mb-2 h-8 mx-[-.75rem] mt-[-1rem]">
      <span className="text-lg font-bold text-primary uppercase">{title}</span>
    </div>
  );
}
