import React from "react";
import dayjs from "dayjs";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";
import { useNavigate } from "react-router-dom";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";

export default function PrintedCourses({ student }) {
  const navigate = useNavigate();

  return (
    <>
      <CardHeader title="Printed Courses" />

      {student.course_enrollments.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>PROGRESS</TableHeader>
              <TableHeader>DECISIONS</TableHeader>
              <TableHeader>STATUS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {student.course_enrollments.map((enrollment) => (
              <tr
                key={enrollment.id}
                className="cursor-pointer"
                onClick={() =>
                  navigate(
                    `/students/${student.id}/courses/enrollments/${enrollment.id}/lessons`
                  )
                }
              >
                <TableCell>{enrollment.series_name}</TableCell>
                <TableCell>
                  {enrollment.progress}
                  {enrollment && (
                    <>
                      {" - "}
                      {enrollment.last_completed_delivery
                        ? dayjs(
                            enrollment.last_completed_delivery?.date_completed
                          ).format("MMM D, YYYY")
                        : "No lessons"}
                    </>
                  )}
                </TableCell>
                <TableCell>{enrollment.decisions_count}</TableCell>
                <TableCell>
                  {returnEnrollmentStatus(enrollment.series_id, enrollment)}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No printed courses for this student." />
      )}
    </>
  );
}
