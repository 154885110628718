import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";

export default function Select({
  id,
  isRequired,
  children,
  classNames,
  label,
  dark,
  defaultValue,
  disabled,
  onChange,
  index,
  wrapperClassName,
  readOnly,
  placeholder,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(id, defaultValue);
  }, [defaultValue]);

  return (
    <div className={wrapperClassName}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="select-wrapper relative">
        <select
          id={id}
          disabled={disabled || readOnly}
          defaultValue={defaultValue}
          className={twMerge(
            "w-full h-[38px] rounded",
            classNames,
            dark && "bg-transparent",
            errors[id] && "border-red-500"
          )}
          {...register(id, {
            onChange: onChange,
            required: isRequired ? "This field is required" : false,
          })}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {children}
        </select>
      </div>

      {index >= 0 ? (
        <>
          {errors?.[id.split(".")[0]]?.[id.split(".")[1]]?.[
            id.split(".")[2]
          ] && (
            <p className="relative text-red-500 text-sm">
              {
                errors?.[id.split(".")[0]]?.[id.split(".")[1]]?.[
                  id.split(".")[2]
                ]?.message
              }
            </p>
          )}
        </>
      ) : (
        <>
          {errors[id] && (
            <p className="absolute text-red-500">{errors[id]?.message}</p>
          )}
        </>
      )}
    </div>
  );
}
