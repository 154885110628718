import React, { useEffect, useState } from "react";
import ActionMenuContainer from "../../../ActionMenu/ActionMenuContainer";
import {
  assignStudentRequest,
  getSeminarAttendancesRequest,
} from "../../../../requests/seminars";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getAllParticipatingSchoolsInstructors } from "../../../../requests/seminars";
import { checkPermission } from "../../../../utils/checkPermission";
import { displayError } from "../../../../utils/displayError";
import ListButtonGroup from "../../../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../../../UI/ListButtonGroup/ListButtonGroupItem";
import StudentView from "./StudentView";
import ListView from "./ListView";
import { useForm } from "react-hook-form";

export default function AssignStudents({
  closeModal,
  seminar,
  assignStudents,
  recentlyAssigned,
  setRecentlyAssigned,
  getRecentlyAssigned,
}) {
  const methods = useForm();
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const [participatingSchoolsInstructors, setParticipatingSchoolsInstructors] =
    useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedStudentIndex, setSelectedStudentIndex] = useState(0);
  const [attendances, setAttendances] = useState([]);
  const [selectedViewMode, setSelectedViewMode] = useState("Student View");
  const [remainingStudents, setRemainingStudents] = useState(assignStudents);

  const viewMode = [
    {
      label: "Student View",
    },
    {
      label: "List View",
    },
  ];

  const getParticipatingSchoolsInstructors = async () => {
    const res = await getAllParticipatingSchoolsInstructors({
      seminarId: seminar.id,
    });
    if (res.data) {
      setParticipatingSchoolsInstructors(res.data);
    }
  };

  const getAttendances = async () => {
    try {
      const res = await getSeminarAttendancesRequest({
        seminarId: seminar.id,
        studentId: selectedStudent.id,
      });

      if (res.data) {
        setAttendances(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    setSelectedStudent(remainingStudents[selectedStudentIndex]?.student);
  }, [remainingStudents]);

  useEffect(() => {
    if (selectedStudent) {
      getAttendances();
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (isAdmin) {
      getParticipatingSchoolsInstructors();
    }
  }, []);

  useEffect(() => {
    if (remainingStudents.length === 0) {
      closeModal();
    }
  }, [remainingStudents]);

  const onSubmit = async (data) => {
    try {
      const res = await assignStudentRequest({
        instructor_id: data.instructor_id?.split("#")[0],
        student_id: selectedStudent.id,
        school_id: data.instructor_id?.split("#")[1],
        seminar_id: seminar.id,
      });

      if (res.data) {
        ToastNotification("success", "Student assigned successfully.");
        // Remove the assigned student from remainingStudents
        setRemainingStudents((prevStudents) =>
          prevStudents.filter(
            (student) => student.student.id !== selectedStudent.id
          )
        );
        // Reset the selectedStudentIndex if necessary
        setSelectedStudentIndex((prevIndex) =>
          prevIndex >= remainingStudents.length - 1
            ? Math.max(0, remainingStudents.length - 2)
            : prevIndex
        );
        getRecentlyAssigned();
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleNext = async () => {
    if (selectedStudentIndex < remainingStudents.length - 1) {
      setSelectedStudentIndex(selectedStudentIndex + 1);
      setSelectedStudent(remainingStudents[selectedStudentIndex + 1]?.student);
    }
    methods.setValue("instructor_id", "");
  };

  const handlePrevious = async () => {
    if (selectedStudentIndex > 0) {
      setSelectedStudentIndex(selectedStudentIndex - 1);
      setSelectedStudent(remainingStudents[selectedStudentIndex - 1]?.student);
    }
    methods.setValue("instructor_id", "");
  };

  return (
    <div className="relative">
      <ActionMenuContainer clickToClose={closeModal} light>
        <div className="h-[40px] flex items-center mb-8">
          <h1 className="text-[18px] uppercase font-bold text-primary">{`Assign students (${remainingStudents.length})`}</h1>

          <ListButtonGroup>
            {viewMode.map((item, index) => (
              <ListButtonGroupItem
                key={item.id}
                label={item.label}
                isFirst={index === 0}
                isLast={index === viewMode.length - 1}
                selected={selectedViewMode}
                setSelected={setSelectedViewMode}
              />
            ))}
          </ListButtonGroup>
        </div>

        {selectedViewMode === "Student View" ? (
          <StudentView
            onSubmit={onSubmit}
            seminar={seminar}
            participatingSchoolsInstructors={participatingSchoolsInstructors}
            attendances={attendances}
            selectedStudent={selectedStudent}
            methods={methods}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            selectedViewMode={selectedViewMode}
            selectedStudentIndex={selectedStudentIndex}
            totalStudents={remainingStudents.length}
            currentStudentNumber={selectedStudentIndex + 1}
          />
        ) : (
          <ListView
            seminar={seminar}
            recentlyAssigned={recentlyAssigned}
            setRecentlyAssigned={setRecentlyAssigned}
          />
        )}
      </ActionMenuContainer>
    </div>
  );
}
