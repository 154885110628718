import React from "react";
import Card from "../../../UI/Card";
import OnlineCourses from "./OnlineCourses";
import PrintedCourses from "./PrintedCourses";
import Seminars from "./Seminars";
import Decisions from "./Decisions";
import Notes from "./Notes";
import Products from "./Products";
import StudentMobileOverview from "./Mobile/StudentMobileOverview";
import { useRouteLoaderData } from "react-router-dom";
import { useMediaQuery } from "../../../../utils/useMediaQuery";

export default function StudentOverview() {
  const student = useRouteLoaderData("student").data;
  const isMobile = useMediaQuery("md", "down");

  return isMobile ? (
    <StudentMobileOverview />
  ) : (
    <>
      <Card>
        <PrintedCourses student={student} />
      </Card>

      <div className="mt-4 flex">
        <Card classNames="mr-4 w-3/6 min-h-[260px]">
          <Seminars student={student} />
        </Card>
        <Card classNames="w-3/6 min-h-[260px]">
          <OnlineCourses student={student} />
        </Card>
      </div>

      <div className="mt-4 flex">
        <div className="mr-4 w-3/6">
          <Decisions student={student} />
        </div>
        <div className="w-3/6">
          <Notes student={student} />
        </div>
      </div>

      <div className="mt-4 flex">
        <div className="mr-4 w-3/6">
          <Products student={student} />
        </div>
        <div className="w-3/6"></div>
      </div>
    </>
  );
}
