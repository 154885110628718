import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import TableCell from "../../../UI/Table/TableCell";
import {
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import Question from "./Questions/Question";
import {
  getQuestionsByLessonRequest,
  updateLessonRequest,
} from "../../../../requests/lessons";
import QuestionNew from "./Questions/QuestionNew";
import CourseLessonForm from "./CourseLessonForm";
import { ToastNotification } from "../../../UI/ToastNotification";
import { hoverLink } from "../../../../utils/hoverLink";

export default function CourseLessonRow({
  lesson,
  handleDeleteModal,
  formOpen,
  setFormOpen,
}) {
  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);
  const [questionFormOpen, setQuestionFormOpen] = useState({
    open: false,
    action: null,
  });
  const [questions, setQuestions] = useState([]);

  const [editing, setEditing] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await updateLessonRequest({
        ...data,
        lessonId: lesson.id,
      });

      if (res.data) {
        ToastNotification("success", "Lesson updated successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const getQuestions = async () => {
    const res = await getQuestionsByLessonRequest({ lessonId: lesson.id });
    if (res.data) {
      setQuestions(res.data);
    }
  };

  const handleOpenForm = () => {
    setFormOpen({ open: true, action: "edit" });
    setEditing(true);
  };

  useEffect(() => {
    if (!formOpen.open && formOpen.action === null) {
      setEditing(false);
    }
  }, [formOpen]);

  useEffect(() => {
    getQuestions();
  }, [questionFormOpen, deleteQuestionModalOpen]);

  return (
    <tr>
      <TableCell>
        {editing ? (
          <CourseLessonForm
            onSubmit={onSubmit}
            lesson={lesson}
            setOpen={setFormOpen}
          />
        ) : (
          <p onClick={() => handleOpenForm()} className={hoverLink}>
            {lesson.name}
          </p>
        )}
      </TableCell>
      <TableCell>{lesson.date}</TableCell>
      <TableCell>
        {questions.map((question) => (
          <Question
            key={question.id}
            question={question}
            deleteQuestionModalOpen={deleteQuestionModalOpen}
            setDeleteQuestionModalOpen={setDeleteQuestionModalOpen}
            questionFormOpen={questionFormOpen}
            setQuestionFormOpen={setQuestionFormOpen}
          />
        ))}

        {questionFormOpen.action === "new" && (
          <QuestionNew
            setQuestionFormOpen={setQuestionFormOpen}
            lessonId={lesson.id}
          />
        )}
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Menu as="div">
          <Menu.Button className="hover:bg-white/25 bg-transparent rounded rounded-full w-6">
            <EllipsisVerticalIcon className="w-6 cursor-pointer" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-10 mr-4 z-10 p-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item
                onClick={() =>
                  setQuestionFormOpen({ open: true, action: "new" })
                }
                className="hover:bg-secondary-600 hover:text-white rounded p-2 cursor-pointer"
              >
                <p className="flex">
                  <PlusIcon className="w-5 mr-2" />
                  <span>Add Question</span>
                </p>
              </Menu.Item>
              <Menu.Item
                onClick={(e) => handleDeleteModal(e, lesson.id)}
                className="hover:bg-secondary-600 hover:text-white p-2 cursor-pointer"
              >
                <p className="flex">
                  <TrashIcon className="w-5 mr-2" />
                  <span>Delete</span>
                </p>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </TableCell>
    </tr>
  );
}
