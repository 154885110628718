import React from "react";
import Print from "../../UI/Print";
import { fullName } from "../../../utils/fullName";
import { addressMultiLine } from "../../../utils/fullAddress";

export default function MasterTeamReportPrint({
  reports,
  seminar,
  handleAfterPrint,
}) {
  return (
    <Print
      header={`MASTER TEAM REPORT FOR ${seminar?.name}`}
      empty={reports?.enrollments?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>NAME/PHONE</th>
            <th>ADDRESS</th>
            <th>LATEST FOLLOW-UP DATE</th>
            <th>LATEST FOLLOW-UP ACTIVITY</th>
          </tr>
        </thead>
        <tbody>
          {reports?.enrollments?.map((enrollment) => (
            <tr key={enrollment.id}>
              <td>
                {fullName(
                  enrollment.student.first_name,
                  "",
                  enrollment.student.last_name
                )}
                <br />
                {enrollment.student.phone}
              </td>
              <td className="whitespace-nowrap">
                {addressMultiLine({
                  address: enrollment.student.address,
                  address_2: enrollment.student.address_2,
                  city: enrollment.student.city,
                  state: enrollment.student.state,
                  zip_code: enrollment.student.zip_code,
                  country: enrollment.student.country,
                })}
              </td>
              <td>{enrollment?.latest_followup_activity?.created_at || "-"}</td>
              <td>
                {enrollment?.latest_followup_activity?.activity ||
                  "(No followup activity)"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
