import React, { useEffect, useState } from "react";
import {
  getAttendanceReport,
  downloadCsvAttendanceReport,
} from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { fullName } from "../../utils/fullName";
import { Pagination } from "../UI/Pagination";
import { renderPresentations } from "../../utils/reports/reportAttendanceChecks";
import { highlightText } from "../../utils/highlightText";
import StudentsFilter from "../Students/StudentsFilter";
import { useFilterValues } from "../UI/Filter/Filter";
import { usePageActions } from "../../contexts/PageActionsContext";
import QuickFind from "../UI/QuickFind";
import { DownloadCSVIcon, PrintIcon } from "../UI/PageActions/Icons";
import { ToastNotification } from "../UI/ToastNotification";
import AttendanceReportPrint from "./Print/AttendanceReportPrint";

export default function AttendanceReport({ seminar }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const pagination = usePagination();
  const filterValues = useFilterValues();
  const { setPageActions } = usePageActions();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printingReports, setPrintingReports] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      seminar_id: searchParams.get("seminar_id"),
      pagination: print ? false : undefined,
      page: csv || print ? undefined : page,
      per_page: csv || print ? undefined : pagination.recordsPerPage,
      q: { ...filterValues },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      queryParams.q.full_name_i_cont_all = query.trim().split(" ");
    }

    return queryParams;
  };

  const getReport = async (callback) => {
    setLoading(true);

    try {
      const res = await getAttendanceReport({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data);
        } else {
          setReports(res.data);
          pagination.setData(res.data.pagination);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvAttendanceReport({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    getReport();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || reports?.enrollments?.length < 1,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || reports?.enrollments?.length < 1,
        loading: isPrinting,
        onClick: () => {
          setIsPrinting(true);

          getReport((reports) => {
            setPrintingReports(reports);
            setIsPrinting(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    searchParams,
    reports,
    loading,
    isPrinting,
    downloadingCsv,
  ]);

  useEffect(() => {
    if (printingReports?.enrollments?.length > 0) window.print();

    return () => {
      if (printingReports?.enrollments?.length > 0) setPrintingReports([]);
    };
  }, [printingReports]);

  return (
    <>
      <div className="flex justify-between my-10">
        <p>Attendance Report for {seminar?.name}</p>
        <div className="flex">
          <QuickFind
            onSubmit={(data) =>
              setSearchParams((searchParams) => {
                searchParams.set("query", data.search);
                return searchParams;
              })
            }
            value={query}
          />
          <div className="relative ml-7">
            <StudentsFilter />
          </div>
        </div>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports?.length < 1}
        empty={reports?.enrollments?.length < 1}
      >
        {reports?.enrollments?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>REG. NO.</TableHeader>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>ATTENDANCE</TableHeader>
                <TableHeader>INSTRUCTOR</TableHeader>
                <TableHeader>SCHOOL</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports?.enrollments.map((enrollment) => (
                <tr key={enrollment.id}>
                  <TableCell>{enrollment.registration_number || "-"}</TableCell>
                  <TableCell>
                    {highlightText(
                      fullName(
                        enrollment.student.first_name,
                        "",
                        enrollment.student.last_name
                      ),
                      query
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-wrap">
                      {reports?.presentations.length > 0 &&
                        renderPresentations(reports, enrollment)}
                    </div>
                  </TableCell>
                  <TableCell>{enrollment.student.instructor_name}</TableCell>
                  <TableCell>
                    {`(${enrollment.student.school?.school_code}) ${enrollment.student.school?.church_name}`}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No attendances recorded." />
        )}
      </Pagination>

      <AttendanceReportPrint reports={printingReports} />
    </>
  );
}
