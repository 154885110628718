import React from "react";
import NavGroup from "../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../UI/NavGroup/NavGroupItem";

export default function StudentNav({ student }) {
  const studentNavigation = [
    {
      label: "Overview",
      url: "overview",
    },
    {
      label: "Profile",
      url: "profile",
    },
    {
      label: "Courses",
      url: "courses",
    },
    {
      label: "Seminars",
      url: "seminars",
    },
  ];

  return (
    <NavGroup>
      {studentNavigation.map((item) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/students/${student?.id}/${item.url}`}
        />
      ))}
    </NavGroup>
  );
}
