import React from "react";

const ClipboardIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 1.75C9.41 1.75 9.75 2.09 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.09 8.59 1.75 9 1.75ZM16 18H2V4H16V18Z" />
      <path d="M12.08 10.0299L9.96 7.90991L4 13.8599V15.9999H6.1L12.08 10.0299Z" />
      <path d="M13.8499 8.27C14.0499 8.07 14.0499 7.76 13.8499 7.56L12.4399 6.15C12.2399 5.95 11.9299 5.95 11.7299 6.15L10.6699 7.21L12.7899 9.33L13.8499 8.27Z" />
    </svg>
  );
};

export default ClipboardIcon;
