import React, { useEffect, useState } from "react";
import ListButtonGroupItem from "../../../../../../UI/ListButtonGroup/ListButtonGroupItem";
import { FormProvider, useForm } from "react-hook-form";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";
import { Tooltip } from "../../../../../../UI/Tooltip";

export default function DeliveryType({
  lesson,
  enrollment,
  episodeDelivery,
  user,
}) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const deliveryTypes = [
    {
      label: "PO",
      value: "Post Office",
    },
    {
      label: "PD",
      value: "Personal Delivery",
    },
    {
      label: "IH",
      value: "In-home",
    },
  ];

  const defaultSelected = episodeDelivery
    ? deliveryTypes.find((item) => item.value === episodeDelivery.delivery_type)
        ?.label
    : "";

  const [selected, setSelected] = useState(defaultSelected);

  const onSubmit = async () => {
    setLoading(true);

    try {
      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            delivery_type:
              selected !== ""
                ? deliveryTypes.find((item) => item.label === selected)?.value
                : "",
            episode_id: lesson.id,
            grading_instructor_id: user.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
      }
    } catch (e) {
      ToastNotification("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (value) => {
    const newValue = selected === value ? "" : value;
    setSelected(newValue);
  };

  useEffect(() => {
    if (selected !== defaultSelected) {
      methods.handleSubmit(onSubmit)();
    }
  }, [selected]);

  return (
    <FormProvider {...methods}>
      <form>
        {deliveryTypes.map((item, index) => (
          <Tooltip content={item.value} key={item.label}>
            <ListButtonGroupItem
              label={item.label}
              isFirst={index === 0}
              isLast={index === deliveryTypes.length - 1}
              selected={selected}
              setSelected={handleSelected}
              disabled={loading}
            />
          </Tooltip>
        ))}
      </form>
    </FormProvider>
  );
}
