import React from "react";
import { twMerge } from "tailwind-merge";

export default function SeminarButton({ children, classNames, onClick }) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        "border rounded-full p-2 px-4 text-secondary hover:text-white hover:bg-secondary border-secondary",
        classNames
      )}
    >
      {children}
    </button>
  );
}
