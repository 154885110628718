import React from "react";
import { twMerge } from "tailwind-merge";

export default function ListButtonGroup({ children, className }) {
  return (
    <div
      className={twMerge(
        "lg:block absolute left-1/2 inline-flex -translate-x-1/2 rounded-full hidden",
        className
      )}
      role="group"
    >
      {children}
    </div>
  );
}
