import TableCell from "../UI/Table/TableCell";
import Input from "../UI/Inputs/Input";
import Select from "../UI/Inputs/Select/Select";
import SelectItem from "../UI/Inputs/Select/SelectItem";
import React, { useEffect, useState } from "react";
import {
  getAllSchoolCourses,
  getAllSchoolInstructors,
  getAllSchoolSeminars,
} from "../../requests/schools";
import { displayError } from "../../utils/displayError";
import { useFormContext } from "react-hook-form";
import Checkbox from "../UI/Inputs/Checkbox";
import DatePickerInput from "../UI/Inputs/DatePickerInput";
import SearchSchool from "../UI/SearchSchool";

export default function CsvStudentsRow({
  index,
  student,
  schools,
  methods,
  handleRemovedRows,
  countries,
  states,
  genders,
}) {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const denominations = ["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof"];
  const { watch } = useFormContext();
  const selectedCountry = watch(
    `csv_students.${index}.country`,
    student?.country
  );
  const [seminars, setSeminars] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isRowChecked, setIsRowChecked] = useState(true);
  const [filteredStates, setFilteredStates] = useState([]);

  const getInstructors = async () => {
    try {
      const res = await getAllSchoolInstructors({
        school_id: selectedSchool.id,
      });

      if (res.data) {
        setInstructors(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getSeminars = async () => {
    try {
      const res = await getAllSchoolSeminars({
        school_id: selectedSchool.id,
      });

      if (res.data) {
        setSeminars(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getCourses = async () => {
    const res = await getAllSchoolCourses({
      school_id: selectedSchool.id,
    });

    if (res.data) {
      setCourses(res.data);
    }
  };

  const filterStatesByCountry = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;

      setFilteredStates(
        states.filter((state) => state.country_id === countryId)
      );
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    methods.setValue(`csv_students.${index}.index`, index);
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      filterStatesByCountry();
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (selectedSchool) {
      getInstructors();
      getSeminars();
      getCourses();
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (schools.length > 0 && student.school_name) {
      handleSchoolChange(student.school_name);
    }
  }, [schools, student.school_name]);

  useEffect(() => {
    if ((seminars.length > 0 || courses.length > 0) && student.enrollments) {
      handleEnrollmentChange(student.enrollments);
    }
  }, [seminars, courses, student.enrollments]);

  useEffect(() => {
    if (instructors.length > 0 && student.instructor_name) {
      handleInstructorChange(student.instructor_name);
    }
  }, [instructors, student.instructor_name]);

  useEffect(() => {
    if (instructors.length > 0 && !student.instructor_name) {
      const instructor = instructors.find(
        (i) => i.id === selectedSchool.head_school_admin_id
      );
      if (instructor) {
        methods.setValue(
          `csv_students.${index}.instructor_name`,
          `${instructor.first_name} ${instructor.last_name}`
        );
        methods.setValue(`csv_students.${index}.instructor_id`, instructor.id);
        methods.trigger(`csv_students.${index}.instructor_name`);
      }
    }
  }, [instructors]);

  const handleSchoolChange = async (value) => {
    const school = schools.find((s) => s.school_name === value);
    if (school) {
      methods.setValue(`csv_students.${index}.school_id`, school.id);
      setSelectedSchool(school);
    } else {
      methods.setValue(`csv_students.${index}.school_name`, "");
    }
  };

  const handleEnrollmentChange = async (value) => {
    let enrollment = seminars.find((s) => s.name === value);
    if (!enrollment) {
      enrollment = courses.find((c) => c.name === value);
    }

    if (enrollment) {
      methods.setValue(`csv_students.${index}.series_id`, enrollment.id);
    } else {
      methods.setValue(`csv_students.${index}.enrollments`, "");
    }
  };

  const handleInstructorChange = async (value) => {
    let instructor = instructors.find(
      (i) => `${i.first_name} ${i.last_name}` === value
    );
    if (instructor) {
      methods.setValue(`csv_students.${index}.instructor_id`, instructor.id);
    } else {
      if (value) {
        methods.setValue(`csv_students.${index}.instructor_name`, "");
      }
    }
  };

  // handles the change of school name, since we use a different component for school search
  useEffect(() => {
    handleSchoolChange(watch(`csv_students.${index}.school_name`).school_name);
  }, [watch(`csv_students.${index}.school_name`)]);

  return (
    <tr key={index} className="mb-6" id={`row-${index}`}>
      <TableCell classNames="border-b-[#878787] pb-5 text-center">
        <Checkbox
          id={`checkbox-${index}`}
          defaultChecked={true}
          onClick={(event) => {
            setIsRowChecked(event.target.checked);
            handleRemovedRows(event, index);
          }}
        />
        <Input type="hidden" id={`csv_students.${index}.index`} />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Select
          id={`csv_students.${index}.title`}
          classNames="w-full bg-transparent text-white border-none"
          defaultValue={student.title}
        >
          <SelectItem value="" disabled>
            Title
          </SelectItem>
          {denominations.map((option) => (
            <SelectItem
              key={option}
              value={option}
              selected={student.title === option}
            >
              {option}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          isRequired={isRowChecked}
          id={`csv_students.${index}.first_name`}
          defaultValue={student.first_name}
          dark
          inputClassNames="text-white border-none"
          index={index}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.middle_name`}
          defaultValue={student.middle_name}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          isRequired={isRowChecked}
          id={`csv_students.${index}.last_name`}
          defaultValue={student.last_name}
          dark
          inputClassNames="text-white border-none"
          index={index}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input type="hidden" id={`csv_students.${index}.school_id`} />
        <SearchSchool
          id={`csv_students.${index}.school_name`}
          placeholder="Select a school"
          isRequired={isRowChecked}
          index={index}
          options={schools.map((school) => ({
            value: school.id,
            label: school.display_name,
            school_name: school.school_name,
          }))}
          borderless
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input type="hidden" id={`csv_students.${index}.instructor_id`} />
        <Select
          id={`csv_students.${index}.instructor_name`}
          classNames="w-full bg-transparent text-white border-none"
          isRequired={isRowChecked}
          defaultValue={student.instructor_name}
          onChange={(e) => handleInstructorChange(e.target.value)}
          index={index}
        >
          <SelectItem value="" disabled>
            Select an instructor
          </SelectItem>
          {instructors.map((instructor) => (
            <SelectItem
              key={instructor.id}
              value={`${instructor.first_name} ${instructor.last_name}`}
              selected={
                student.instructor_name ===
                `${instructor.first_name} ${instructor.last_name}`
              }
            >
              {instructor.first_name} {instructor.last_name}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Select
          id={`csv_students.${index}.enrollments`}
          classNames="w-full bg-transparent text-white border-none"
          defaultValue={student.enrollments}
          onChange={(e) => handleEnrollmentChange(e.target.value)}
        >
          <SelectItem value="" disabled>
            Select enrollments
          </SelectItem>
          <optgroup label="Seminars" key="seminars">
            {seminars.map((seminar) => (
              <SelectItem
                key={seminar.id}
                value={seminar.name}
                selected={student.enrollments === seminar.name}
              >
                {seminar.name}
              </SelectItem>
            ))}
          </optgroup>
          <optgroup label="Courses" key="courses">
            {courses.map((course) => (
              <SelectItem
                key={course.id}
                value={course.name}
                selected={student.enrollments === course.name}
              >
                {course.name}
              </SelectItem>
            ))}
          </optgroup>
        </Select>
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.referrals`}
          defaultValue={student.referrals}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.address`}
          defaultValue={student.address}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.address_2`}
          defaultValue={student.address_2}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.city`}
          defaultValue={student.city}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Select
          id={`csv_students.${index}.state`}
          classNames="w-full bg-transparent text-white border-none"
          defaultValue={
            states.find((s) => s.abbreviation === student?.state)?.name
          }
        >
          <SelectItem value="" disabled>
            {watch(`csv_students.${index}.country`)
              ? "State a state"
              : "Select a country"}
          </SelectItem>
          {watch(`csv_students.${index}.country`) &&
            filteredStates.map((state) => (
              <SelectItem
                key={state.id}
                value={state.abbreviation}
                selected={student.state === state.abbreviation}
              >
                {state.name}
              </SelectItem>
            ))}
        </Select>
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.zip_code`}
          defaultValue={student.zip_code}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Select
          id={`csv_students.${index}.country`}
          classNames="w-full bg-transparent text-white border-none"
          defaultValue={student?.country}
        >
          <SelectItem value="" disabled>
            Select a country
          </SelectItem>
          {countries.map((country) => (
            <SelectItem
              key={country.id}
              value={country.name}
              selected={student.country === country.name}
            >
              {country.name}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Select
          id={`csv_students.${index}.gender`}
          classNames="w-full bg-transparent text-white border-none"
          defaultValue={student?.gender}
        >
          <SelectItem value="" disabled>
            Select a gender
          </SelectItem>
          {genders.map((gender) => (
            <SelectItem
              key={gender.id}
              value={gender.name}
              selected={student?.gender === gender.name}
            >
              {gender.name}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.married`}
          defaultChecked={student?.married}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <DatePickerInput
          classNames="w-full bg-transparent text-white border-none"
          id={`csv_students.${index}.birthdate`}
          selected={student.birthdate}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.denomination`}
          defaultValue={student.denomination}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.baptized`}
          defaultChecked={student.baptized}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.phone`}
          defaultValue={student.phone}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.phone_is_sms`}
          defaultChecked={student.phone_is_sms}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.phone_2`}
          defaultValue={student.phone_2}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.phone_2_is_sms`}
          defaultChecked={student.phone_2_is_sms === "true"}
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.email`}
          defaultValue={student.email}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
      <TableCell classNames="border-b-[#878787] pb-5">
        <Input
          id={`csv_students.${index}.social_media`}
          defaultValue={student.social_media}
          dark
          inputClassNames="text-white border-none"
        />
      </TableCell>
    </tr>
  );
}
