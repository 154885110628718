import React, { useState, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Link } from "react-router-dom";
import { hoverLink } from "../../utils/hoverLink";

export default function VersionsTable({
  versions,
  loading,
  showDetails = false,
}) {
  const [details, setDetails] = useState({});

  const formattedCreatedAt = (createdAt) => {
    return new Intl.DateTimeFormat(undefined, {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date(createdAt));
  };

  const toggleDetails = (id) => {
    setDetails((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const detailsVisible = (versionId) => showDetails || details[versionId];

  useEffect(() => {
    setDetails({});
  }, [versions]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>WHEN</TableHeader>
          <TableHeader>ACTOR</TableHeader>
          <TableHeader>EVENT</TableHeader>
          <TableHeader>RECORD</TableHeader>
          <TableHeader></TableHeader>
        </tr>
      </thead>
      <tbody>
        {versions?.map((version) => (
          <React.Fragment key={version.id}>
            <tr
              className={twMerge([
                loading ? "opacity-25" : undefined,
                detailsVisible(version.id) ? "[&_td]:bg-white" : undefined,
              ])}
            >
              <TableCell classNames="whitespace-nowrap">
                <div className="text-sm text-secondary">
                  {version.created_at_distance}
                </div>

                <Link className={hoverLink} to={`/versions/${version.id}`}>
                  {formattedCreatedAt(version.created_at)}
                </Link>
              </TableCell>
              <TableCell>{version.actor_name}</TableCell>
              <TableCell>{version.event}</TableCell>
              <TableCell>
                <b>{version.item_type}</b>
                <br />
                {version.object_changes && (
                  <i className="text-xs">
                    {Object.keys(version.object_changes).join(", ")}
                  </i>
                )}
              </TableCell>
              <TableCell classNames="text-right">
                {version.object_changes && !showDetails && (
                  <button
                    onClick={() => toggleDetails(version.id)}
                    type="button"
                    className={
                      "bg-secondary hover:bg-secondary/80 text-white px-2 py-1 rounded-md transition-colors duration-200 whitespace-nowrap"
                    }
                  >
                    {detailsVisible(version.id) ? "Hide" : "Show"}{" "}
                    {Object.keys(version.object_changes).length} changes
                  </button>
                )}
              </TableCell>
            </tr>

            {detailsVisible(version.id) && (
              <tr className="[&_td]:bg-white">
                <TableCell colSpan={5}>
                  <table className="w-full [&_th]:text-left [&_td]:border-b">
                    <thead>
                      <tr>
                        <th></th>
                        <th>From</th>
                        <th className="w-[40px]">→</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(version.object_changes).map(
                        ([key, value]) => (
                          <tr key={key}>
                            <td>
                              <b>{key}</b>
                            </td>
                            <td>{JSON.stringify(value[0])}</td>
                            <td>→</td>
                            <td>{JSON.stringify(value[1])}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </TableCell>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
