import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../UI/Inputs/Input";
import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { getAllCountries } from "../../../../requests/countries";
import { ToastNotification } from "../../../UI/ToastNotification";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { getStatesByCountry } from "../../../../requests/states";
import Checkbox from "../../../UI/Inputs/Checkbox";

function capitalize(string) {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export default function NewStudentForm({
  setNewStudentForm,
  setSuggestedSchools,
  studentData,
  setStudentData,
  studentSearchName,
}) {
  const methods = useForm();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const selectedCountry = methods.watch("country", studentData?.country);

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching countries.");
    }
  };

  const getStates = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;
      const res = await getStatesByCountry({
        countryId: countryId || countries[0]?.id,
      });

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching states.");
    }
  };

  // Let's convert the search term into full name of the user to pre-fill
  const searchNameArray = studentSearchName?.trim().split(" ") || [];
  const searchFirstName = capitalize(searchNameArray[0]);
  const searchLastName =
    searchNameArray.length > 1 ? capitalize(searchNameArray.at(-1)) : undefined;
  const searchMiddleName =
    searchNameArray.length > 2
      ? searchNameArray.slice(1, -1).map(capitalize).join(" ")
      : undefined;

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      getStates();
    }
  }, [selectedCountry, countries]);

  const onSubmit = (data) => {
    setStudentData(data);
    setNewStudentForm(false);
    setSuggestedSchools(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-6">
        <div className="md:flex-row md:mb-6 flex justify-between flex-col">
          <div className="md:w-2/4 md:mr-4">
            <Input
              id="first_name"
              label="First Name"
              dark
              isRequired
              defaultValue={studentData?.first_name || searchFirstName}
            />
          </div>
          <div className="md:w-2/4 md:mr-4">
            <Input
              id="middle_name"
              label="Middle Name"
              dark
              defaultValue={studentData?.middle_name || searchMiddleName}
            />
          </div>
          <div className="md:w-2/4">
            <Input
              id="last_name"
              label="Last Name"
              dark
              isRequired
              defaultValue={studentData?.last_name || searchLastName}
            />
          </div>
        </div>

        <div className="md:w-2/4 md:mb-6">
          <Select
            id="country"
            classNames="w-full bg-transparent"
            isRequired
            defaultValue={studentData?.country}
          >
            <SelectItem value="" disabled>
              Select a country
            </SelectItem>
            {countries.map((country) => (
              <SelectItem key={country.id} value={country.name}>
                {country.name}
              </SelectItem>
            ))}
          </Select>
        </div>

        <InputGroup col>
          <Input
            id="address"
            placeholder="Address 1"
            label="Address"
            dark
            isRequired
            defaultValue={studentData?.address}
          />
          <Input
            id="address_2"
            placeholder="Address 2"
            dark
            defaultValue={studentData?.address_2}
          />
        </InputGroup>

        <InputGroup>
          <Input
            id="city"
            placeholder="City"
            dark
            isRequired
            defaultValue={studentData?.city}
          />

          <Select
            id="state"
            classNames="w-full bg-transparent"
            defaultValue={studentData?.state}
          >
            <SelectItem value="" disabled>
              {methods.watch("country") ? "State a state" : "Select a country"}
            </SelectItem>
            {methods.watch("country") &&
              states.map((state) => (
                <SelectItem key={state.id} value={state.abbreviation}>
                  {state.name}
                </SelectItem>
              ))}
          </Select>

          <Input
            id="zip_code"
            placeholder="Zip/Postal"
            dark
            isRequired
            defaultValue={studentData?.zip_code}
          />

          <Select
            id="include_in_mailing_list"
            classNames="w-full bg-transparent"
            defaultValue={
              studentData?.include_in_mailing_list || "if_address_valid"
            }
            label="Include in mailing list"
          >
            <SelectItem value="if_address_valid">If Address Valid</SelectItem>
            <SelectItem value="always">Always</SelectItem>
            <SelectItem value="never">Never</SelectItem>
          </Select>
        </InputGroup>

        <InputGroup>
          <Input
            id="email"
            placeholder="Email"
            dark
            defaultValue={studentData?.email}
          />

          <Input
            id="social_media"
            placeholder="Social Media"
            dark
            defaultValue={studentData?.social_media}
          />
        </InputGroup>

        <Checkbox
          id="notify_instructor"
          label="Notify the instructor about their new student"
          labelColor="#ebebeb"
          labelFont="font-small"
          defaultChecked={studentData?.notify_instructor}
        />

        <div className="text-center mt-4">
          <Button type="submit" variant="primary">
            Add Student
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
