import React from "react";
import Info from "./SeminarDetails/Info";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { createSeminarRequest } from "../../../requests/seminars";
import Card from "../../UI/Card";
import Button from "../../UI/Button";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";

export default function SeminarNew() {
  const [searchParams] = useSearchParams();
  const methods = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await createSeminarRequest({
        ...data,
        type: "Seminar",
        host_school_id: searchParams.get("school_id"),
      });

      if (res.data) {
        ToastNotification("success", "Seminar created successfully.");
        navigate(`/seminars/${res.data.id}/details`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <Card classNames="lg:mt-10">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="flex">
                <Info />
              </div>
              <Button
                classNames="md:w-auto w-full mt-4"
                variant="primary"
                type="submit"
              >
                Create Seminar
              </Button>
            </form>
          </FormProvider>
        </Card>
      </div>
    </div>
  );
}
