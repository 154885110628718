import React from "react";
import { updateEnrollmentRequest } from "../../../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../../../UI/ToastNotification";
import { useNavigate } from "react-router-dom";
import DecisionsForm from "./DecisionsForm";

export default function Decisions({
  addingDecision,
  decisionOptions,
  setAddingDecision,
  lesson,
  enrollment,
  episodeDelivery,
  user,
}) {
  const navigate = useNavigate();

  const selectedDecisionOptions = decisionOptions.filter((option) =>
    episodeDelivery?.decisions.some(
      (decision) => decision.decision_option_id === option.id
    )
  );

  const onSubmit = async (data) => {
    try {
      const decisions = decisionOptions.map((decision_option) => ({
        id: episodeDelivery?.decisions.find(
          (decision) => decision.decision_option_id === decision_option.id
        )?.id,
        decision_option_id: decision_option.id,
        cached_enrollment_id: enrollment.id,
        _destroy: data.decisions.find(
          (decision) => decision.id === decision_option.id
        )
          ? false
          : true,
      }));

      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            grading_instructor_id: user.id,
            decisions_attributes: decisions,
            episode_id: lesson.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Decisions updated successfully.");
        setAddingDecision(false);
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return addingDecision ? (
    <DecisionsForm
      onSubmit={onSubmit}
      decisionOptions={decisionOptions}
      episodeDelivery={episodeDelivery}
      setAddingDecision={setAddingDecision}
      selectedDecisionOptions={selectedDecisionOptions}
    />
  ) : (
    <div className="flex flex-wrap gap-1">
      {selectedDecisionOptions.length > 0 &&
        selectedDecisionOptions.map((item) => (
          <div
            key={item.id}
            onClick={() => setAddingDecision(true)}
            className="cursor-pointer bg-secondary inline-flex items-center border rounded-full pl-2 pr-1 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-white"
          >
            {item.name}
          </div>
        ))}
    </div>
  );
}
