import React from "react";
import Versions from "../Versions/Versions";
import Version from "../Versions/Version";

export default [
  {
    path: "/versions",
    element: <Versions />,
  },
  {
    path: "/versions/:id",
    element: <Version />,
  },
];
