import React from "react";
import NoteForm from "../../../NoteForm";
import { ToastNotification } from "../../../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../../../requests/enrollments";
import { useNavigate } from "react-router-dom";

export default function Note({
  lesson,
  enrollment,
  episodeDelivery,
  user,
  setAddingNotes,
}) {
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            grading_instructor_id: user.id,
            notes_attributes: [
              {
                text: data.text,
                type: data.type,
              },
            ],
            episode_id: lesson.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Note created successfully.");
        navigate(".", { replace: true });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return <NoteForm setAddingNotes={setAddingNotes} onSubmit={onSubmit} />;
}
