import React, { useEffect, useState } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import QuickFind from "../UI/QuickFind";
import { ToastNotification } from "../UI/ToastNotification";
import Pill from "../UI/Pill";
import { DownloadCSVIcon, PrintIcon } from "../UI/PageActions/Icons";
import { useFilterValues } from "../UI/Filter/Filter";
import EditOnHover from "../UI/EditOnHover";
import InstructorsMobileList from "./Mobile/InstructorsMobileList";
import { getAllUsers, downloadCsvUsersRequest } from "../../requests/users";
import { fullName } from "../../utils/fullName";
import { checkPermission } from "../../utils/checkPermission";
import { hoverLink } from "../../utils/hoverLink";
import { addressMultiLine } from "../../utils/fullAddress";
import { highlightText } from "../../utils/highlightText";
import { usePageActions } from "../../contexts/PageActionsContext";
import { displayError } from "../../utils/displayError";
import InstructorsFilter from "./InstructorsFilter";
import InstructorsPrint from "./InstructorsPrint";

export default function Instructors() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pagination = usePagination();
  const filterValues = useFilterValues();
  const navigate = useNavigate();
  const { setPageActions } = usePageActions();
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "asc";
  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printingInstructors, setPrintingInstructors] = useState([]);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      type: "Instructor",
      page: csv || print ? undefined : page,
      per_page: print ? 10000 : csv ? undefined : pagination.recordsPerPage,
      q: { ...filterValues, sorts: `${sortBy} ${orderBy}` },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      queryParams.q.full_name_i_cont_all = query.trim().split(" ");
    }

    return queryParams;
  };

  const getInstructors = async (callback) => {
    setLoading(true);

    try {
      const res = await getAllUsers({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data.users);
        } else {
          setInstructors(res.data.users);
          pagination.setData(res.data.pagination);
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvUsersRequest({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  // Fetch users when the component mounts
  // and when query params change.
  useEffect(() => {
    getInstructors();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || instructors.length < 1,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        disabled: loading || isPrinting || instructors.length < 1,
        loading: isPrinting,
        label: "Print Records",
        onClick: () => {
          setIsPrinting(true);

          getInstructors((users) => {
            setPrintingInstructors(users);
            setIsPrinting(false);
            setLoading(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    loading,
    searchParams,
    isPrinting,
    downloadingCsv,
    instructors,
  ]);

  useEffect(() => {
    if (printingInstructors.length > 0) window.print();

    return () => {
      if (printingInstructors.length > 0) setPrintingInstructors([]);
    };
  }, [printingInstructors]);

  return (
    <div>
      <div className="md:hidden">
        <InstructorsMobileList instructors={instructors} />
      </div>
      <div className="hidden md:block">
        <Card>
          <div className="flex items-center justify-between mb-6">
            <div className="relative">
              {checkPermission(["school_admin", "conference_admin"]) && (
                <img
                  src="/add-new.svg"
                  className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                  width={30}
                  height={30}
                  alt="Add New"
                  onClick={() => navigate("/instructors/new")}
                />
              )}

              <h1 className="text-xl font-bold text-primary">INSTRUCTORS</h1>
            </div>
            <div className="flex">
              <QuickFind onSubmit={onSubmit} value={query} />
              <div className="relative ml-7">
                <InstructorsFilter />
              </div>
            </div>
          </div>
          <Pagination
            pagination={pagination}
            loading={loading && instructors.length < 1}
            empty={instructors.length < 1}
          >
            <Table>
              <thead>
                <tr>
                  <TableHeader
                    isSortable
                    isMultiSort
                    multiSortOptions={[
                      { name: "First", value: "first_name" },
                      { name: "Last", value: "last_name" },
                    ]}
                  >
                    INSTRUCTOR
                  </TableHeader>
                  <TableHeader>STUDENTS</TableHeader>
                  <TableHeader>CONTACT INFO</TableHeader>
                  <TableHeader>ADDRESS</TableHeader>
                  <TableHeader>ROLES</TableHeader>
                </tr>
              </thead>
              <tbody>
                {instructors.map((instructor) => (
                  <tr
                    key={instructor.id}
                    className={loading ? "opacity-25" : undefined}
                  >
                    <TableCell>
                      <EditOnHover to={`/instructors/${instructor.id}/profile`}>
                        <Link
                          className={hoverLink}
                          to={`/instructors/${instructor.id}/overview`}
                        >
                          {highlightText(
                            fullName(
                              instructor.first_name,
                              " ",
                              instructor.last_name
                            ),
                            query
                          )}
                        </Link>
                      </EditOnHover>
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/instructors/${instructor.id}/students`}
                      >
                        {instructor.students_count}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <p>{instructor.email}</p>
                      <p>{instructor.phone}</p>
                    </TableCell>
                    <TableCell className="whitespace-nowrap">
                      {addressMultiLine({
                        address: instructor.address,
                        address_2: instructor.address_2,
                        city: instructor.city,
                        state: instructor.state,
                        country: instructor.country,
                        zip_code: instructor.zip_code,
                      })}
                    </TableCell>
                    <TableCell>
                      {instructor.roles.map((role) => (
                        <div key={role.id}>
                          <Pill>{role.titleized_role}</Pill> at{" "}
                          <Link
                            className={hoverLink}
                            to={`/schools/${role.organization_id}/overview`}
                          >
                            {role.organization_name}
                          </Link>
                        </div>
                      ))}
                    </TableCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Pagination>
        </Card>
      </div>

      <InstructorsPrint instructors={printingInstructors} />
    </div>
  );
}
