import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Checkbox from "../../../UI/Inputs/Checkbox";
import { getAllCountries } from "../../../../requests/countries";
import { getStatesByCountry } from "../../../../requests/states";
import { getSchoolRequest } from "../../../../requests/schools";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { ToastNotification } from "../../../UI/ToastNotification";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

export default function ContactInformationForm({ student, isAdmin }) {
  const [searchParams] = useSearchParams();
  const schoolId = searchParams.get("school_id");
  const { watch, setValue } = useFormContext();
  const [denominations, setDenominations] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const selectedCountry = watch("country", student?.country);
  const [school, setSchool] = useState();

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching countries.");
    }
  };

  const getStates = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;
      const res = await getStatesByCountry({
        countryId: countryId || countries[0]?.id,
      });

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching states.");
    }
  };

  const fetchSchool = () => {
    getSchoolRequest({ schoolId }).then((res) => {
      if (res.data) {
        setSchool(res.data);
      }
    });
  };

  useEffect(() => {
    setDenominations(["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof"]);
    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      getStates();
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (schoolId) fetchSchool();
  }, [schoolId]);

  // Pre-fill country if school is found
  useEffect(() => {
    if (countries.length > 0 && school?.school_country && !student?.country) {
      setValue("country", school.school_country);
    }
  }, [school, countries]);

  // Pre-fill state if school is found
  useEffect(() => {
    if (states.length > 0 && school?.school_state && !student?.state) {
      setValue("state", school.school_state);
    }
  }, [school, states]);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Contact Information</h2>

      <InputGroup classNames="md:flex md:gap-4">
        <div className="md:w-2/5">
          <Select disabled={!isAdmin} id="title">
            <SelectItem value="" disabled>
              Title
            </SelectItem>
            {denominations.map((option) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </Select>
        </div>
        <Input
          id="first_name"
          defaultValue={student?.first_name}
          placeholder="First name"
          disabled={!isAdmin}
          isRequired
        />
        <div className="md:w-3/5">
          <Input
            id="middle_name"
            defaultValue={student?.middle_name}
            placeholder="Middle name"
            disabled={!isAdmin}
          />
        </div>
        <Input
          id="last_name"
          defaultValue={student?.last_name}
          placeholder="Last name"
          disabled={!isAdmin}
          isRequired
        />
      </InputGroup>

      <InputGroup>
        <div className=" md:w-2/5">
          {countries.length > 0 && (
            <Select
              disabled={!isAdmin}
              id="country"
              defaultValue={student?.country || ""}
            >
              <SelectItem value="" disabled>
                Select a country
              </SelectItem>
              {countries.map((country) => (
                <SelectItem key={country.id} value={country.name}>
                  {country.name}
                </SelectItem>
              ))}
            </Select>
          )}
        </div>
      </InputGroup>

      <InputGroup col>
        <Input
          id="address"
          defaultValue={student?.address}
          placeholder="Address 1"
          label="Address"
          disabled={!isAdmin}
        />
        <Input
          id="address_2"
          defaultValue={student?.address_2}
          placeholder="Address 2"
          disabled={!isAdmin}
        />
      </InputGroup>

      <InputGroup classNames="md:flex md:gap-4">
        <Input
          id="city"
          defaultValue={student?.city}
          placeholder="City"
          disabled={!isAdmin}
        />

        <div className="md:w-2/5">
          {states.length > 0 && (
            <Select
              disabled={!isAdmin}
              id="state"
              defaultValue={student?.state}
            >
              <SelectItem value="" disabled>
                {watch("country") ? "State a state" : "Select a country"}
              </SelectItem>
              {watch("country") &&
                states.map((state) => (
                  <SelectItem key={state.id} value={state.abbreviation}>
                    {state.name}
                  </SelectItem>
                ))}
            </Select>
          )}
        </div>

        <div className="md:w-2/5">
          <Input
            id="zip_code"
            defaultValue={student?.zip_code}
            placeholder="Zip/Postal"
            disabled={!isAdmin}
          />
        </div>
      </InputGroup>

      <InputGroup>
        <Select
          wrapperClassName="flex items-center gap-4"
          id="include_in_mailing_list"
          defaultValue={student?.include_in_mailing_list || "if_address_valid"}
          label="Include in Mailing List"
        >
          <SelectItem value="if_address_valid">If Address Valid</SelectItem>
          <SelectItem value="always">Always</SelectItem>
          <SelectItem value="never">Never</SelectItem>
        </Select>
      </InputGroup>

      <InputGroup>
        <Input
          disabled={!isAdmin}
          id="email"
          defaultValue={student?.email}
          placeholder="Email Address"
          label="Email Address"
        />
      </InputGroup>

      <InputGroup classNames="flex items-center">
        <Input
          classNames="md:w-2/3"
          disabled={!isAdmin}
          id="phone"
          defaultValue={student?.phone}
          placeholder="Phone"
          label="Phone"
        />
        <Checkbox
          classNames="mt-6"
          disabled={!isAdmin}
          id="phone_is_sms"
          defaultChecked={student?.phone_is_sms}
          label="SMS"
        />
      </InputGroup>

      <InputGroup classNames="flex items-center">
        <Input
          classNames="md:w-2/3"
          disabled={!isAdmin}
          id="phone_2"
          defaultValue={student?.phone_2}
          placeholder="Phone 2"
          label="Phone 2"
        />
        <Checkbox
          classNames="mt-6"
          disabled={!isAdmin}
          id="phone_2_is_sms"
          defaultChecked={student?.phone_2_is_sms}
          label="SMS"
        />
      </InputGroup>

      <InputGroup>
        <Input
          disabled={!isAdmin}
          id="social_media"
          defaultValue={student?.social_media}
          placeholder="Social Media"
          label="Social Media"
        />
      </InputGroup>
    </div>
  );
}
