import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import AddButton from "../../../UI/AddButton";
import { getAllCourseLessonsRequest } from "../../../../requests/courses";
import { useParams } from "react-router-dom";
import CourseLessonRow from "./CourseLessonRow";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import { ToastNotification } from "../../../UI/ToastNotification";
import { deleteLessonRequest } from "../../../../requests/lessons";
import CourseLessonRowNew from "./CourseLessonRowNew";
import EmptyTable from "../../../UI/EmptyTable";
import { checkPermission } from "../../../../utils/checkPermission";

export default function CourseLessons() {
  const params = useParams();
  const courseId = params.courseId;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeLessonId, setActiveLessonId] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [formOpen, setFormOpen] = useState({ open: false, action: null });

  const getAllCourseLessons = async () => {
    const res = await getAllCourseLessonsRequest({ courseId: courseId });

    if (res.data) {
      setLessons(res.data);
    }
  };

  const deleteLesson = async () => {
    try {
      const res = await deleteLessonRequest({ lessonId: activeLessonId });

      if (res) {
        ToastNotification("success", "Lesson deleted successfully.");
        setDeleteModalOpen(false);
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const handleDeleteModal = (e, lessonId) => {
    e.preventDefault();
    setDeleteModalOpen(true);
    setActiveLessonId(lessonId);
  };

  const handleAddLessons = (e) => {
    e.preventDefault();
    const count = parseInt(e.target.elements["lesson-count"].value, 10);
    setFormOpen({ open: true, action: "new", count: count });
    e.target.reset();
  };

  useEffect(() => {
    getAllCourseLessons();
  }, [formOpen, deleteModalOpen]);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["global_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() => setFormOpen({ open: true, action: "new" })}
              />
            )}

            <h1 className="text-xl font-bold text-primary">LESSONS</h1>
          </div>
        </div>
        {lessons.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>LESSON</TableHeader>
                <TableHeader>DATE</TableHeader>
                <TableHeader>QUESTIONS</TableHeader>
                <TableHeader>RESPONSES</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {lessons.map((lesson) => (
                <CourseLessonRow
                  key={lesson.id}
                  lesson={lesson}
                  formOpen={formOpen}
                  setFormOpen={setFormOpen}
                  handleDeleteModal={handleDeleteModal}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No lessons found" />
        )}

        {formOpen.action === "new" && (
          <div>
            <CourseLessonRowNew
              courseId={courseId}
              setFormOpen={setFormOpen}
              count={parseInt(formOpen.count, 10)}
              lessons={lessons}
            />
          </div>
        )}

        {formOpen.action === "new" ? null : (
          <form
            className="flex items-center justify-center mt-4 space-x-4"
            onSubmit={handleAddLessons}
          >
            <label htmlFor="lesson-count">Add lessons</label>
            <input
              id="lesson-count"
              type="number"
              defaultValue={1}
              min={1}
              max={100}
              step={1}
              className="w-20 text-center px-1 py-2 rounded-md border border-gray-300"
            />
            <AddButton type="submit" label="Add" variant="primary" />
          </form>
        )}
      </Card>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteLesson()}
        />
      </Modal>
    </div>
  );
}
