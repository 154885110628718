import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

export default function Modal({
  open,
  setOpen,
  children,
  fullWidth = false,
  dark = false,
  closeButton = false,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center md:p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  dark ? "bg-neutral-600" : "bg-white",
                  fullWidth ? "sm:w-full 2xl:w-2/3" : "sm:w-auto",
                  "sm:rounded-lg sm:mx-4 rounded-t relative transform text-left shadow-xl transition-all w-full"
                )}
              >
                {closeButton && (
                  <button
                    onClick={() => setOpen(false)}
                    className={twMerge(
                      "absolute -right-3 -top-3 rounded-full p-2",
                      dark
                        ? "bg-neutral-700 text-white hover:bg-neutral-800"
                        : "bg-white text-gray-500 hover:bg-gray-100",
                      "shadow-lg z-10 transition-colors duration-200"
                    )}
                    aria-label="Close modal"
                  >
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                )}
                <div className="overflow-hidden sm:rounded-lg">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
