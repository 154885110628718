import React from "react";
import { Link } from "react-router-dom";

const EditOnHover = ({ children, to }) => {
  return (
    <div className="group flex items-center">
      {children}
      <Link
        className="text-secondary-600 text-sm ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 hover:bg-secondary/20 py-1 px-2 rounded-md"
        to={to}
      >
        Edit
      </Link>
    </div>
  );
};

export default EditOnHover;
