import React, { useMemo } from "react";
import Input from "../../../UI/Inputs/Input";
import AddButton from "../../../UI/AddButton";
import Checkbox from "../../../UI/Inputs/Checkbox";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { useFieldArray, useFormContext } from "react-hook-form";
import RoleSelectRow from "./RoleSelectRow";
import { useSearchParams } from "react-router-dom";

export default function AccountForm({ instructor }) {
  const [searchParams] = useSearchParams();
  const schoolId = searchParams.get("school_id");
  const role = searchParams.get("role");
  const headSchoolAdmin = Boolean(
    JSON.parse(searchParams.get("head_school_admin") || "0")
  );
  const { control, watch, setValue } = useFormContext();

  const { fields, append, update } = useFieldArray({
    control,
    name: "roles_attributes",
  });

  useMemo(() => {
    if (role && schoolId) {
      append({ role_type: role, organization_id: schoolId });
      setValue("head_school_admin_of", headSchoolAdmin ? schoolId : undefined);
    }
  }, [schoolId, role, append, setValue]);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Password</h2>

      <div className="mb-6">
        <InputGroup col>
          {instructor && (
            <Input
              id="current_password"
              type="password"
              placeholder="Current Password"
            />
          )}
          <Input
            id="password"
            type="password"
            placeholder="New Password"
            isRequired={!instructor}
          />
          <Input
            id="password_confirmation"
            type="password"
            placeholder="New Password Confirmation"
            isRequired={!instructor}
          />
        </InputGroup>

        <p className="mb-6 text-sm">
          Password needs uppercase, lowercase, number, special character and at
          least 9 characters.
        </p>
      </div>

      <div className="mb-6 border-b border-black h-px"></div>

      <h2 className="mb-6 text-2xl">Options</h2>

      <div className="mb-6">
        <div className="mb-6">
          <Checkbox
            id="followup_notification"
            label="Receive notifications by email"
            defaultChecked={
              instructor ? instructor.followup_notification : true
            }
          />
        </div>

        <div>
          <Checkbox
            id="admin"
            defaultChecked={instructor?.admin}
            label="Global Admin"
          />
        </div>

        {headSchoolAdmin && (
          <div className="mt-6">
            <Checkbox
              id="head_school_admin"
              label="Head School Admin"
              defaultChecked
            />
          </div>
        )}
      </div>

      {!watch("admin", instructor?.admin) && (
        <>
          <div className="mb-6 border-b border-black h-px"></div>

          <h2 className="mb-6 text-2xl">Roles</h2>

          {fields
            .filter((activeField) => !activeField.delete)
            .map((field, index) => (
              <RoleSelectRow
                key={field.id}
                field={field}
                index={index}
                update={update}
              />
            ))}

          <div className="mb-6">
            <AddButton
              onClick={() => append({ role_type: "instructor" })}
              label="Add additional role"
              variant="primary-outline"
            />
          </div>
        </>
      )}
    </div>
  );
}
