import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import CardHeader from "../../../UI/CardHeader";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import StudentMobileNotes from "./Mobile/StudentMobileNotes";
import { getAllStudentNotesRequest } from "../../../../requests/students";
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { humanize } from "../../../../utils/humanizeString";
import EmptyTable from "../../../UI/EmptyTable";
import ClipboardIcon from "../../../UI/Icons/ClipboardIcon";
import PrayerRequestIcon from "../../../UI/Icons/PrayerRequestIcon";

export default function StudentNotes() {
  const navigate = useNavigate();
  const student = useRouteLoaderData("student").data;
  const [notes, setNotes] = useState([]);
  const [expandedNoteId, setExpandedNoteId] = useState(null);

  const getAllStudentNotes = async () => {
    const res = await getAllStudentNotesRequest({
      studentId: student.id,
    });

    if (res.data) {
      setNotes([
        ...res.data.episode_delivery_notes,
        ...res.data.reminder_notes,
        ...res.data.visit_notes,
      ]);
    }
  };

  const seriesTypePlural = (type) => {
    return type === "Course" ? "courses" : "seminars";
  };

  const notableTypePlural = (note) => {
    switch (note.notable_type) {
      case "Visit":
        return "visits";
      case "EpisodeDelivery":
        return note.series_type === "Course" ? "lessons" : "presentations";
      case "Reminder":
        return "reminders";
    }
  };

  useEffect(() => {
    getAllStudentNotes();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileNotes notes={notes} />
      </div>
      <Card classNames="md:block hidden min-h-[260px]">
        <CardHeader title="Notes" />

        {notes.length > 0 ? (
          <TableScrollable height={147}>
            <thead>
              <tr>
                <TableHeader classNames="w-1/12 text-center">TYPE</TableHeader>
                <TableHeader classNames="w-5/12">NOTE</TableHeader>
                <TableHeader classNames="w-4/12">CONTEXT</TableHeader>
                <TableHeader classNames="w-2/12">DATE</TableHeader>
              </tr>
            </thead>
            <tbody className="h-[147px]">
              {notes.map((note) => (
                <tr
                  key={note.id}
                  onClick={() =>
                    navigate(
                      `/students/${student.id}/${seriesTypePlural(
                        note.series_type
                      )}/enrollments/${note.enrollment_id}/${notableTypePlural(
                        note
                      )}`
                    )
                  }
                  className="cursor-pointer"
                >
                  <TableCell classNames="w-1/12 text-secondary">
                    <div
                      className="flex items-center justify-center"
                      title={humanize(note.note_type)}
                    >
                      {note.note_type === "Note" ? (
                        <ClipboardIcon />
                      ) : (
                        <PrayerRequestIcon />
                      )}
                    </div>
                  </TableCell>
                  <TableCell classNames="w-5/12">
                    {expandedNoteId === note.id ? (
                      note.text
                    ) : (
                      <>
                        {note.text.slice(0, 25)}
                        {note.text.length > 25 && (
                          <>
                            ...{" "}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setExpandedNoteId(note.id);
                              }}
                              className="text-sm text-secondary hover:underline"
                            >
                              Show all
                            </button>
                          </>
                        )}
                      </>
                    )}
                    {expandedNoteId === note.id && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setExpandedNoteId(null);
                        }}
                        className="ml-1 text-sm text-secondary hover:underline"
                      >
                        Show less
                      </button>
                    )}
                  </TableCell>
                  <TableCell classNames="w-4/12">{note.context}</TableCell>
                  <TableCell classNames="w-2/12">
                    {dayjs(note.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </TableScrollable>
        ) : (
          <EmptyTable message="No notes found for this student." />
        )}
      </Card>
    </>
  );
}
