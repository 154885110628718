import React from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";

export default function LessonsSummary({ course, lessons }) {
  return (
    <Card classNames="overflow-y-scroll no-scrollbar">
      <CardHeader
        title="Lessons Summary"
        to={`/courses/${course.id}/lessons`}
      />

      {lessons.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>LESSON</TableHeader>
              <TableHeader>LESSON DELIVERED</TableHeader>
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson) => (
              <tr key={lesson.id}>
                <TableCell>{lesson.name}</TableCell>
                <TableCell>{`${lesson.lessons_delivered} delivered of ${lesson.enrollments_count} enrolled`}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No lessons available." />
      )}
    </Card>
  );
}
