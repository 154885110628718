import React, { useState } from "react";
import dayjs from "dayjs";
import TableCell from "../../../../../UI/Table/TableCell";
import { useNavigate } from "react-router-dom";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { displayError } from "../../../../../../utils/displayError";
import Notes from "./Notes/Notes";
import Decisions from "./Decisions/Decisions";
import { PlusIcon } from "@heroicons/react/20/solid";
import DropdownMenu from "../../../../../UI/DropdownMenu";

export default function PresentationRow({
  presentation,
  enrollment,
  decisionOptions,
  user,
}) {
  const navigate = useNavigate();
  const [addingDecision, setAddingDecision] = useState(false);
  const [addingNotes, setAddingNotes] = useState(false);

  const episodeDelivery = enrollment.episode_deliveries.find(
    (item) => item.episode_id === presentation.id
  );

  const dropdownItems = [
    {
      label: "Add Note",
      icon: <PlusIcon />,
      onClick: () => setAddingNotes(true),
    },
    {
      label: "Add Decision",
      icon: <PlusIcon />,
      onClick: () => setAddingDecision(true),
    },
  ];

  const onSubmit = async () => {
    try {
      const rest = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            date_completed: episodeDelivery?.date_completed ? null : new Date(),
            episode_id: presentation.id,
            grading_instructor_id: user.id,
            delivery_type: "Personal Delivery",
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (rest.data) {
        ToastNotification("success", "Attendance taken successfully");
        navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <tr>
      <TableCell>
        <p>{dayjs(presentation.date).format("MM/DD/YYYY")}</p>
      </TableCell>
      <TableCell>
        <p>{presentation.name}</p>
      </TableCell>
      <TableCell classNames="text-center">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          onClick={() => onSubmit()}
          defaultChecked={episodeDelivery?.date_completed ? true : false}
        />
      </TableCell>
      <TableCell>
        <Notes
          addingNotes={addingNotes}
          setAddingNotes={setAddingNotes}
          presentation={presentation}
          enrollment={enrollment}
          episodeDelivery={episodeDelivery}
          user={user}
        />
      </TableCell>
      <TableCell>
        <Decisions
          addingDecision={addingDecision}
          setAddingDecision={setAddingDecision}
          presentation={presentation}
          enrollment={enrollment}
          episodeDelivery={episodeDelivery}
          decisionOptions={decisionOptions}
          user={user}
        />
      </TableCell>
      <TableCell classNames="text-center">
        <DropdownMenu items={dropdownItems} />
      </TableCell>
    </tr>
  );
}
