import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../UI/Button";
import { getAllSchools } from "../../../../requests/schools";
import InputGroup from "../../../UI/Inputs/InputGroup";
import SearchSchool from "../../../UI/SearchSchool";

export default function PlaceManually() {
  const navigate = useNavigate();
  const methods = useForm();
  const [schools, setSchools] = useState([]);

  const getSchools = async () => {
    const res = await getAllSchools({ params: { pagination: false } });
    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  const onSubmit = (data) =>
    navigate(`/students/new?school_id=${data.school?.value}`);

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <>
      <h2 className="text-2xl">Place in School Manually</h2>
      <p className="text-[15px]">
        Select the school you would like to place the new student in.
      </p>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-6 rounded-md min-h-[350px]"
        >
          {schools.length > 0 && (
            <InputGroup>
              <SearchSchool
                id="school"
                options={schools.map((school) => ({
                  value: school.id,
                  label: school.display_name,
                }))}
                isRequired
              />
            </InputGroup>
          )}

          <p className="text-[15px] mb-6 mt-4">
            To continue creating the new student, click the button below to
            complete the new student profile and enrollments.
          </p>

          <div className="text-center">
            <Button type="submit" variant="primary">
              Create New Student
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
