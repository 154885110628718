import React from "react";
import NavGroup from "../../../../UI/NavGroup/NavGroup";
import NavGroupItem from "../../../../UI/NavGroup/NavGroupItem";

export default function StudentCourseNav({ studentId, enrollmentId }) {
  const studentCourseNavigation = [
    {
      label: "Lessons",
      url: "lessons",
    },
    {
      label: "Decisions",
      url: "decisions",
    },
    {
      label: "Questions",
      url: "questions",
    },
    {
      label: "Products",
      url: "products",
    },
    {
      label: "Reminders",
      url: "reminders",
    },
    {
      label: "Visits",
      url: "visits",
    },
    {
      label: "Management",
      url: "management",
    },
  ];

  return (
    <NavGroup>
      {studentCourseNavigation.map((item) => (
        <NavGroupItem
          key={item.label}
          label={item.label}
          url={`/students/${studentId}/courses/enrollments/${enrollmentId}/${item.url}`}
        />
      ))}
    </NavGroup>
  );
}
