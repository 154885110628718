import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import PageLoader from "../../../../../../UI/PageLoader";
import CardLink from "../../../../../../UI/Card/CardLink";
import LessonForm from "./LessonForm";

export default function LessonsMobile({ lessons, enrollment, user }) {
  if (!lessons) return <PageLoader className="h-[60vh]" />;

  const [formOpen, setFormOpen] = useState();
  const [updatedEnrollment, setUpdatedEnrollment] = useState(enrollment);

  // Merge student enrollments into courses and sort it so that
  // graduated courses are at the bottom.
  let sortedLessons = lessons
    .map((lesson) => ({
      ...lesson,
      episodeDelivery: updatedEnrollment.episode_deliveries.find(
        (item) => item.episode_id === lesson.id
      ),
    }))
    .map((lesson) => ({
      ...lesson,
      completed: Boolean(lesson.episodeDelivery?.date_completed),
      sent: Boolean(lesson.episodeDelivery?.date_sent),
    }))
    .sort(
      (a, b) => Number(Boolean(a.completed)) - Number(Boolean(b.completed))
    );

  return sortedLessons.map((lesson) =>
    !lesson.completed ? (
      <CardLink
        key={lesson.id}
        className="mb-4 last:mb-10 block"
        cardClassName="rounded-2xl p-0 py-2"
        onClick={() => {
          setFormOpen(lesson.id);
        }}
        activeEffect={formOpen !== lesson.id}
      >
        <span
          className={twMerge(
            "block py-2 px-4 text-lg leading-none",
            lesson.sent && "border-b border-gray-500/10"
          )}
        >
          {lesson.name}
        </span>
        {lesson.sent && (
          <span className="flex items-end justify-between pt-2 px-4">
            <span>
              <span className="block">
                Sent - {lesson.episodeDelivery?.delivery_type}
              </span>
              <span className="block">
                {Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  timeZone: "UTC",
                }).format(new Date(lesson.episodeDelivery?.date_sent))}
              </span>
            </span>
            <span className="text-center">
              <span className="block text-xs whitespace-nowrap">Days Past</span>
              <span className="block text-[40px] leading-none">
                {Math.floor(
                  (new Date() - new Date(lesson.episodeDelivery.date_sent)) /
                    (1000 * 60 * 60 * 24)
                )}
              </span>
            </span>
          </span>
        )}
        <LessonForm
          open={formOpen === lesson.id}
          enrollment={enrollment}
          lesson={lesson}
          episodeDelivery={lesson.episodeDelivery}
          user={user}
          setFormOpen={setFormOpen}
          setUpdatedEnrollment={setUpdatedEnrollment}
        />
      </CardLink>
    ) : (
      <CardLink
        key={lesson.id}
        className="mb-4 last:mb-10 block"
        cardClassName="rounded-2xl p-6 flex justify-between items-center bg-black/70 gap-4 text-white group-active:bg-black"
      >
        <span className="block text-lg leading-none">{lesson.name}</span>
        <span className="text-center text-xs">
          <span className="block whitespace-nowrap">Completed</span>
          <span className="block whitespace-nowrap">
            {Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            }).format(new Date(lesson.episodeDelivery?.date_completed))}
          </span>
        </span>
      </CardLink>
    )
  );
}
