import React, { useEffect, useRef } from "react";

export default function NavGroup({ children }) {
  const parentRef = useRef(null);

  useEffect(() => {
    // Dynamically set the width of the parent container (width = sum of all children's div width)
    if (parentRef.current) {
      const childrenWidth = Array.from(parentRef.current.children).reduce(
        (acc, child) => acc + child.offsetWidth,
        0
      );
      parentRef.current.style.width = `${childrenWidth}px`;
    }
  }, []);

  return (
    <div
      ref={parentRef}
      className="bg-white flex mx-auto text-center rounded-full mt-4 mb-6 shadow"
      role="group"
    >
      {children}
    </div>
  );
}
