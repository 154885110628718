import React from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";

export default function DecisionsSummary({ course, decisions }) {
  return (
    <Card classNames="overflow-y-scroll no-scrollbar mb-4">
      <CardHeader
        title="Decisions Summary"
        to={`/courses/${course.id}/decisions`}
      />

      {decisions.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>DECISION</TableHeader>
              <TableHeader>DECISIONS RECORDED</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {decisions.map((decision) => (
              <tr key={decision.id}>
                <TableCell>{decision.name}</TableCell>
                <TableCell>{decision.decisions_count}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No decisions found." />
      )}
    </Card>
  );
}
