import React, { useCallback } from "react";
import Filter from "../UI/Filter/Filter";
import { getAllCountries } from "../../requests/countries";
import { getStatesByCountryName } from "../../requests/states";
import { getAllSchools } from "../../requests/schools";

export default function InstructorsFilter() {
  const getCountries = useCallback(async () => {
    try {
      const res = await getAllCountries();
      return res.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getStates = useCallback(async (countryName) => {
    try {
      const res = await getStatesByCountryName({ countryName });
      return res.data.map((state) => ({
        label: `${state.name} (${state.abbreviation})`,
        value: state.abbreviation,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSchools = useCallback(async () => {
    try {
      const res = await getAllSchools({
        params: { pagination: false },
      });
      return res.data.schools.map((school) => ({
        label: `(#${school.school_code}) ${school.school_name}`,
        value: school.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const filterAttributes = {
    roles_organization_id: {
      label: "School",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getSchools,
      autocomplete: true,
    },
    country: {
      label: "Country",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getCountries,
    },
    state: {
      label: "State",
      operators: ["eq", "not_eq", "present", "blank"],
      defaultOperator: "eq",
      options: getStates,
      autocomplete: true,
      dependsOn: "country",
    },
    created_at: {
      label: "Record created",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
    updated_at: {
      label: "Record updated",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
  };

  return <Filter attributes={filterAttributes} />;
}
