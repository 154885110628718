import React from "react";
import { twMerge } from "tailwind-merge";

export const returnEnrollmentStatus = (courseId, enrollment) => {
  let statusColor;

  switch (enrollment.status) {
    case "Applied":
      statusColor = "text-yellow-500";
      break;
    case "Attended":
      statusColor = "text-blue-800";
      break;
    case "Enrolled":
      statusColor = "text-blue-800";
      break;
    case "Graduated":
      statusColor = "text-green-500";
      break;
    case "Inactive":
      statusColor = "text-gray-500";
      break;
    default:
      statusColor = "text-yellow-500";
  }

  return (
    <span className={twMerge(statusColor, "font-medium")}>
      {enrollment.status}
    </span>
  );
};
