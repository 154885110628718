import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import DatePickerInput from "../../../../../../UI/Inputs/DatePickerInput";
import Select from "../../../../../../UI/Inputs/Select/Select";
import SelectItem from "../../../../../../UI/Inputs/Select/SelectItem";
import Button from "../../../../../../UI/Button";
import PageLoader from "../../../../../../UI/PageLoader";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";

export default function LessonForm({
  enrollment,
  lesson,
  episodeDelivery,
  user,
  setFormOpen,
  setUpdatedEnrollment,
  open,
}) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setFormOpen(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            date_sent: data.date_sent,
            received_date: data.received_date,
            delivery_type: data.delivery_type,
            episode_id: lesson.id,
            grading_instructor_id: user.id,
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        handleClose();
        setUpdatedEnrollment(res.data);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <PageLoader className="h-[200px]" variant="primary" />
  ) : (
    <div
      className={twMerge(
        "transform transition-all duration-300 ease-in-out origin-top",
        open ? "h-auto opacity-100 scale-y-100" : "h-0 opacity-0 scale-y-0"
      )}
    >
      {open && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="p-4 flex flex-col gap-4 border-t border-gray-500/10 mt-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div>
              <label htmlFor="delivery_type">Delivery type</label>
              <Select
                id="delivery_type"
                placeholder="Select delivery type"
                defaultValue={episodeDelivery?.delivery_type}
              >
                {["Post Office", "Personal Delivery", "In-home"].map(
                  (option) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  )
                )}
              </Select>
            </div>

            <div>
              <label htmlFor="date_sent">Sent</label>
              <DatePickerInput
                id="date_sent"
                selected={episodeDelivery?.date_sent}
              />
            </div>

            <div>
              <label htmlFor="received_date">Received</label>
              <DatePickerInput
                id="received_date"
                selected={episodeDelivery?.received_date}
              />
            </div>

            <div className="flex gap-4 justify-center">
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
}
