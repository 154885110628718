import React, { useState } from "react";
import { hoverLink } from "../../../../utils/hoverLink";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import { updateEnrollmentRequest } from "../../../../requests/enrollments";
import { ToastNotification } from "../../../UI/ToastNotification";
import { displayError } from "../../../../utils/displayError";
import { highlightText } from "../../../../utils/highlightText";

export default function AddRegistrationNumber({
  enrollment,
  reloadStudents,
  query,
}) {
  const methods = useForm();
  const [addingRegistrionNumber, setAddingRegistrionNumber] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        ...data,
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification(
          "success",
          "Registration number updated successfully."
        );
        reloadStudents();
        setAddingRegistrionNumber(false);
      }
    } catch (e) {
      displayError(e);
    }
  };

  if (addingRegistrionNumber) {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-end">
            <Input
              id="registration_number"
              classNames="w-3/12 mr-4"
              placeholder="Registration No."
              defaultValue={enrollment?.registration_number}
            />
            <Button
              onClick={() => setAddingRegistrionNumber(false)}
              classNames="mr-4"
              variant="primary-outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary-outline">
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    );
  }

  return (
    <span onClick={() => setAddingRegistrionNumber(true)} className={hoverLink}>
      {highlightText(enrollment.registration_number, query) ||
        "Add registration number"}
    </span>
  );
}
