import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/versions`;

const getAllVersions = async (params) =>
  await axiosClient.get(endpoint, params);

const getVersion = async (id) => await axiosClient.get(`${endpoint}/${id}`);

export { getAllVersions, getVersion };
