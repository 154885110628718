import React from "react";
import Card from "../UI/Card";
import TableScrollable from "../UI/Table/TableScrollable";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import EmptyTable from "../UI/EmptyTable";
import PageLoader from "../UI/PageLoader";
import { hoverLink } from "../../utils/hoverLink";
import { fullName } from "../../utils/fullName";
import { Link } from "react-router-dom";
import CardHeader from "../UI/CardHeader";

export default function InstructorsTable({ instructors }) {
  return (
    <Card classNames="min-h-[425px]">
      <CardHeader title="Instructors" to="/instructors" />

      {instructors?.length > 0 ? (
        <TableScrollable height={300}>
          <thead>
            <tr>
              <TableHeader classNames="w-1/3">INSTRUCTOR</TableHeader>
              <TableHeader classNames="w-1/3 text-center">STUDENTS</TableHeader>
              <TableHeader classNames="w-1/3">SCHOOL</TableHeader>
            </tr>
          </thead>
          <tbody className="h-[300px]">
            {instructors.map((instructor) => (
              <tr key={instructor.id}>
                <TableCell classNames="w-1/3">
                  <Link
                    className={hoverLink}
                    to={`/instructors/${instructor.id}/overview`}
                  >
                    {fullName(instructor.first_name, " ", instructor.last_name)}
                  </Link>
                </TableCell>
                <TableCell classNames="w-1/3 text-center">
                  <Link
                    className={hoverLink}
                    to={`/instructors/${instructor.id}/students`}
                  >
                    {instructor.students_count}
                  </Link>
                </TableCell>
                <TableCell classNames="w-1/3">
                  {instructor.roles.map((role) => (
                    <div key={role.id}>{role.organization_name}</div>
                  ))}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : instructors ? (
        <EmptyTable message="No instructors available." />
      ) : (
        <PageLoader />
      )}
    </Card>
  );
}
