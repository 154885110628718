import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import EmptyTable from "../../../../../UI/EmptyTable";
import { getAllCourseDecisionsRequest } from "../../../../../../requests/courses";
import { getAllSeminarPresentationsRequest } from "../../../../../../requests/seminars";
import { displayError } from "../../../../../../utils/displayError";
import PresentationRow from "./PresentationRow";

export default function Presentations() {
  const { user, seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;
  const [decisionOptions, setDecisionOptions] = useState([]);
  const [presentations, setPresentations] = useState([]);

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: enrollment.series_id,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  const getAllSeminarPresentations = async () => {
    try {
      const res = await getAllSeminarPresentationsRequest({
        seminarId: enrollment.series_id,
      });

      if (res.data) {
        setPresentations(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getAllCourseDecisions();
    getAllSeminarPresentations();
  }, []);

  return (
    <div>
      {presentations?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>DATE</TableHeader>
              <TableHeader>PRESENTATION</TableHeader>
              <TableHeader classNames="text-center">ATTENDED</TableHeader>
              <TableHeader>NOTES & PRAYER REQUESTS</TableHeader>
              <TableHeader>DECISIONS</TableHeader>
              <TableHeader classNames="text-center">ACTIONS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {presentations.map((presentation) => (
              <PresentationRow
                key={presentation.id}
                enrollment={enrollment}
                presentation={presentation}
                decisionOptions={decisionOptions}
                user={user}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No Presentations recorded." />
      )}
    </div>
  );
}
