import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/available_source_codes`;

const getAvailableSourceCodes = async () => {
  const data = await axiosClient.get(endpoint);

  return data;
};

export { getAvailableSourceCodes };
