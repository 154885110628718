import React, { useState, useEffect } from "react";
import Dialog from "../UI/Modal";
import { useLocation, useMatches } from "react-router-dom";
import {
  dashboardLinks,
  studentLinks,
} from "../../utils/actionMenu/actionMenuLinks";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ActionMenuItem from "./ActionMenuItem";
import { actionMenuChild } from "../../utils/actionMenu/actionMenuChildMapping";
import { twMerge } from "tailwind-merge";

export default function ActionMenu({
  actionMenuModal,
  setActionMenuModal,
  user,
}) {
  const location = useLocation();
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  const modalOpen = actionMenuModal.open;
  const defaultRender = actionMenuModal.defaultRender;
  const resetActionMenu = { open: false, defaultRender: null };
  const isGlobalAdmin = user.admin;

  const [render, setRender] = useState(defaultRender);
  const [viewAllTools, setViewAllTools] = useState(false);

  let menuContent;
  let toolsName;

  switch (lastMatch?.handle?.actionMenu) {
    case "students":
      menuContent = studentLinks;
      toolsName = "Student tools";
      break;
    default:
      if (isGlobalAdmin) {
        menuContent = dashboardLinks;
        toolsName = "Administrative tools";
      } else {
        menuContent = studentLinks;
        toolsName = null;
      }
  }

  const ActionMenuChild = actionMenuChild[render];

  const renderContent = () => {
    if (render) {
      return <ActionMenuChild setRender={setRender} user={user} />;
    } else {
      return (
        <ul className="sm:grid-rows-1 grid-rows-2 grid grid-flow-col bg-gradient-to-r from-primary to-primary-600">
          {menuContent.map((menuItem) => (
            <li
              key={menuItem.id}
              className="sm:py-0 py-6 mx-3 text-white text-center"
            >
              <ActionMenuItem
                menuItem={menuItem}
                setRender={setRender}
                setViewAllTools={setViewAllTools}
              />
            </li>
          ))}
        </ul>
      );
    }
  };

  useEffect(() => {
    setRender(defaultRender);
    setViewAllTools(false);
  }, [modalOpen]);

  useEffect(() => {
    setActionMenuModal(resetActionMenu);
    setViewAllTools(false);
  }, [location]);

  return (
    <div
      className={twMerge(
        menuContent.length === 0 && "hidden",
        "2xl:left-[15%] 3xl:left-[25%] 4xl:left-[30%] xl:left-[10%] lg:left-10 lg:top-[4.2em] lg:absolute lg:from-inherit lg:w-auto lg:h-[56px] mr-7 pb-5 fixed bottom-0 z-10 h-auto max-w-full cursor-pointer left-1/2 -translate-x-1/2 w-full bg-gradient-to-t from-10% from-[rgba(0,0,0,.5)]"
      )}
    >
      <img
        src="/add.svg"
        width={56}
        height={56}
        className="m-auto"
        alt="Quick Add"
        aria-hidden="true"
        onClick={() => setActionMenuModal({ open: true, defaultRender: null })}
      />

      <Dialog
        open={modalOpen}
        setOpen={() => setActionMenuModal(resetActionMenu)}
      >
        <div className="bg-neutral-600 relative">
          <div className="sm:hidden text-white px-3 pt-6">
            <XMarkIcon
              onClick={() => setActionMenuModal(resetActionMenu)}
              className="w-7 ml-auto cursor-pointer"
            />
          </div>
          {viewAllTools && (
            <div className="sm:hidden">
              <p className="text-center text-white text-xs">
                Administrative tools
              </p>
              <ul className="sm:grid-rows-1 grid-rows-2 grid grid-flow-col">
                {dashboardLinks.map((menuItem) => (
                  <li
                    key={menuItem.id}
                    className="sm:py-0 py-6 mx-3 text-white text-center"
                  >
                    <ActionMenuItem
                      menuItem={menuItem}
                      setRender={setRender}
                      setViewAllTools={setViewAllTools}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {viewAllTools && (
            <p className="sm:hidden text-center text-white text-xs">
              {toolsName}
            </p>
          )}
          {renderContent()}
          <div className="sm:hidden">
            {lastMatch?.handle?.actionMenu !== undefined && !render && (
              <p
                onClick={() => setViewAllTools(!viewAllTools)}
                className="text-center pb-4 cursor-pointer text-[#46C5F3] text-xs"
              >
                {viewAllTools ? "SHOW LESS" : "VIEW ALL TOOLS"}
              </p>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
