import React, { useEffect, useState, useRef } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import bwipjs from "bwip-js";
import PageLoader from "../../../UI/PageLoader";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const TICKET_HEIGHT = 149;

const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
    img.src = url;
  });
};

export default function SeminarPrintTicketsPdf({
  selectedStudents,
  startRegistrationNumber,
  endRegistrationNumber,
}) {
  const [students, setStudents] = useState([]);
  const [printingTips, setPrintingTips] = useState(false);
  const startRegistrationNumberInt = Number(startRegistrationNumber);
  const endRegistrationNumberInt = Number(endRegistrationNumber);
  const [loading, setLoading] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);
  const [pdfFullyLoaded, setPdfFullyLoaded] = useState(false);
  const iframeRef = useRef(null);
  const loadingTimerRef = useRef(null);

  const createTicketFront = async (student, index, totalTickets) => {
    const canvas = document.createElement("canvas");

    await bwipjs.toCanvas(canvas, {
      bcid: "code39",
      text: student.registration_number,
      scale: 2,
      height: 12.6,
      includetext: true,
      textxalign: "center",
      rotate: "L",
    });

    const barcodeData = canvas.toDataURL();

    return {
      table: {
        widths: [50, "*"],
        heights: [TICKET_HEIGHT],
        body: [
          [
            {
              stack: [
                { text: "", margin: [0, 20, 0, 0] }, // Top padding
                {
                  columns: [{ image: barcodeData, width: 50 }],
                },
              ],
              alignment: "center",
            },
            {
              stack: [
                { text: "", margin: [0, 40, 0, 0] }, // Top padding
                student.noStudent
                  ? undefined
                  : {
                      text: `${student.first_name} ${student.last_name}`,
                      fontSize: 22,
                    },
                {
                  text: `Ticket #${student.registration_number}`,
                  fontSize: 22,
                  margin: [0, 5, 0, 0],
                },
                {
                  text: "Thank You!   Gracias!",
                  fontSize: 12,
                  margin: [0, 10, 0, 0],
                },
              ],
              alignment: "center",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: (i, node) => {
          if (i === 0) return 1; // Top border
          if (i === node.table.body.length)
            return index === totalTickets - 1 ? 1 : 0; // Bottom border
          return 0;
        },
        vLineWidth: (i) => (i === 1 ? 1 : 0), // Only draw the vertical line before the second column
        hLineStyle: () => ({ dash: { length: 5, space: 5 } }),
        vLineStyle: () => ({ dash: { length: 5, space: 5 } }),
        hLineColor: () => "#cccccc",
        vLineColor: () => "#cccccc",
        fillColor: () => null,
        paddingLeft: () => 0,
        paddingRight: () => 10,
        paddingTop: () => 0,
        paddingBottom: () => 0,
      },
      margin: [0, 0, 0, 0],
    };
  };

  const createTicketBack = (index, totalTickets) => {
    return {
      table: {
        widths: ["*", 200, 50],
        heights: [TICKET_HEIGHT],
        body: [
          [
            {
              stack: [
                {
                  text: "Name/Nombre: _______________________________________________________",
                  fontSize: 9,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "Address/Domicilio: ____________________________________________________",
                  fontSize: 9,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: "City/Ciudad: __________________________________________________________",
                  fontSize: 9,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: "State/Estado: ___________________ Zip/Codigo Postal: ___________________",
                  fontSize: 9,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: "Telephone/Teléfono: (________) ________________________________________",
                  fontSize: 9,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: "Age/Edad: [  ]0-9   [  ]10-19   [  ]20-29   [  ]30-39   [  ]40-49   [  ]50-59   [  ]60+",
                  fontSize: 9,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: "There is no obligation in filling out this card. No hay obligación alguna de llenar esta tarjeta.",
                  fontSize: 7,
                  margin: [0, 9, 0, 0],
                },
              ],
            },
            {
              stack: [
                {
                  text: "Please tell us how you heard about the meetings.",
                  fontSize: 9,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "Favor de decirnos como supo de estas reuniones.",
                  fontSize: 9,
                  italics: true,
                },
                {
                  text: "[   ] TV      [   ] Radio      [   ] Mail/Correo",
                  fontSize: 9,
                  margin: [0, 9, 0, 0],
                },
                {
                  text: "[   ] Newspaper/Periódico",
                  fontSize: 9,
                  margin: [0, 5, 0, 0],
                },
                {
                  text: "[   ] Billboard/Boletinero",
                  fontSize: 9,
                  margin: [0, 5, 0, 0],
                },
                {
                  text: "[   ] Friend/Amigo      [   ] Other/Otro",
                  fontSize: 9,
                  margin: [0, 5, 0, 0],
                },
                {
                  text: "Church Affiliation/Afiliación de Iglesia:",
                  fontSize: 9,
                  margin: [0, 10, 0, 0],
                },
                {
                  text: "_________________________________________________",
                  fontSize: 9,
                  margin: [0, 8, 0, 0],
                },
              ],
            },
            {
              stack: [
                {
                  image: "signature",
                  width: 33,
                  alignment: "right",
                  margin: [0, 10, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: (i, node) => {
          if (i === 0) return 1; // Top border
          if (i === node.table.body.length)
            return index === totalTickets - 1 ? 1 : 0; // Bottom border
          return 0;
        },
        vLineWidth: (i) => (i === 2 ? 1 : 0), // Only draw the vertical line before the last column
        hLineStyle: () => ({ dash: { length: 5, space: 5 } }),
        vLineStyle: () => ({ dash: { length: 5, space: 5 } }),
        hLineColor: () => "#cccccc",
        vLineColor: () => "#cccccc",
        fillColor: () => null,
        paddingLeft: () => 0,
        paddingRight: () => 10,
        paddingTop: () => 0,
        paddingBottom: () => 0,
      },
      margin: [0, 0, 0, 0],
    };
  };

  const docDefinition = async () => {
    const content = [];
    const ticketsPerPage = 5;

    for (let i = 0; i < students.length; i += ticketsPerPage) {
      const pageTickets = students.slice(i, i + ticketsPerPage);

      // Front side of tickets
      const frontContent = await Promise.all(
        pageTickets.map((student, index) =>
          createTicketFront(student, index, pageTickets.length)
        )
      );
      content.push(...frontContent);

      // Add a page break after front tickets
      content.push({ text: "", pageBreak: "after" });

      // Back side of tickets
      const backContent = pageTickets.map((student, index) =>
        createTicketBack(index, pageTickets.length)
      );
      content.push(...backContent);

      // Add a page break after back tickets, except for the last page
      if (i + ticketsPerPage < students.length) {
        content.push({ text: "", pageBreak: "after" });
      }
    }

    return {
      info: {
        title: "registration_tickets.pdf",
      },
      pageSize: "LETTER",
      pageMargins: [20, 20, 20, 20],
      content: content,
      images: {
        signature: await getBase64ImageFromURL("/seminar-ticket-signature.png"),
      },
    };
  };

  const handleIframeLoad = () => {
    // Clear any existing timer
    if (loadingTimerRef.current) {
      clearTimeout(loadingTimerRef.current);
    }

    // Set a new timer to allow extra time for the PDF to render
    loadingTimerRef.current = setTimeout(() => {
      setPdfFullyLoaded(true);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const mountedStudents = Array.from(
      { length: endRegistrationNumberInt - startRegistrationNumberInt + 1 },
      (_, index) => {
        const currentRegistrationNumberInt = startRegistrationNumberInt + index;
        const currentRegistrationNumber = currentRegistrationNumberInt
          .toString()
          .padStart(4, "0");
        const existingStudent = selectedStudents?.find(
          (student) =>
            Number(student.registration_number) === currentRegistrationNumberInt
        );
        return (
          existingStudent || {
            noStudent: true,
            registration_number: currentRegistrationNumber,
          }
        );
      }
    );

    setStudents(mountedStudents);
  }, [selectedStudents]);

  useEffect(() => {
    if (students?.length > 0) {
      setLoading(true);
      setPdfFullyLoaded(false);
      docDefinition().then((def) => {
        const pdfDocGenerator = pdfMake.createPdf(def);
        pdfDocGenerator.getDataUrl((dataUrl) => {
          setPdfDataUrl(dataUrl);
        });
      });
    }

    return () => {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, [students]);

  return (
    <div className="mt-4 pt-4 border-t border-black">
      <h1 className="text-2xl font-bold">Registration Tickets</h1>
      <p className="mb-4">
        Showing <b>{students?.length}</b> tickets in range{" "}
        <b>{startRegistrationNumber.toString().padStart(4, "0")}-</b>
        <b>{endRegistrationNumber.toString().padStart(4, "0")}.</b>
      </p>

      <button
        onClick={() => setPrintingTips(!printingTips)}
        className="text-secondary mb-4 hover:text-secondary/75 transition-colors duration-200"
      >
        {printingTips ? "Hide" : "Show"} Printing Tips
      </button>

      {printingTips && (
        <ul className="list-disc pl-4 text-sm mb-6 ml-6">
          <li>
            If it doesn't print properly when printing from a browser window,
            you may need to download the PDF and open it in an external PDF
            viewer (Acrobat Reader).
          </li>
          <li>
            In the print dialog, make sure you select the <b>Two-Sided</b>{" "}
            option.
          </li>
          <li>
            If there is no <b>Two-Sided</b> option, you will need to print only
            the odd pages first, feed the printed paged back in the printer, and
            print only the even pages on the backs of the odd pages.
          </li>
          <li>
            If needed, disable any options like "Scale", "Fit to Page", "Shrink
            to Printable Area", "Auto Rotate and Center" in order to make sure
            it uses the full page width and the cut lines on back and front line
            up properly
          </li>
          <li>Cut on the dashed lines with a paper cutter</li>
        </ul>
      )}

      {(loading || !pdfFullyLoaded) && <PageLoader />}
      {pdfDataUrl && (
        <iframe
          ref={iframeRef}
          src={pdfDataUrl}
          className={`w-full h-[1200px] ${!pdfFullyLoaded ? "hidden" : ""}`}
          title="PDF Viewer"
          onLoad={handleIframeLoad}
        />
      )}
    </div>
  );
}
