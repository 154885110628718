import React from "react";
import TableScrollable from "../../../UI/Table/TableScrollable";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import CardHeader from "../../../UI/CardHeader";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { useNavigate } from "react-router-dom";
import EmptyTable from "../../../UI/EmptyTable";

export default function OnlineCourses({ student }) {
  const navigate = useNavigate();

  return (
    <>
      <CardHeader title="Online Courses" />

      {/* eslint-disable no-constant-condition */}
      {false ? (
        <TableScrollable height={147}>
          <thead>
            <tr>
              <TableHeader classNames="w-3/6">COURSE</TableHeader>
              <TableHeader classNames="w-1/6 text-center">PROGRESS</TableHeader>
              <TableHeader classNames="w-1/6 text-center">DECISION</TableHeader>
              <TableHeader classNames="w-1/6 text-center">STATUS</TableHeader>
            </tr>
          </thead>
          <tbody className="h-[147px]">
            {student.course_enrollments.map((enrollment) => (
              <tr
                key={enrollment.id}
                className="cursor-pointer"
                onClick={() =>
                  navigate(
                    `/students/${student.id}/courses/enrollments/${enrollment.id}/lessons`
                  )
                }
              >
                <TableCell classNames="w-3/6">
                  {enrollment.series_name}
                </TableCell>
                <TableCell classNames="w-1/6 text-center">
                  {enrollment.progress}
                </TableCell>
                <TableCell classNames="w-1/6 text-center">
                  {enrollment.decisions_count}
                </TableCell>
                <TableCell classNames="w-1/6 text-center">
                  {returnEnrollmentStatus(enrollment.series_id, enrollment)}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : (
        <EmptyTable message="Coming soon." />
      )}
    </>
  );
}
