import React from "react";
import Print from "../../UI/Print";

export default function SchoolContactReportPrint({
  reports,
  handleAfterPrint,
}) {
  return (
    <Print
      header="CLAIMED ZIPS REPORT"
      empty={reports?.schools?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table className="fixed-layout">
        <thead>
          <tr>
            <th>NAME</th>
            <th>ADDRESS</th>
            <th>ADDRESS 2</th>
            <th>CITY</th>
            <th>STATE</th>
            <th>ZIP/POSTAL CODE</th>
            <th>COUNTRY</th>
            <th>PHONE</th>
            <th>EMAIL ADDRESS</th>
            <th>HEAD SCHOOL ADMIN</th>
            <th>ACTIVE</th>
            <th>SCHOOL NUMBER</th>
          </tr>
        </thead>
        <tbody>
          {reports?.schools?.map((school) => (
            <tr key={school.id}>
              <td>
                {school.school_name}
                {school.church_name}
              </td>
              <td>
                {school.church_address}
                {school.church_address}
              </td>
              <td>
                {school.school_address_2}
                {school.church_address_2}
              </td>
              <td>
                {school.school_city}
                {school.church_city}
              </td>
              <td>
                {school.school_state}
                {school.church_state}
              </td>
              <td>
                {school.school_zip_code}
                {school.church_zip_code}
              </td>
              <td>
                {school.school_country}
                {school.church_country}
              </td>
              <td>
                {school.school_phone}
                {school.church_phone}
              </td>
              <td>
                {school.school_email}
                {school.church_email}
              </td>
              <td>{school.head_school_admin?.first_name}</td>
              <td>{school.active ? "✓" : "✗"}</td>
              <td>{school.school_code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
