import React from "react";
import CardLink from "./CardLink";

function isNumeric(value) {
  return /^\d+$/.test(value);
}

export default function CardCounter({ to, count, label, className }) {
  return (
    <CardLink
      to={to}
      className={className}
      cardClassName="rounded-3xl text-center"
    >
      {!isNumeric(count) ? (
        <span className="flex items-center justify-center h-[82px]">
          <span className="animate-spin rounded-full h-7 w-7 border-b-2 border-gray-500" />
        </span>
      ) : (
        <>
          <p className="text-[40px]">{count}</p>
          <p className="text-[15px]">{label}</p>
        </>
      )}
    </CardLink>
  );
}
