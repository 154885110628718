import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import Card from "../Card";

function Wrapper({ children, to, onClick, className }) {
  return to ? (
    <Link to={to} className={twMerge("group", className)}>
      {children}
    </Link>
  ) : (
    <div onClick={onClick} className={twMerge("group", className)}>
      {children}
    </div>
  );
}

export default function CardLink({
  to,
  children,
  className,
  cardClassName,
  onClick,
  activeEffect = true,
}) {
  return (
    <Wrapper to={to} className={className} onClick={onClick}>
      <Card
        classNames={twMerge(
          activeEffect &&
            "group-active:bg-white group-active:shadow-lg transition-all duration-200",
          cardClassName
        )}
      >
        {children}
      </Card>
    </Wrapper>
  );
}
