import React from "react";
import { twMerge } from "tailwind-merge";
import { hoverLink } from "../../../utils/hoverLink";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

export default function TableHeader({
  children,
  classNames,
  sortBy,
  multiSortOptions,
  isMultiSort = false,
  isSortable = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const order = searchParams.get("orderBy");
  const attribute = searchParams.get("sortBy");

  function handleClick(value) {
    let newOrder;

    if (!attribute || attribute !== value) {
      newOrder = "asc";
    } else {
      newOrder = order === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      prev.set("orderBy", newOrder);
      prev.set("sortBy", value);
      prev.set("page", "1");
      return prev;
    });
  }

  return (
    <th className={twMerge("text-left text-xs", classNames)}>
      {isSortable ? (
        <>
          {isMultiSort ? (
            <>
              {children}
              <div className={"flex items-center"}>
                (
                {multiSortOptions.map((option, index) => (
                  <React.Fragment key={option.value}>
                    <div
                      onClick={() => handleClick(option.value)}
                      className={twMerge("flex items-center", hoverLink)}
                    >
                      {option.name}
                      {option.value === attribute && order === "asc" && (
                        <ChevronUpIcon className="w-4" />
                      )}
                      {option.value === attribute && order === "desc" && (
                        <ChevronDownIcon className="w-4" />
                      )}
                    </div>
                    {index < multiSortOptions.length - 1 && (
                      <span className="mx-1"> / </span>
                    )}
                  </React.Fragment>
                ))}
                )
              </div>
            </>
          ) : (
            <div
              onClick={() => handleClick(sortBy)}
              className={twMerge("flex items-center", hoverLink)}
            >
              {children}
              {sortBy === attribute && order === "asc" && (
                <ChevronUpIcon className="w-4 ml-1" />
              )}
              {sortBy === attribute && order === "desc" && (
                <ChevronDownIcon className="w-4 ml-1" />
              )}
            </div>
          )}
        </>
      ) : (
        children
      )}
    </th>
  );
}
