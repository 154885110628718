import React from "react";
import { ToastNotification } from "../../../UI/ToastNotification";
import { createProductRequest } from "../../../../requests/products";
import SeminarProductsForm from "./SeminarProductsForm";

export default function SeminarDecisionsNew({ setFormOpen, seminar }) {
  const onSubmit = async (data) => {
    try {
      const res = await createProductRequest({
        ...data,
        school_id: seminar.host_school?.id,
        series_id: seminar.id,
      });

      if (res.data) {
        ToastNotification("success", "Product created successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  return (
    <div className="mt-4">
      <SeminarProductsForm
        setOpen={setFormOpen}
        onSubmit={onSubmit}
        column="all"
      />
    </div>
  );
}
