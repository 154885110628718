import { useState, useEffect } from "react";
import resolveConfig from "tailwindcss/resolveConfig";

// Loading Tailwind default config since we don't have custom breakpoints.
// To use custom config we would need to import the tailwind.config.js file.
const fullConfig = resolveConfig({});

const screens = fullConfig.theme.screens;

const getBreakpointValue = (breakpoint) => {
  return parseInt(screens[breakpoint].replace("px", ""), 10);
};

const sortedBreakpoints = Object.keys(screens).sort(
  (a, b) => getBreakpointValue(a) - getBreakpointValue(b)
);

// Helper function to create and evaluate media query
const createMediaQuery = (screen, type) => {
  let query;

  if (type === "up") {
    query = `(min-width: ${screens[screen]})`;
  } else if (type === "down") {
    const value = getBreakpointValue(screen) - 0.1;
    query = `(max-width: ${value}px)`;
  } else if (type === "only") {
    const currentIndex = sortedBreakpoints.indexOf(screen);
    const nextBreakpoint = sortedBreakpoints[currentIndex + 1];

    if (nextBreakpoint) {
      const nextValue = getBreakpointValue(nextBreakpoint) - 0.1;
      query = `(min-width: ${screens[screen]}) and (max-width: ${nextValue}px)`;
    } else {
      query = `(min-width: ${screens[screen]})`;
    }
  } else {
    query = `(min-width: ${screens[screen]})`;
  }

  return query;
};

export const useMediaQuery = (screen, type = "exact") => {
  // Initialize with the current match state instead of false
  const [matches, setMatches] = useState(() => {
    // Only run matchMedia in browser environment
    if (typeof window !== "undefined") {
      const query = createMediaQuery(screen, type);
      return window.matchMedia(query).matches;
    }
    return false;
  });

  useEffect(() => {
    const query = createMediaQuery(screen, type);
    const mediaQuery = window.matchMedia(query);
    setMatches(mediaQuery.matches);

    const handler = (event) => setMatches(event.matches);
    mediaQuery.addEventListener("change", handler);

    return () => mediaQuery.removeEventListener("change", handler);
  }, [screen, type]);

  return matches;
};
