import React from "react";

// Helper function to highlight text based on query
export const highlightText = (text, query) => {
  if (!query || !text) return text;

  // Split query into words and filter out empty strings
  const searchWords = query.split(/\s+/).filter((word) => word.length > 0);

  // Create regex pattern that matches any of the words
  const pattern = new RegExp(`(${searchWords.join("|")})`, "gi");

  const parts = text.toString().split(pattern);
  return parts.map((part, i) =>
    searchWords.some((word) => part.toLowerCase() === word.toLowerCase()) ? (
      <span key={i} className="bg-yellow-200 text-black">
        {part}
      </span>
    ) : (
      part
    )
  );
};
