import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/tags`;

const getAllTags = async (params) => {
  const data = await axiosClient.get(endpoint, params);
  return data;
};

export { getAllTags };
