import React from "react";
import Table from "./Table";

// When using this component, the table header will stick and the table body will be scrollable.
// Required: you should set the width of each table cell (th and td) so columns are properly aligned.
// Example:
// <TableScrollable height={300}>
//   <thead>
//     <tr>
//       <TableHeader classNames="w-1/2">Column 1</TableHeader>
//       <TableHeader classNames="w-1/2">Column 2</TableHeader>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <TableCell classNames="w-1/2">Row 1, Column 1</TableCell>
//       <TableCell classNames="w-1/2">Row 1, Column 2</TableCell>
//     </tr>
//   </tbody>
// </TableScrollable>
export default function TableScrollable({
  children,
  classNames,
  height = 300,
}) {
  return (
    <div
      className={`
      [&_thead]:table [&_thead]:w-full [&_tbody]:block [&_tbody]:h-[${height}px]
      [&_tbody]:overflow-y-scroll [&_tr]:table [&_tr]:w-full [&_tr]:border-box
      ${classNames}`}
    >
      <Table>{children}</Table>
    </div>
  );
}
