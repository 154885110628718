import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Link } from "react-router-dom";
import { hoverLink } from "../../utils/hoverLink";
import { addressMultiLine } from "../../utils/fullAddress";
import { highlightText } from "../../utils/highlightText";
import React from "react";
import Pill from "../UI/Pill";

export default function SchoolsTable({ schools, loading, sortBy, query }) {
  const renderActive = (active) => {
    return active ? <Pill color="success">Active</Pill> : <Pill>Inactive</Pill>;
  };

  const addressHighlight =
    sortBy === "school_city"
      ? "city"
      : sortBy === "school_state"
        ? "state"
        : sortBy === "school_zip_code"
          ? "zip_code"
          : sortBy === "school_country"
            ? "country"
            : undefined;

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader isSortable sortBy="school_code">
            CODE
          </TableHeader>
          <TableHeader isSortable sortBy="school_name">
            NAME
          </TableHeader>
          <TableHeader
            isSortable
            isMultiSort
            multiSortOptions={[
              { name: "City", value: "school_city" },
              { name: "State", value: "school_state" },
              { name: "Zip", value: "school_zip_code" },
              { name: "Country", value: "school_country" },
            ]}
          >
            ADDRESS
          </TableHeader>
          <TableHeader isSortable sortBy="conference_name">
            CONFERENCE
          </TableHeader>
          <TableHeader>COURSES</TableHeader>
          <TableHeader>OPTIONS</TableHeader>
          <TableHeader>STATUS</TableHeader>
        </tr>
      </thead>
      <tbody>
        {schools.map((school) => (
          <tr key={school.id} className={loading ? "opacity-25" : undefined}>
            <TableCell>
              <Link className={hoverLink} to={`/schools/${school.id}/overview`}>
                #{highlightText(school.school_code, query)}
              </Link>
            </TableCell>
            <TableCell>
              <Link className={hoverLink} to={`/schools/${school.id}/overview`}>
                {highlightText(school.school_name, query)}
              </Link>
            </TableCell>
            <TableCell classNames="whitespace-nowrap">
              {addressMultiLine(
                {
                  address: school.school_address,
                  address_2: school.school_address_2,
                  city: school.school_city,
                  state: school.school_state,
                  zip_code: school.school_zip_code,
                  country: school.school_country,
                },
                { highlight: addressHighlight }
              )}
            </TableCell>
            <TableCell>{school.conference?.name}</TableCell>
            <TableCell>
              <Link className={hoverLink} to={`/schools/${school.id}/courses`}>
                {school.courses_count}
              </Link>
            </TableCell>
            <TableCell>
              {[
                { name: "Prison Ministry", value: school.prison_ministry },
                { name: "Signs Box", value: school.signs_box },
                { name: "No PO Box", value: school.no_po_box },
              ]
                .filter((option) => option.value)
                .map((option, index) => (
                  <Pill key={index} color="success" classNames="mr-2">
                    {option.name}
                  </Pill>
                ))}
            </TableCell>
            <TableCell>{renderActive(school.active)}</TableCell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
