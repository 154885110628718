import axiosClient from "./axios";

// This function is used to download a CSV file without opening a new tab
export const downloadCsvRequest = async (endpoint, params, fileName = "File") => {
  try {
    const response = await axiosClient.get(endpoint, {
      ...params,
      responseType: "blob",
    });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element, set the download attribute with a filename
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${fileName}-${new Date().toISOString().split("T")[0]}.csv`;

    // Append to the document body and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};
