import React from "react";

const PrayerRequestIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.3525 4.75266C12.6679 4.75266 13.7342 3.68874 13.7342 2.37633C13.7342 1.06392 12.6679 0 11.3525 0C10.0371 0 8.9707 1.06392 8.9707 2.37633C8.9707 3.68874 10.0371 4.75266 11.3525 4.75266Z" />
      <path d="M15.6251 7.09197C15.0691 6.46321 14.1145 6.40773 13.4936 6.95327L12.1312 8.20154L10.1573 5.36289C10.0368 5.19645 9.8885 5.04851 9.71241 4.9468C9.59194 4.85433 9.46219 4.78036 9.33244 4.70639C8.13693 4.11462 6.68192 4.59543 6.08879 5.78822L2.93782 12.1035C2.40957 13.1576 2.73393 14.4151 3.64216 15.0994C3.66069 15.1179 3.66996 15.1271 3.68849 15.1456L5.66249 16.9856H1.51061C0.676533 16.9856 0 17.6606 0 18.4928C0 19.325 0.676533 20 1.51061 20H9.46219C10.0831 20 10.6392 19.6209 10.8709 19.0476C11.1025 18.4743 10.9543 17.8178 10.5002 17.3925L7.04335 14.1655L9.5178 9.7272L10.6206 11.3176C10.8709 11.6689 11.2508 11.9001 11.6771 11.9463C12.1034 11.9926 12.5298 11.8631 12.8541 11.5765L15.4861 9.2094C16.1163 8.65461 16.1719 7.70223 15.6251 7.08272V7.09197Z" />
    </svg>
  );
};

export default PrayerRequestIcon;
