import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

const DropdownMenu = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleItemClick = (onClick) => {
    setIsOpen(false);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
      >
        <EllipsisVerticalIcon className="w-7 h-7 text-gray-500" />
      </button>
      {isOpen && (
        <div className="absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {items.map((item, index) =>
              item.to ? (
                <Link
                  key={index}
                  to={item.to}
                  className="flex items-center px-4 py-2 text-md text-secondary hover:bg-gray-100"
                  role="menuitem"
                  onClick={() => handleItemClick()}
                >
                  {item.icon && <span className="w-5 mr-1">{item.icon}</span>}
                  {item.label}
                </Link>
              ) : (
                <button
                  key={index}
                  onClick={() => handleItemClick(item.onClick)}
                  className="flex items-center w-full text-left px-4 py-2 text-md text-secondary hover:bg-gray-100"
                  role="menuitem"
                >
                  {item.icon && <span className="w-5 mr-1">{item.icon}</span>}
                  {item.label}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
