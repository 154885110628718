import React from "react";
import Card from "../UI/Card";
import TableScrollable from "../UI/Table/TableScrollable";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import EmptyTable from "../UI/EmptyTable";
import PageLoader from "../UI/PageLoader";
import { Link } from "react-router-dom";
import { hoverLink } from "../../utils/hoverLink";
import CardHeader from "../UI/CardHeader";

export default function SeminarsTable({ seminars, isAdmin }) {
  return (
    <Card classNames="mb-4 min-h-[425px]">
      <CardHeader title="Seminars" to={isAdmin ? "/seminars" : false} />

      {seminars?.length > 0 ? (
        <TableScrollable height={300}>
          <thead>
            <tr>
              <TableHeader classNames="w-1/3">SEMINAR</TableHeader>
              <TableHeader classNames="w-1/3 text-center">STUDENTS</TableHeader>
              <TableHeader classNames="w-1/3 text-center">
                NEEDING-FOLLOW-UP
              </TableHeader>
            </tr>
          </thead>
          <tbody className="h-[300px]">
            {seminars.map((seminar) => (
              <tr key={seminar.id}>
                <TableCell classNames="w-1/3">
                  {isAdmin ? (
                    <Link
                      className={hoverLink}
                      to={`/seminars/${seminar?.id}/details`}
                    >
                      {seminar.name}
                    </Link>
                  ) : (
                    seminar.name
                  )}
                </TableCell>
                <TableCell classNames="w-1/3 text-center">
                  <Link
                    className={hoverLink}
                    to={`/seminars/${seminar?.id}/students`}
                  >
                    {seminar.students_count}
                  </Link>
                </TableCell>
                <TableCell classNames="w-1/3 text-center">-</TableCell>
              </tr>
            ))}
          </tbody>
        </TableScrollable>
      ) : seminars ? (
        <EmptyTable message="No seminars available." />
      ) : (
        <PageLoader />
      )}
    </Card>
  );
}
