import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";
import { twMerge } from "tailwind-merge";

export default function StudentMobileCourses({
  courses,
  returnStudentEnrollment,
}) {
  const student = useRouteLoaderData("student").data;

  if (!courses) return <PageLoader className="h-[50vh]" />;

  // Merge student enrollments into courses and sort it so that
  // graduated courses are at the bottom.
  let sortedCourses = courses
    .map((course) => ({
      ...course,
      enrollment: returnStudentEnrollment(course.id),
    }))
    .map((course) => ({
      ...course,
      graduated: course.enrollment?.status === "Graduated",
    }))
    .sort((a, b) => Number(a.graduated) - Number(b.graduated));

  return (
    <div>
      {sortedCourses.map((course) => (
        <CardLink
          key={course.id}
          to={
            course.enrollment
              ? `/students/${student?.id}/courses/enrollments/${course.enrollment.id}/lessons`
              : `#`
          }
          className="mb-4 last:mb-10 block"
          cardClassName={twMerge(
            "rounded-2xl p-4",
            course.graduated && "bg-black/70 text-white group-active:bg-black"
          )}
        >
          <span className="flex justify-between items-center gap-2">
            <span className="text-[17px]">{course.name}</span>
            {course.graduated ? (
              <span className="text-center text-xs">
                <span className="block whitespace-nowrap">Graduated</span>
                <span className="block whitespace-nowrap">
                  {Intl.DateTimeFormat("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }).format(new Date(course.enrollment.diploma_delivered_date))}
                </span>
              </span>
            ) : course.enrollment ? (
              <span className="flex text-center gap-4">
                <span>
                  <span className="block text-xs whitespace-nowrap">
                    Lesson
                  </span>
                  <span className="block text-[40px] leading-none">
                    {course.enrollment?.cached_attended_count + 1}
                  </span>
                </span>
                {course.enrollment.latest_presentation_date_sent && (
                  <>
                    <span className="flex gap-2">
                      <span>
                        <span className="block text-xs whitespace-nowrap">
                          Days Past
                        </span>
                        <span className="block text-[40px] leading-none">
                          {Math.floor(
                            (new Date() -
                              new Date(
                                course.enrollment.latest_presentation_date_sent
                              )) /
                              (1000 * 60 * 60 * 24)
                          )}
                        </span>
                      </span>
                      <span className="hidden w-4">
                        <span className="rounded-full bg-[#35CDBB] px-2 py-1 text-xs">
                          {1}
                        </span>
                      </span>
                    </span>
                  </>
                )}
              </span>
            ) : (
              <span className="text-gray-500 text-xs">Unenrolled</span>
            )}
          </span>
        </CardLink>
      ))}
    </div>
  );
}
