import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import StudentMobileSeminars from "./Mobile/StudentMobileSeminars";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import { getAllSchoolSeminars } from "../../../../requests/schools";
import {
  createEnrollmentRequest,
  deleteEnrollmentRequest,
} from "../../../../requests/enrollments";
import { ToastNotification } from "../../../UI/ToastNotification";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import Modal from "../../../UI/Modal";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { hoverLink } from "../../../../utils/hoverLink";
import { displayError } from "../../../../utils/displayError";

export default function StudentSeminars() {
  const student = useRouteLoaderData("student").data;
  const [enrollmentModal, setEnrollmentModal] = useState(false);
  const [seminars, setSeminars] = useState([]);
  const [selectedSeminar, setSelectedSeminar] = useState(null);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);

  const getSeminars = async () => {
    const res = await getAllSchoolSeminars({
      school_id: student.school_id,
      student_id: student.id,
    });

    if (res.data) {
      setSeminars(res.data);
    }
  };

  const handleEnrollment = (seminar) => {
    setSelectedSeminar(seminar);
    setEnrollmentModal(true);
  };

  const createEnrollment = async () => {
    try {
      const res = await createEnrollmentRequest({
        student_id: student.id,
        series_id: selectedSeminar.id,
        school_id: student.school_id,
      });

      if (res.data) {
        ToastNotification("success", "Enrollment created successfully.");
        setEnrollmentModal(false);
        navigate(`/students/${res.data.student_id}/seminars`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const returnStudentEnrollment = (seminarId) => {
    return student.seminar_enrollments.find((seminarEnrollment) => {
      return seminarEnrollment.series_id === seminarId;
    });
  };

  const checkDataRecorded = (enrollment) => {
    return (
      enrollment.visits_count > 0 ||
      enrollment.reminders_count > 0 ||
      enrollment.decisions_count > 0 ||
      enrollment.progress.split(" of ")[0] > 0
    );
  };

  const handleDelete = (enrollment) => {
    setSelectedEnrollment(enrollment);
    setDeleteModal(true);
  };

  const deleteEnrollment = async () => {
    try {
      const res = await deleteEnrollmentRequest({
        enrollmentId: selectedEnrollment.id,
      });

      if (res) {
        ToastNotification("success", "Unenrolled successfully.");
        setDeleteModal(false);
        navigate(`/students/${student.id}/seminars`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getSeminars();
  }, []);

  return (
    <>
      <div className="md:hidden">
        <StudentMobileSeminars seminars={seminars} />
      </div>
      <Card classNames="md:block hidden">
        {seminars.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>SEMINAR</TableHeader>
                <TableHeader>REG. NO.</TableHeader>
                <TableHeader>SCHOOL</TableHeader>
                <TableHeader>ATTENDANCE</TableHeader>
                <TableHeader>STATUS</TableHeader>
                <TableHeader>PRODUCTS</TableHeader>
                <TableHeader>VISITS</TableHeader>
                <TableHeader>ACTIONS</TableHeader>
              </tr>
            </thead>
            <tbody>
              {seminars.map((seminar) => {
                const enrollment = returnStudentEnrollment(seminar.id);
                return (
                  <tr key={seminar.id}>
                    <TableCell>
                      {enrollment ? (
                        <Link
                          to={`/students/${student.id}/seminars/enrollments/${enrollment.id}/presentations`}
                          className={hoverLink}
                        >
                          {seminar.name}
                        </Link>
                      ) : (
                        <p>{seminar.name}</p>
                      )}
                    </TableCell>
                    <TableCell>
                      {enrollment?.registration_number || "-"}
                    </TableCell>
                    <TableCell>
                      <Link
                        className={hoverLink}
                        to={`/schools/${seminar.host_school.id}/overview`}
                      >
                        {seminar.host_school.school_name}
                      </Link>
                    </TableCell>
                    <TableCell>{enrollment?.progress}</TableCell>
                    <TableCell>
                      {enrollment &&
                        returnEnrollmentStatus(seminar.id, enrollment)}
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{enrollment?.visits_count}</TableCell>
                    <TableCell>
                      {enrollment ? (
                        <Button
                          variant="outline"
                          disabled={checkDataRecorded(enrollment)}
                          onClick={() => handleDelete(enrollment)}
                        >
                          Unenroll
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleEnrollment(seminar)}
                          variant="primary-outline"
                        >
                          Enroll
                        </Button>
                      )}
                    </TableCell>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p className="text-center">No seminars offered by the school</p>
        )}
      </Card>

      <Modal dark open={enrollmentModal} setOpen={setEnrollmentModal}>
        <ConfirmationDialog
          closeModal={() => setEnrollmentModal(false)}
          confirmAction={() => createEnrollment()}
        />
      </Modal>

      <Modal dark open={deleteModal} setOpen={setDeleteModal}>
        <ConfirmationDialog
          closeModal={() => setDeleteModal(false)}
          confirmAction={() => deleteEnrollment()}
        />
      </Modal>
    </>
  );
}
