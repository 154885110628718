import React from "react";
import CoursesTable from "./CoursesTable";
import SeminarsTable from "./SeminarsTable";
import InstructorsTable from "./InstructorsTable";
import NeedingFollowupTable from "./NeedingFollowupTable";

export default function DesktopDashboard({
  needingFollowup,
  courses,
  seminars,
  instructors,
  isAdmin,
}) {
  return (
    <>
      <h1 className="text-[32px]">My Dashboard</h1>
      <div className="flex mt-2">
        <div className="mr-4 w-1/2">
          <NeedingFollowupTable needingFollowup={needingFollowup} />
        </div>
        <div className="w-1/2">
          <CoursesTable courses={courses} isAdmin={isAdmin} />
          <SeminarsTable seminars={seminars} isAdmin={isAdmin} />
          {isAdmin && <InstructorsTable instructors={instructors} />}
        </div>
      </div>
    </>
  );
}
