import axiosClient from "../utils/axios";
import { downloadCsvRequest } from "../utils/downloadCsvRequest";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/reports`;

const getAttendanceReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/attendance`, params);

  return data;
};

const getStudentFollowUpReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/student-follow-up`, params);

  return data;
};

const getStudentContactReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/student-contact`, params);

  return data;
};

const getMasterTeamReport = async (params) => {
  const data = await axiosClient.get(`${endpoint}/master-team`, params);

  return data;
};

const getClaimedZips = async (params) => {
  const data = await axiosClient.get(`${endpoint}/claimed-zips`, params);

  return data;
};

const getSchoolContact = async (params) => {
  const data = await axiosClient.get(`${endpoint}/school-contact`, params);

  return data;
};

const getSchoolInformationForms = async (params) => {
  const data = await axiosClient.get(
    `${endpoint}/school-information-forms`,
    params
  );

  return data;
};

const getSchoolAveryLabels = async (params) => {
  const data = await axiosClient.get(`${endpoint}/school-avery-labels`, params);

  return data;
};

const getNadBibleSchools = async (params) => {
  const data = await axiosClient.get(`${endpoint}/nad-bible-schools`, params);

  return data;
};

const downloadCsvAttendanceReport = async (params) =>
  downloadCsvRequest(`${endpoint}/attendance.csv`, params, "Attendance");

const downloadCsvStudentContactReport = async (params) =>
  downloadCsvRequest(
    `${endpoint}/student-contact.csv`,
    params,
    "Student Contact"
  );

const downloadCsvMasterTeamReport = async (params) =>
  downloadCsvRequest(`${endpoint}/master-team.csv`, params, "Master Team");

const downloadCsvClaimedZips = async (params) =>
  downloadCsvRequest(
    `${endpoint}/claimed-zips.csv`,
    params,
    "Schools with Claimed Zips"
  );

const downloadCsvSchoolContactReport = async (params) =>
  downloadCsvRequest(
    `${endpoint}/school-contact.csv`,
    params,
    "Schools with City, State, Contact Info"
  );

const downloadCsvNadBibleSchools = async (params) =>
  downloadCsvRequest(
    `${endpoint}/nad-bible-schools.csv`,
    params,
    "NAD Bible Schools"
  );

const downloadCsvStudentFollowUpReport = async (params) =>
  downloadCsvRequest(
    `${endpoint}/student-follow-up.csv`,
    params,
    "Student Follow-up Report"
  );

export {
  getAttendanceReport,
  getStudentFollowUpReport,
  getStudentContactReport,
  getMasterTeamReport,
  getClaimedZips,
  getSchoolContact,
  getSchoolInformationForms,
  getSchoolAveryLabels,
  getNadBibleSchools,
  downloadCsvAttendanceReport,
  downloadCsvStudentContactReport,
  downloadCsvMasterTeamReport,
  downloadCsvClaimedZips,
  downloadCsvSchoolContactReport,
  downloadCsvNadBibleSchools,
  downloadCsvStudentFollowUpReport,
};
