import React from "react";
import Print from "../../UI/Print";
import { fullName } from "../../../utils/fullName";
import { renderPresentations } from "../../../utils/reports/reportAttendanceChecks";

export default function StudentFollowupReportPrint({
  reports,
  seminar,
  handleAfterPrint,
}) {
  return (
    <Print
      header={`STUDENT FOLLOW-UP REPORT FOR ${seminar?.name}`}
      empty={reports?.enrollments?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>REGISTRATION NUMBER</th>
            <th>NAME</th>
            <th>ATTENDANCE</th>
            <th>LATEST FOLLOW-UP DATE</th>
            <th>LATEST FOLLOW-UP ACTIVITY</th>
            <th>INSTRUCTOR</th>
          </tr>
        </thead>
        <tbody>
          {reports?.enrollments?.map((enrollment) => (
            <tr key={enrollment.id}>
              <td>{enrollment.registration_number}</td>
              <td>
                {fullName(
                  enrollment.student.first_name,
                  "",
                  enrollment.student.last_name
                )}
                <br />
                {enrollment.student.phone}
              </td>
              <td>
                <div className="flex">
                  {reports?.presentations.length > 0 &&
                    renderPresentations(reports, enrollment)}
                </div>
              </td>
              <td>{enrollment?.latest_followup_activity?.created_at || "-"}</td>
              <td>
                {enrollment?.latest_followup_activity?.activity ||
                  "(No followup activity)"}
              </td>
              <td>{enrollment.student.instructor_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
