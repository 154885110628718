import React from "react";
import Print from "../../UI/Print";
import { fullName } from "../../../utils/fullName";
import { addressMultiLine } from "../../../utils/fullAddress";

export default function StudentContactReportPrint({
  reports,
  handleAfterPrint,
}) {
  return (
    <Print
      header="STUDENT CONTACT REPORT"
      empty={reports?.students?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>NAME/PHONE</th>
            <th>ADDRESS</th>
            <th>PHONE</th>
            <th>PHONE 2</th>
            <th>EMAIL ADDRESS</th>
            <th>INSTRUCTOR</th>
            <th>SCHOOL</th>
          </tr>
        </thead>
        <tbody>
          {reports?.students?.map((student) => (
            <tr key={student.id}>
              <td>
                {fullName(student.first_name, "", student.last_name)}
                <br />
                {student.phone}
              </td>
              <td className="whitespace-nowrap">
                {addressMultiLine({
                  address: student.address,
                  address_2: student.address_2,
                  city: student.city,
                  state: student.state,
                  zip_code: student.zip_code,
                  country: student.country,
                })}
              </td>
              <td>
                {student.phone} {student.phone_is_sms ? "yes" : "no"}
              </td>
              <td>
                {student.phone_2} {student.phone_2_is_sms ? "yes" : "no"}
              </td>
              <td>{student.email}</td>
              <td>{student.instructor_name}</td>
              <td>
                {`(#${student.school.school_code}) ${student.school.church_name}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
