import React from "react";
import Print from "../UI/Print";
import { fullName } from "../../utils/fullName";
import { addressMultiLine } from "../../utils/fullAddress";

const InstructorsPrint = ({ instructors, handleAfterPrint }) => {
  return (
    <Print
      header="INSTRUCTORS"
      empty={instructors.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>INSTRUCTOR</th>
            <th>STUDENTS</th>
            <th>CONTACT INFO</th>
            <th>ADDRESS</th>
            <th>ROLES</th>
          </tr>
        </thead>

        <tbody>
          {instructors.map((instructor) => (
            <tr key={instructor.id}>
              <td>
                {fullName(instructor.first_name, " ", instructor.last_name)}
              </td>
              <td className="whitespace-nowrap">
                {instructor.students_count} students
              </td>
              <td>
                <p>{instructor.email}</p>
                <p>{instructor.phone}</p>
              </td>
              <td className="whitespace-nowrap">
                {addressMultiLine({
                  address: instructor.address,
                  address_2: instructor.address_2,
                  city: instructor.city,
                  state: instructor.state,
                  country: instructor.country,
                  zip_code: instructor.zip_code,
                })}
              </td>
              <td>
                {instructor.roles.map((role) => (
                  <div key={role.id}>
                    <b>{role.titleized_role}</b> at {role.organization_name}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
};

export default InstructorsPrint;
